import React, { Dispatch, useCallback } from "react";
import { Modal} from "antd";

import ConfirmSwapModalHeader from "../components/ConfirmSwapModalHeader";
import ConfirmationModalContent from "../components/ConfirmModalContent";
import ConfirmSwapModalBottom from "../components/ConfirmSwapModalBottom";
import ConfirmPendingContent from "../components/ConfirmPendingContent";
import TransactionSubmitContent from "../components/TransactionSubmitContent";
import { Currency } from "entities/currency";
import TransactionErrorContent from "../components/TransactionErrorContent";

interface ConfirmSwapModalProps {
  isOpen: boolean;
  setIsOpen: Dispatch<boolean>;
  trade: any;
  inputAmount: string;
  outputAmount: string;
  inputSwapData?: Currency | null;
  outputSwapData?: Currency | null;
  allowedSlippage: number;
  formattedPrice: string;
  switchPrice: () => void;
  attemptingTxn: boolean;
  txHash: string | undefined;
  onConfirm: () => void;
  swapErrorMessage: string | undefined;
  setSwapState: any;
  onDismiss: () => void;
}

const ConfirmSwapModal: React.FunctionComponent<ConfirmSwapModalProps> = ({
  isOpen,
  setIsOpen,
  trade,
  inputAmount,
  outputAmount,
  inputSwapData,
  outputSwapData,
  allowedSlippage,
  formattedPrice,
  switchPrice,
  attemptingTxn,
  txHash,
  onConfirm,
  swapErrorMessage,
  setSwapState,
  onDismiss,
}) => {
  
  const modalHeader = useCallback(() => {
    return trade ? (
      <ConfirmSwapModalHeader
        trade={trade}
        inputAmount={inputAmount}
        outputAmount={outputAmount}
        inputSwapData={inputSwapData}
        outputSwapData={outputSwapData}
        allowedSlippage={allowedSlippage}
      />
    ) : null;
  }, [trade]);

  const modalBottom = useCallback(() => {
    return trade ? (
      <ConfirmSwapModalBottom
        allowedSlippage={allowedSlippage}
        trade={trade}
        formattedPrice={formattedPrice}
        switchPrice={switchPrice}
      />
    ) : null;
  }, [trade, formattedPrice]);

  
  const confirmationContent = useCallback(() => {
    return (
      <>
        {swapErrorMessage ? (
          <TransactionErrorContent onDismiss={onDismiss} />
        ) : (
          <ConfirmationModalContent
            topContent={modalHeader}
            bottomContent={modalBottom}
            onConfirm={onConfirm}
          />
        )}
      </>
    );
  }, [modalHeader, modalBottom, swapErrorMessage, onDismiss]);

  
  const pendingText = `Swapping ${inputAmount}  ${trade?.trade.inputAmount?.currency?.symbol} for ${outputAmount}  ${trade?.trade.outputAmount?.currency?.symbol} `;
  
  return (
    <Modal
      className={`modal--swap--confirm ${attemptingTxn ? "waiting" : ""}`}
      open={isOpen}
      destroyOnClose={true}
      closable={true}
      onOk={() => setIsOpen(false)}
      onCancel={() => setIsOpen(false)}
      footer={null}
      maskClosable={true}
      title={<>Confirm Swap</>}
    >
      {attemptingTxn ? (
        <ConfirmPendingContent pendingText={pendingText} />
      ) : txHash ? (
        <TransactionSubmitContent
          setIsOpen={setIsOpen}
          setSwapState={setSwapState}
        />
      ) : (
        confirmationContent()
      )}
    </Modal>
  );
};

export default ConfirmSwapModal;
