import { createAction, createSlice } from "@reduxjs/toolkit";
import { DEFAULT_DEADLINE_FROM_NOW } from "libs/ape-swap/constants";
import { SerializedToken } from "libs/ape-swap/types";

import { addSerializedToken, removeSerializedToken } from "./userAction";
import { GAS_PRICE_GWEI } from "state/types";

const currentTimestamp = () => new Date().getTime();

export interface UserState {
  userSlippageTolerance: number;
  timestamp: number;
  userBonusRouterDisabled: boolean;
  userSingleHopOnly: boolean;
  tokens: {
    [chainId: number]: {
      [address: string]: SerializedToken;
    };
  };
  userDeadline: number;
  gasPrice: string;
}

const initialState: UserState = {
  userSlippageTolerance: 50,
  timestamp: currentTimestamp(),
  userBonusRouterDisabled: false,
  userSingleHopOnly: false,
  tokens: {},
  userDeadline: DEFAULT_DEADLINE_FROM_NOW,
  gasPrice: GAS_PRICE_GWEI.rpcDefault,
};

export const updateUserSlippageTolerance = createAction<{
  userSlippageTolerance: number;
}>("user/updateUserSlippageTolerance");

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(
        updateUserSlippageTolerance,
        (state, { payload: { userSlippageTolerance } }) => {
          state.userSlippageTolerance = userSlippageTolerance;
          state.timestamp = currentTimestamp();
        }
      )
      .addCase(updateUserBonusRouter, (state, action) => {
        state.userBonusRouterDisabled = action.payload.userBonusRouterDisabled;
        state.timestamp = currentTimestamp();
      })
      .addCase(updateUserSingleHopOnly, (state, action) => {
        state.userSingleHopOnly = action.payload.userSingleHopOnly;
      })
      .addCase(
        addSerializedToken,
        (state, { payload: { serializedToken } }) => {
          state.tokens[serializedToken.chainId] =
            state.tokens[serializedToken.chainId] || {};
          state.tokens[serializedToken.chainId][serializedToken.address] =
            serializedToken;
          state.timestamp = currentTimestamp();
        }
      )
      .addCase(
        removeSerializedToken,
        (state, { payload: { address, chainId } }) => {
          state.tokens[chainId] = state.tokens[chainId] || {};
          delete state.tokens[chainId][address];
          state.timestamp = currentTimestamp();
        }
      )
      .addCase(updateUserDeadline, (state, action) => {
        state.userDeadline = action.payload.userDeadline;
      })
      .addCase(updateGasPrice, (state, action) => {
        state.gasPrice = action.payload.gasPrice;
      });
  },
});

export const updateUserBonusRouter = createAction<{
  userBonusRouterDisabled: boolean;
}>("user/updateUserBonusRouter");

export const updateUserSingleHopOnly = createAction<{
  userSingleHopOnly: boolean;
}>("user/updateUserSingleHopOnly");

export const updateUserDeadline = createAction<{ userDeadline: number }>(
  "user/updateUserDeadline"
);

export const updateGasPrice = createAction<{ gasPrice: string }>(
  "user/updateGasPrice"
);

export default userSlice.reducer;
