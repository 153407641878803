import { Interface } from '@ethersproject/abi';
import { parseUnits } from '@ethersproject/units';
import { useWeb3React } from '@web3-react/core';
import pancakePairABI from 'config/abis/pancakePair.json';
import { RawCurrency } from 'entities/currency';
import {
  Currency,
  CurrencyAmount,
  currencyEquals,
  JSBI,
  Pair,
  Percent,
  Token,
  TokenAmount,
  Trade,
  WETH,
} from 'libs/mdex-swap';
import { useAllCurrencyCombinations } from 'libs/mdex-swap/hooks/useAllCurrencyCombinations';
import { PairState, useV2Pairs } from 'libs/mdex-swap/hooks/useV2Pairs';
import { useMemo } from 'react';
import { useMultipleContractSingleData } from 'state/multicall/multiCallHook';
import { Field } from 'state/swap/types';
import { ChainId } from "libs/pancake-swap";

const MAX_HOPS = 3;
const ZERO_PERCENT = new Percent('0');
export const ONE_HUNDRED_PERCENT = new Percent('1');
const PAIR_INTERFACE = new Interface(pancakePairABI);

const BETTER_TRADE_LESS_HOPS_THRESHOLD = new Percent(JSBI.BigInt(50), JSBI.BigInt(10000));

// try to parse a user entered amount for a given token
const tryParseAmount = (
  value?: string,
  currency?: Currency
): CurrencyAmount | TokenAmount | undefined => {
  if (!value || !currency) {
    return undefined;
  }
  try {
    const typedValueParsed = parseUnits(value, currency.decimals).toString();

    if (typedValueParsed !== '0') {
      return currency instanceof Token
        ? new TokenAmount(currency, JSBI.BigInt(typedValueParsed))
        : CurrencyAmount.ether(JSBI.BigInt(typedValueParsed));
    }
  } catch (error) {
    // should fail if the user specifies too many decimal places of precision (or maybe exceed max uint?)
  }
  // necessary for all paths to return a value
  return undefined;
};

// returns whether tradeB is better than tradeA by at least a threshold percentage amount
function isTradeBetter(
  tradeA: Trade | undefined | null,
  tradeB: Trade | undefined | null,
  minimumDelta: Percent = ZERO_PERCENT
): boolean | undefined {
  if (tradeA && !tradeB) return false;
  if (tradeB && !tradeA) return true;
  if (!tradeA || !tradeB) return undefined;

  if (
    tradeA.tradeType !== tradeB.tradeType ||
    !currencyEquals(tradeA.inputAmount.currency, tradeB.inputAmount.currency) ||
    !currencyEquals(tradeA.outputAmount.currency, tradeB.outputAmount.currency)
  ) {
    throw new Error('Trades are not comparable');
  }

  if (minimumDelta.equalTo(ZERO_PERCENT)) {
    return tradeA.executionPrice.lessThan(tradeB.executionPrice);
  }
  return tradeA.executionPrice.raw
    .multiply(minimumDelta.add(ONE_HUNDRED_PERCENT))
    .lessThan(tradeB.executionPrice);
}

function wrappedCurrency(
  currency: Currency | undefined,
  chainId: ChainId | undefined
): Token | undefined {
  return chainId && currency?.symbol === 'BNB'
    ? WETH[chainId]
    : currency instanceof Token
    ? currency
    : undefined;
}

function useAllCommonPairs(currencyA?: Currency, currencyB?: Currency): Pair[] {
  const allCurrencyCombinations = useAllCurrencyCombinations(currencyA, currencyB);

  const allPairs = useV2Pairs(allCurrencyCombinations);

  return useMemo(
    () =>
      Object.values(
        allPairs
          // filter out invalid pairs
          .filter((result): result is [PairState.EXISTS, Pair] =>
            Boolean(result[0] === PairState.EXISTS && result[1])
          )
          // filter out duplicated pairs
          .reduce<{ [pairAddress: string]: Pair }>((memo: any, [, curr]) => {
            memo[curr.liquidityToken.address] = memo[curr.liquidityToken.address] ?? curr;
            return memo;
          }, {})
      ),
    [allPairs]
  );
}

function useTradeExactIn(currencyAmountIn?: CurrencyAmount, currencyOut?: Currency): Trade | null {
  const allowedPairs = useAllCommonPairs(currencyAmountIn?.currency, currencyOut);

  return useMemo(() => {
    if (currencyAmountIn && currencyOut && allowedPairs.length > 0) {
      // search through trades with varying hops, find best trade out of them
      let bestTradeSoFar: Trade | null = null;
      for (let i = 1; i <= MAX_HOPS; i++) {
        const currentTrade: Trade | null =
          Trade.bestTradeExactIn(allowedPairs, currencyAmountIn, currencyOut, {
            maxHops: i,
            maxNumResults: 1,
          })[0] ?? null;
        // if current trade is best yet, save it
        if (isTradeBetter(bestTradeSoFar, currentTrade, BETTER_TRADE_LESS_HOPS_THRESHOLD)) {
          bestTradeSoFar = currentTrade;
        }
      }
      return bestTradeSoFar;
    }
    return null;
  }, [allowedPairs, currencyAmountIn, currencyOut]);
}

/**
 * Returns the best trade for the token in to the exact amount of token out
 */
function useTradeExactOut(currencyIn?: Currency, currencyAmountOut?: CurrencyAmount): Trade | null {
  const allowedPairs = useAllCommonPairs(currencyIn, currencyAmountOut?.currency);

  return useMemo(() => {
    if (currencyIn && currencyAmountOut && allowedPairs.length > 0) {
      // search through trades with varying hops, find best trade out of them
      let bestTradeSoFar: Trade | null = null;
      for (let i = 1; i <= MAX_HOPS; i++) {
        const currentTrade =
          Trade.bestTradeExactOut(allowedPairs, currencyIn, currencyAmountOut, {
            maxHops: i,
            maxNumResults: 1,
          })[0] ?? null;
        if (isTradeBetter(bestTradeSoFar, currentTrade, BETTER_TRADE_LESS_HOPS_THRESHOLD)) {
          bestTradeSoFar = currentTrade;
        }
      }
      return bestTradeSoFar;
    }
    return null;
  }, [currencyIn, currencyAmountOut, allowedPairs]);
}

export function useMdexDerivedSwapInfo(
  independentField: Field,
  inputCurrency: RawCurrency | undefined,
  outputCurrency: RawCurrency | undefined,
  typedValue: string
): {
  trade: Trade | undefined;
  parsedAmount: CurrencyAmount | TokenAmount | undefined;
} {
  const wrappedInputCurrency = inputCurrency
    ? inputCurrency.address
      ? Token.wrapRawCurrencyToToken(inputCurrency)
      : Currency.wrapRawCurrency(inputCurrency)
    : undefined;

  const wrappedOutputCurrency = outputCurrency
    ? outputCurrency.address
      ? Token.wrapRawCurrencyToToken(outputCurrency)
      : Currency.wrapRawCurrency(outputCurrency)
    : undefined;

  const isExactIn: boolean = independentField === Field.INPUT;

  const parsedAmount = tryParseAmount(
    typedValue,
    (isExactIn ? wrappedInputCurrency : wrappedOutputCurrency) ?? undefined
  );

  const bestTradeExactIn = useTradeExactIn(
    isExactIn ? parsedAmount : undefined,
    wrappedOutputCurrency ?? undefined
  );

  const bestTradeExactOut = useTradeExactOut(
    wrappedInputCurrency ?? undefined,
    !isExactIn ? parsedAmount : undefined
  );

  const v2Trade = isExactIn ? bestTradeExactIn : bestTradeExactOut;

  return {
    trade: v2Trade ?? undefined,
    parsedAmount,
  };
}
