import { Percent, Trade } from "../entities";
import { useWeb3React } from "@web3-react/core";
import { useTransactionDeadline } from "./useTransactionDeadline";
import { useMemo } from "react";
import { getRouterContract } from "../utils/contract";
import { Router } from "../router";
import JSBI from "jsbi";
import { TradeType } from "../constants";

export const BIPS_BASE = JSBI.BigInt(10000);

export function useSwapCallArguments(
  trade: Trade | undefined,
  allowedSlippage: number = 50,
  recipientAddressOrName: string | null
) {
  const { account, chainId, library } = useWeb3React();
  const recipient = account;
  const deadline = useTransactionDeadline();

  return useMemo(() => {
    if (!trade || !recipient || !library || !account || !chainId || !deadline)
      return [];

    const contract = getRouterContract(chainId, library, account);
    if (!contract) return [];

    const swapMethods = [];

    swapMethods.push(
      Router.swapCallParameters(trade, {
        feeOnTransfer: false,
        allowedSlippage: new Percent(JSBI.BigInt(allowedSlippage), BIPS_BASE),
        recipient,
        ttl: deadline.toNumber(),
      })
    );

    if (trade.tradeType === TradeType.EXACT_INPUT) {
      swapMethods.push(
        Router.swapCallParameters(trade, {
          feeOnTransfer: true,
          allowedSlippage: new Percent(JSBI.BigInt(allowedSlippage), BIPS_BASE),
          recipient,
          ttl: deadline.toNumber(),
        })
      );
    }

    return swapMethods.map((parameters) => ({ parameters, contract }));
  }, [account, allowedSlippage, chainId, deadline, library, recipient, trade]);
}
