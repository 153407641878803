import { Contract } from "@ethersproject/contracts";
import { useWeb3React } from "@web3-react/core";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { RootState } from "app/store";
import { useMultiCallContract } from "hooks/useContract";
import useDebounce from "hooks/useDebounce";
import { useEffect, useMemo, useRef } from "react";
import { useCurrentBlock } from "state/block/hooks";
import chunkArray from "utils/chunkArray";
import { retry, RetryableError } from "utils/retry";
import {
  errorFetchingMultiCallResults,
  fetchingMultiCallResults,
  parseCallKey,
  updateMultiCallResults,
} from "./multiCallSlice";
import { useActiveWeb3React } from "../../hooks/useActiveWeb3React";
import { useBlockNumber } from "../../hooks/useBlockNumber";

export function activeListeningKeys(
  allListeners?: RootState["multiCall"]["callListeners"],
  chainId?: number
) {
  if (!allListeners || !chainId) return {};

  const listeners = allListeners[chainId];

  if (!listeners) return {};

  return Object.keys(listeners).reduce((memo: any, callKey) => {
    const keyListeners = listeners[callKey];
    memo[callKey] = Object.keys(keyListeners)
      .filter((key) => {
        const blockPerFetch = parseInt(key);
        if (blockPerFetch <= 0) return false;
        return keyListeners[blockPerFetch] > 0;
      })
      .reduce((previousValue, currentValue) => {
        return Math.min(previousValue, parseInt(currentValue));
      }, Infinity);
    return memo;
  }, {});
}

export function outdatedListeningKeys(
  callResults: RootState["multiCall"]["callResults"],
  listeningKeys: { [callKey: string]: number },
  chainId?: number,
  currentBlock?: number
): string[] {
  if (!chainId || !currentBlock) return [];
  const results = callResults[chainId];

  if (!results) return Object.keys(listeningKeys);

  return Object.keys(listeningKeys).filter((callKey) => {
    const blockPerFetch = listeningKeys[callKey];

    const data = results[callKey];

    // no data, must fetch
    if (!data) return true;

    const minDataBlockNumber = currentBlock - (blockPerFetch - 1);

    // already fetching it for a recent enough block, don't refetch it
    if (
      data.fetchingBlockNumber &&
      data.fetchingBlockNumber >= minDataBlockNumber
    )
      return false;

    // if data is older than minDataBlockNumber, fetch it
    return !data.blockNumber || data.blockNumber < minDataBlockNumber;
  });
}

function multiCallFunction(multiCallContract: Contract, chainId: number) {
  if (chainId === 97 || chainId === 56) return multiCallContract.aggregate;
  else return multiCallContract.callStatic.multicall;
}

function multiCallFunctionParameters(chunk: Call[], chainId: number) {
  if (chainId === 97 || chainId === 56)
    return chunk.map((obj) => [obj.address, obj.callData]);
  else
    return chunk.map((obj) => ({
      target: obj.address,
      callData: obj.callData,
      gasLimit: 1000000,
    }));
}

function multiCallFunctionResult(returnData: any, chainId: number) {
  if (chainId === 97 || chainId === 56) return returnData;
  else return returnData.map((e: any) => e.returnData); //[returnData[0].returnData];
}

type ErrorWithMessageAndCode = {
  code: number;
  message: string;
};

function isErrorWithMessageAndCode(
  error: unknown
): error is ErrorWithMessageAndCode {
  return (
    (typeof error === "object" &&
      error &&
      ("message" in error || "code" in error)) ??
    false
  );
}

async function fetchChunk(
  multicall: Contract | any,
  chunk: Call[],
  blockNumber: number
): Promise<string[]> {
  try {
    const { returnData } = await multicall.callStatic.aggregate(
      chunk.map((obj) => ({
        target: obj.address,
        callData: obj.callData,
      })),
      { blockTag: blockNumber }
    );
    return returnData;
  } catch (error) {
    if (isErrorWithMessageAndCode(error)) {
      if (
        error.code === -32000 ||
        error.message?.indexOf("header not found") !== -1
      ) {
        throw new RetryableError(
          `header not found for block number ${blockNumber}`
        );
      }
    }
    console.error("Failed to fetch chunk", error);
    throw error;
  }
}
export interface Call {
  address: string;
  callData: string;
  gasRequired?: number;
}
// async function fetchChunk(
//   multiCallContract: Contract | null,
//   chunk: Call[],
//   minBlockNumber: number,
//   chainId: number
// ): Promise<{ results: string[] | undefined; blockNumber: number | undefined }> {
//   if (!multiCallContract) return { results: undefined, blockNumber: undefined };
//   console.log("multiCallContract in fetchChunk", multiCallContract);
//   console.log("chunk", chunk);

//   const { blockNumber, returnData } = await multiCallFunction(
//     multiCallContract,
//     chainId
//   )(multiCallFunctionParameters(chunk, chainId), {
//     blockTag: minBlockNumber,
//   });
//   console.log("++++ [returnData] sau khi goi aggregate +++++", returnData);
//   return {
//     results: multiCallFunctionResult(returnData, chainId),
//     blockNumber: blockNumber.toNumber(),
//   };
// }

const MultiCallUpdater = () => {
  const dispatch = useAppDispatch();
  const { chainId } = useActiveWeb3React();
  const state = useAppSelector((state) => state.multiCall);

  // const currentBlock = useCurrentBlock();
  const multiCallContract = useMultiCallContract();
  const latestBlockNumber = useBlockNumber()
  

  const debouncedListeners = useDebounce(state.callListeners, 100);
  const cancellations = useRef<{
    blockNumber: number;
    cancellations: (() => void)[];
  }>();

  const listeningKeys = useMemo(
    () => activeListeningKeys(debouncedListeners, chainId),
    [debouncedListeners, chainId]
  );

  const unserializedOutdatedCallKeys = useMemo(
    () =>
      outdatedListeningKeys(
        state.callResults,
        listeningKeys,
        chainId,
        latestBlockNumber
      ),
    [state.callResults, listeningKeys, chainId, latestBlockNumber]
  );

  const serializedOutdatedCallKeys = useMemo(
    () => JSON.stringify(unserializedOutdatedCallKeys.sort()),
    [unserializedOutdatedCallKeys]
  );

  useEffect(() => {
    if (!latestBlockNumber || !chainId) return;

    const outdatedCallKeys = JSON.parse(serializedOutdatedCallKeys);
    if (outdatedCallKeys.length === 0) return;

    const calls = outdatedCallKeys.map((key: string) => parseCallKey(key));
    const chunkedCalls = chunkArray(calls, 500);

    if (cancellations.current?.blockNumber !== latestBlockNumber) {
      cancellations.current?.cancellations?.forEach((c) => c());
    }

    dispatch(
      fetchingMultiCallResults({
        calls,
        chainId,
        fetchingBlockNumber: latestBlockNumber,
      })
    );

    cancellations.current = {
      blockNumber: latestBlockNumber,
      cancellations: chunkedCalls.map((chunk: any[], index: number) => {
        const { cancel, promise } = retry(
          () => fetchChunk(multiCallContract, chunk, latestBlockNumber),
          {
            n: Infinity,
            minWait: 2500,
            maxWait: 3500,
          }
        );

        promise
          .then((returnData) => {
            // console.log("returnData in promise", returnData);
            // accumulates the length of all previous indices
            const firstCallKeyIndex = chunkedCalls
              .slice(0, index)
              .reduce<number>((memo, curr) => memo + curr.length, 0);
            const lastCallKeyIndex = firstCallKeyIndex + returnData.length;

            const slice = outdatedCallKeys.slice(
              firstCallKeyIndex,
              lastCallKeyIndex
            );

            const { erroredCalls, results } = slice.reduce(
              (memo: any, callKey: string, i: number) => {
                memo.results[callKey] = returnData[i] ?? null;
                return memo;
              },
              { erroredCalls: [], results: {} }
            );

            // dispatch any new results
            if (Object.keys(results).length > 0) {
              dispatch(
                updateMultiCallResults({
                  chainId,
                  results,
                  blockNumber: latestBlockNumber,
                })
              );
            }

            // dispatch any errored calls
            if (erroredCalls.length > 0) {
              console.debug("Calls errored in fetch", erroredCalls);
              dispatch(
                errorFetchingMultiCallResults({
                  calls: erroredCalls,
                  chainId,
                  fetchingBlockNumber: latestBlockNumber,
                })
              );
            }

            // split the returned slice into errors and success
          })
          .catch((error: any) => {
            if (error.isCancelledError) {
              console.debug(
                "Cancelled fetch for blockNumber",
                latestBlockNumber,
                chunk,
                chainId
              );
              return;
            }
            console.error(
              "Failed to fetch multicall chunk",
              chunk,
              chainId,
              error
            );
            dispatch(
              errorFetchingMultiCallResults({
                calls: chunk,
                chainId,
                fetchingBlockNumber: latestBlockNumber,
              })
            );
          });

        // promise
        //   .then(({ results: returnData, blockNumber: fetchBlockNumber }) => {
        //     console.log("returnData in promise", returnData);
        //     if (!returnData || !fetchBlockNumber) return;
        //     cancellations.current = {
        //       cancellations: [],
        //       blockNumber: currentBlock,
        //     };
        //     const fistCallKeyIndex = chunkedCalls
        //       .slice(0, index)
        //       .reduce((memo, current) => memo + current.length, 0);

        //     const lastCallKeyIndex = fistCallKeyIndex + returnData.length;

        //     dispatch(
        //       updateMultiCallResults({
        //         chainId,
        //         results: outdatedCallKeys
        //           .slice(fistCallKeyIndex, lastCallKeyIndex)
        //           .reduce((memo: any, callKey: string, i: number) => {
        //             memo[callKey] = returnData[i] ?? null;
        //             return memo;
        //           }, {}),
        //         blockNumber: fetchBlockNumber,
        //       })
        //     );
        //   })
        //   .catch((error: any) => {
        //     console.error("Failed to fetch multi call chunk", error);
        //     dispatch(
        //       errorFetchingMultiCallResults({
        //         calls: chunk,
        //         chainId,
        //         fetchingBlockNumber: currentBlock,
        //       })
        //     );
        //   });

        return cancel;
      }),
    };
  }, [
    chainId,
    latestBlockNumber,
    debouncedListeners,
    listeningKeys,
    unserializedOutdatedCallKeys,
    serializedOutdatedCallKeys,
    dispatch,
    multiCallContract,
  ]);

  return null;
};

export default MultiCallUpdater;
