import { Radio, RadioChangeEvent } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import TokenInfo from "./TokenInfo";
import { useGetInfoCoin } from "../../../utils/getInfoCoin";
import axios from "axios";
import { Currency } from "entities/currency";
export interface TokenInfo {
  symbol: string;
  price: number;
  tradingVolume24h: number;
  marketCapRank: number;
  marketCap: number;
  allTimeHigh: number;
  allTimeLow: number;
  circulatingSupply: number;
  totalSupply: number;
  networkSupported: string;
  contractAddress: string;
}

interface SwapInfoProps {
  tokenInput?: Currency | null;
  tokenOutput?: Currency;
}

export const SwapInfo: React.FC<SwapInfoProps> = ({
  tokenInput,
  tokenOutput,
}) => {
  const rowTable = useMemo(
    () => [
      { name: "Price Change 24H", field: "price_change_24h" },
      { name: "Trading Volume (24H)", field: "total_volume" },
      { name: "Market Cap Rank", field: "market_cap_rank" },
      { name: "Market Cap", field: "market_cap" },
      { name: "All-time High", field: "high_24h" },
      { name: "All-time Low", field: "low_24h" },
      { name: "Circulating Supply", field: "circulating_supply" },
      { name: "Total Supply", field: "total_supply" },
      { name: "Network Supported", field: "name" },
    ],
    []
  );
  const [currentTab, setCurrentTab] = useState<number>(0);

  const { tokenInfoInput, tokenInfoOutput } = useGetInfoCoin(
    tokenInput?.symbol ? tokenInput?.symbol : "",
    tokenOutput?.symbol ? tokenOutput?.symbol : ""
  );

  return (
    <>
      <div className="s-tabs">
        <div
          className={`${currentTab === 0 && "active"}`}
          onClick={() => {
            setCurrentTab(0);
          }}
        >
          {tokenInput?.symbol || "- - -"}
        </div>
        <div
          className={`${currentTab === 1 && "active"}`}
          onClick={() => {
            setCurrentTab(1);
          }}
        >
          {tokenOutput?.symbol || "- - -"}
        </div>
      </div>
      <div className="s-tab">
        {currentTab === 0 && (
          <>
            {rowTable.map((row) => {
              return (
                <TokenInfo
                  name={row.name}
                  token={tokenInfoInput}
                  field={row.field}
                />
              );
            })}
          </>
        )}

        {currentTab === 1 && (
          <>
            {rowTable.map((row) => {
              return (
                <TokenInfo
                  name={row.name}
                  token={tokenInfoOutput}
                  field={row.field}
                />
              );
            })}
          </>
        )}
      </div>
    </>

    // <div className="bordered p-10 mb-5">
    //   <Radio.Group
    //     className="w-100 mb-5"
    //     onChange={onChange}
    //     buttonStyle="solid"
    //     defaultValue={selectedToken.symbol}
    //     value={selectedToken.symbol}
    //   >
    //     <Radio.Button className="swap-info__select" value={token0.symbol}>
    //       {token0.symbol}
    //     </Radio.Button>
    //     <Radio.Button className="swap-info__select" value={token1.symbol}>
    //       {token1.symbol}
    //     </Radio.Button>
    //   </Radio.Group>
    //   <InfoItem
    //     className="px-0 py-10"
    //     field="Price"
    //     value={<span>${selectedToken.price}</span>}
    //     fieldBold
    //   />
    //   <InfoItem
    //     className="px-0 py-10"
    //     field="Trading Volume (24h)"
    //     value={<span>${selectedToken.tradingVolume24h}</span>}
    //     bordered
    //     fieldBold
    //   />
    //   <InfoItem
    //     className="px-0 py-10"
    //     field="Market Cap Rank"
    //     value={<span>#{selectedToken.marketCapRank}</span>}
    //     bordered
    //     fieldBold
    //   />
    //   <InfoItem
    //     className="px-0 py-10"
    //     field="Market Cap"
    //     value={<span>${selectedToken.marketCap}</span>}
    //     bordered
    //     fieldBold
    //   />
    //   <InfoItem
    //     className="px-0 py-10"
    //     field="All-Time High"
    //     value={<span>${selectedToken.allTimeHigh}</span>}
    //     bordered
    //     fieldBold
    //   />
    //   <InfoItem
    //     className="px-0 py-10"
    //     field="All-Time Low"
    //     value={<span>${selectedToken.allTimeLow}</span>}
    //     bordered
    //     fieldBold
    //   />
    //   <InfoItem
    //     className="px-0 py-10"
    //     field="Circulating Supply"
    //     value={<span>{selectedToken.circulatingSupply}</span>}
    //     bordered
    //     fieldBold
    //   />
    //   <InfoItem
    //     className="px-0 py-10"
    //     field="Total Supply"
    //     value={<span>{selectedToken.totalSupply}</span>}
    //     bordered
    //     fieldBold
    //   />
    //   <InfoItem
    //     className="px-0 py-10"
    //     field="Network supported"
    //     value={<span>{selectedToken.networkSupported}</span>}
    //     bordered
    //     fieldBold
    //   />
    //   <InfoItem
    //     className="border-bottom-none px-0 py-10"
    //     field="Contract Address"
    //     value={<span>{selectedToken.contractAddress}</span>}
    //     bordered
    //     fieldBold
    //   />
    // </div>
  );
};
