import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "app/store";
import { RouteSwap } from "models";

export interface SwapState {
  swapRoutes: RouteSwap[];
  isViewMoreRouteSwap: boolean;
}

const initialState: SwapState = {
  swapRoutes: [],
  isViewMoreRouteSwap: false,
};

const swapSlice = createSlice({
  name: "swapView",
  initialState,
  reducers: {
    setSwapRoutes: (state, action: PayloadAction<RouteSwap[]>) => {
      state.swapRoutes = action.payload;
    },
    setIsViewMoreRouteSwap: (state, action: PayloadAction<boolean>) => {
      state.isViewMoreRouteSwap = action.payload;
    },
  },
  extraReducers: {},
});

// Actions
export const { setSwapRoutes, setIsViewMoreRouteSwap } = swapSlice.actions;

// Selectors
export const selectSwapRoutes = (state: RootState) => state.swapView.swapRoutes;
export const selectIsViewMoreRouteSwap = (state: RootState) =>
  state.swapView.isViewMoreRouteSwap;

// Reducer
export default swapSlice.reducer;
