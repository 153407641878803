import { useCurrentBlockTimestamp } from "./useCurrentBlockTimestamp";
import { useMemo } from "react";

export const DEFAULT_DEADLINE_FROM_NOW = 60 * 20;

export function useTransactionDeadline() {
  const ttl = DEFAULT_DEADLINE_FROM_NOW;
  const blockTimestamp = useCurrentBlockTimestamp();
  return useMemo(() => {
    if (blockTimestamp && ttl) return blockTimestamp.add(ttl);
    return undefined;
  }, [blockTimestamp, ttl]);
}
