import { Interface } from "@ethersproject/abi";
import { Contract } from "@ethersproject/contracts";
import { useWeb3React } from "@web3-react/core";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { useBlockNumber } from "hooks/useBlockNumber";
import { useEffect, useMemo } from "react";
import { isAddress } from "utils/addressHelpers";
import {
  addMultiCallListeners,
  Call,
  ListenerOptions,
  MultiCallState,
  parseCallKey,
  removeMultiCallListeners,
  toCallKey,
} from "./multiCallSlice";
import { BigNumber } from "@ethersproject/bignumber";
import { useActiveWeb3React } from "hooks/useActiveWeb3React";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "app/store";
import { debounce } from "utils/debounce";
export { NEVER_RELOAD } from "@uniswap/redux-multicall";

interface CallResult {
  readonly valid: boolean;
  readonly data: string | undefined;
  readonly blockNumber: number | undefined;
}

const INVALID_RESULT = {
  valid: false,
  blockNumber: undefined,
  data: undefined,
};

const INVALID_CALL_STATE = {
  valid: false,
  result: undefined,
  loading: false,
  syncing: false,
  error: false,
};

const LOADING_CALL_STATE = {
  valid: true,
  result: undefined,
  loading: true,
  syncing: true,
  error: false,
};

function useCallsData(
  calls: (Call | undefined)[],
  { blocksPerFetch }: ListenerOptions = { blocksPerFetch: 1 },
  name?: string | "noooo"
) {
  const { chainId } = useActiveWeb3React();
  const callResults = useSelector<RootState, MultiCallState["callResults"]>(
    (state) => state.multiCall.callResults
  );

  const dispatch = useDispatch();

  const { callKeys, stringifiedCalls } = useMemo(() => {
    const filteredCalls =
      calls
        ?.filter((c): c is Call => Boolean(c))
        ?.map(toCallKey)
        ?.sort() ?? [];

    return {
      callKeys: filteredCalls.map((key) => parseCallKey(key)),
      stringifiedCalls: JSON.stringify(filteredCalls),
    };
  }, [calls]);

  const debouncedAddMultiCall = useMemo(() => {
    return debounce((chainId, calls) => {
      dispatch(
        addMultiCallListeners({
          chainId,
          calls,
          options: { blocksPerFetch },
        })
      );
    }, 250);
  }, [dispatch, blocksPerFetch]);

  const debouncedRemoveMultiCall = useMemo(() => {
    return debounce((chainId, calls) => {
      dispatch(
        removeMultiCallListeners({
          chainId,
          calls,
          options: { blocksPerFetch },
        })
      );
    }, 250);
  }, [dispatch, blocksPerFetch]);

  // update listeners when there is an actual change that persists for at least 100ms
  useEffect(() => {
    if (!chainId || callKeys.length === 0) return undefined;
    debouncedAddMultiCall(chainId, callKeys);

    return () => {
      debouncedRemoveMultiCall(chainId, callKeys);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    chainId,
    dispatch,
    stringifiedCalls,
    debouncedAddMultiCall,
    debouncedRemoveMultiCall,
  ]);

  return useMemo(
    () =>
      calls.map<CallResult>((call) => {
        if (!chainId || !call) return INVALID_RESULT;

        const result = callResults[chainId]?.[toCallKey(call)];
        let data;
        if (result?.data && result?.data !== "0x") {
          data = result.data;
        }

        return { valid: true, data, blockNumber: result?.blockNumber };
      }),
    [callResults, calls, chainId]
  );
}

function toCallState(
  callResult: any | undefined,
  contractInterface: Interface | undefined,
  fragment: any | undefined,
  latestBlockNumber: number | undefined
) {
  if (!callResult) return INVALID_CALL_STATE;
  const { valid, data, blockNumber } = callResult;
  if (!valid) return INVALID_CALL_STATE;
  if (valid && !blockNumber) {
    return LOADING_CALL_STATE;
  }
  if (!contractInterface || !fragment || !latestBlockNumber) {
    return LOADING_CALL_STATE;
  }

  const success = data && data.length > 2;
  const syncing = (blockNumber ?? 0) < latestBlockNumber;
  let result;
  if (success && data) {
    try {
      result = contractInterface.decodeFunctionResult(fragment, data);
    } catch (error) {
      return {
        valid: true,
        loading: false,
        error: true,
        syncing,
        result,
      };
    }
  }
  return {
    valid: true,
    loading: false,
    syncing,
    result,
    error: !success,
  };
}

export function useSingleContractMultipleData(
  contract: Contract | null | undefined,
  methodName: string,
  callInputs: any[],
  options: Partial<ListenerOptions> & { gasRequired?: number } = {},
) {
  const fragment = useMemo(
    () => contract?.interface?.getFunction(methodName),
    [contract, methodName]
  );
  const blocksPerFetch = options?.blocksPerFetch;
  const gasRequired = options?.gasRequired;
  const calls = useMemo(
    () =>
      contract && fragment && callInputs && callInputs.length > 0
        ? callInputs.map((input) => ({
            address: contract.address,
            callData: contract.interface.encodeFunctionData(fragment, input),
            ...(gasRequired ? { gasRequired } : {}),
          }))
        : [],
    [contract, fragment, callInputs, gasRequired]
  );

  const results = useCallsData(
    calls,
    blocksPerFetch ? { blocksPerFetch } : undefined,
    "useSingleContractMultipleData"
  );
  const blockNumber = useBlockNumber();
  return useMemo(() => {
    const currentBlockNumber = blockNumber;
    return results.map((result) => {
      return toCallState(result, contract?.interface, fragment, blockNumber);
    });
  }, [fragment, contract, results, blockNumber]);
}

type MethodArg = string | number | BigNumber;
type MethodArgs = Array<MethodArg | MethodArg[]>;

function isMethodArg(x: unknown): x is MethodArg {
  return ["string", "number"].indexOf(typeof x) !== -1;
}

function isValidMethodArgs(x: unknown): x is MethodArgs | undefined {
  return (
    x === undefined ||
    (Array.isArray(x) &&
      x.every(
        (xi) => isMethodArg(xi) || (Array.isArray(xi) && xi.every(isMethodArg))
      ))
  );
}

export function useMultipleContractSingleData(
  addresses: (string | undefined)[],
  contractInterface: Interface,
  methodName: string,
  callInputs?: any,
  options?: Partial<ListenerOptions> & { gasRequired?: number },
  name?: string,
) {
  const fragment = useMemo(
    () => contractInterface.getFunction(methodName),
    [contractInterface, methodName]
  );
  const blocksPerFetch = options?.blocksPerFetch
  const gasRequired = options?.gasRequired

  const callData = useMemo(
    () =>
      fragment && isValidMethodArgs(callInputs)
        ? contractInterface.encodeFunctionData(fragment, callInputs)
        : undefined,
    [callInputs, contractInterface, fragment]
  );

  const calls = useMemo(
    () =>
      fragment && addresses && addresses.length > 0 && callData
        ? addresses
            .filter((address) => isAddress(address) !== false)
            .map((address) =>
              address && callData ? { address, callData ,...(gasRequired ? { gasRequired } : {}),} : undefined
            )
        : [],
    [addresses, callData, fragment, gasRequired]
  );

  const results = useCallsData(calls,  blocksPerFetch ? { blocksPerFetch } : undefined);
  
  const {chainId} = useActiveWeb3React()
  const blockNumber = useBlockNumber();

  return useMemo(
    () =>
      results.map((result) =>
        toCallState(result, contractInterface, fragment, blockNumber)
      ),
    [fragment,chainId, results, contractInterface, blockNumber]
  );
}

export function useSingleCallResult(
  contract: Contract | null | undefined,
  methodName: string,
  inputs?: any,
  options: Partial<ListenerOptions> & { gasRequired?: number } = {}
) {
  const fragment = useMemo(
    () => contract?.interface.getFunction(methodName),
    [contract, methodName]
  );
  const blocksPerFetch = options?.blocksPerFetch;
  const gasRequired = options?.gasRequired;

  const calls = useMemo(() => {
    return contract && fragment && isValidMethodArgs(inputs)
      ? [
          {
            address: contract.address,
            callData: contract.interface.encodeFunctionData(fragment, inputs),
            ...(gasRequired ? { gasRequired } : {}),
          },
        ]
      : [];
  }, [contract, fragment, inputs, gasRequired]);

  const result = useCallsData(
    calls,
    blocksPerFetch ? { blocksPerFetch } : undefined
  )[0];
  const blockNumber = useBlockNumber();
  return useMemo(() => {
    return toCallState(result, contract?.interface, fragment, blockNumber);
  }, [blockNumber, result, contract?.interface, fragment]);
  // return {};
}
