import { CurrencyAmount, JSBI } from "@ape.swap/sdk";
import { useMemo } from "react";
import { useSingleContractMultipleData } from "state/multicall/multiCallHook";
import { useActiveWeb3React } from "./useActiveWeb3React";
import { useMultiCallContract } from "./useContract";

export function useNativeCurrencyBalance(): CurrencyAmount | undefined {
  const { chainId, account } = useActiveWeb3React();
  const multicallContract = useMultiCallContract();
  const results = useSingleContractMultipleData(
    multicallContract,
    // the name is misleading. Depending on the deployment network, the
    // function returns the native currency balance
    "getEthBalance",
    account ? [[account]] : []
  );


  return useMemo(() => {
    if (!chainId) return;
    const value = results?.[0]?.result?.[0];
    if (!value) return;
    return CurrencyAmount.ether(JSBI.BigInt(value.toString()));
  }, [results, chainId]);
}
