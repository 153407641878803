import React, { ReactNode } from "react";

interface ConfirmModalContentProps {
  topContent: () => ReactNode;
  bottomContent: () => ReactNode;
  onConfirm: () => void;
}

const ConfirmModalContent: React.FunctionComponent<
  ConfirmModalContentProps
> = ({ topContent, bottomContent, onConfirm }) => {
  return (
    <>
      <div className="modal--swap--confirm--content">
        {topContent()} {bottomContent()}
        <div className="modal--swap--confirm--content__btn"></div>
        <button className="btn btn-primary" onClick={onConfirm}>Confirm Swap</button>
      </div>
    </>
  );
};

export default ConfirmModalContent;
