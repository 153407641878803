import { useWeb3React } from "@web3-react/core";
import {
  useNetworkSwitcherToggle,
  useUnsupportedChainIdError,
  useWalletModalToggle,
} from "hooks";
import React, { useEffect } from "react";
import ConnectWalletWrapper from "./ConnectWalletWrapper";
import Web3StatusInner from "./Web3StatusInner";

interface Web3StatusProps {}

const Web3Status: React.FunctionComponent<Web3StatusProps> = (props) => {
  const toggleWalletSwitcher = useWalletModalToggle();

  const toggleNetworkSwitcher = useNetworkSwitcherToggle();

  const { active, activate, account, error } = useWeb3React();
  const unsupportedChainIdError = useUnsupportedChainIdError();

  useEffect(() => {
  }, [account]);

  return (
    <ConnectWalletWrapper>
      {!account && !unsupportedChainIdError && (
        <button className="btn btn--connect" onClick={toggleWalletSwitcher}>
          Connect Wallet
        </button>
      )}
      {!account && unsupportedChainIdError && (
        <button className="btn btn--connect" onClick={toggleNetworkSwitcher}>
          Switch to BSC network
        </button>
      )}

      {account && !unsupportedChainIdError && <Web3StatusInner />}
    </ConnectWalletWrapper>
  );
};

export default Web3Status;
