import { useSWRConfig } from "swr";
import { useMemo } from "react";
import { useWeb3React } from "@web3-react/core";
import useSWRImmutable from 'swr/immutable'
import { useActiveWeb3React } from "./useActiveWeb3React";
import { useAppSelector } from "app/hooks";
import { RootState } from "app/store";

export function useBlockNumber() {
  // const { chainId } = useWeb3React();
  // const { cache } = useSWRConfig();

  // return useMemo(() => {
  //   if (!chainId) {
  //     return 0;
  //   }
  //   if (chainId === 97 || chainId === 56) {
  //     return chainId === 97 || chainId === 56
  //       ? cache.get("bscBlockNumber")
  //       : cache.get("ethBlockNumber");
  //   }
  // }, [cache.get("bscBlockNumber"), chainId]);
  // const { chainId } = useWeb3React()
  // const { data: currentBlock = 0 } = useSWRImmutable(['blockNumber', chainId])
  // return currentBlock
  
  const { chainId } = useActiveWeb3React()
  
  return useAppSelector((state: RootState) => state.application.blockNumber[chainId ?? -1])
}
