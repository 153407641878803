import { MenuOutlined } from '@ant-design/icons';
import { ID_TO_CHAIN_ID } from '@uniswap/smart-order-router';
import { Button, Input, List, Modal } from 'antd';
import { TokenItem } from 'features/swap/components/TokenItem';
import { useToken } from 'hooks';
import React, { useState } from 'react';
import { getAllNativeCurrenciesAndTokens, MyToken } from './useToken';
import { useWeb3React } from '@web3-react/core';
import { useCurrencyBalances } from 'hooks/useBalances';

interface UseManageTokenModalResult {
  modal: JSX.Element;
  showModal: () => void;
  currentToken: any | null;
}

export const useManageTokenModal = (): UseManageTokenModalResult => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [searchValue, setSearchValue] = useState<string>('');
  const [currentToken, setCurrentToken] = useState<MyToken | null>(null);
  const { chainId = 0, account } = useWeb3React();

  const tokens = getAllNativeCurrenciesAndTokens(chainId);
  const balances = useCurrencyBalances(account ?? undefined, tokens).reduce(
    (memo: any, balance) => {
      if (balance?.currency.symbol) memo[balance?.currency.symbol] = balance?.amount.toFixed(4);
      return memo;
    },
    {}
  );

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const onSwap = () => {
    showModal();
  };

  const onSelectToken = (token: MyToken) => {
    setCurrentToken(token);
    setIsModalVisible(false);
  };

  const modal: JSX.Element = (
    <Modal open={isModalVisible} onOk={handleOk} onCancel={handleCancel} footer={null}>
      <div className="text-center">
        <span className="title">Select a Token</span>
      </div>

      <div className="my-10">
        <Input
          placeholder="ETH"
          onChange={(e) => setSearchValue(e.target.value)}
          value={searchValue}
        />
      </div>

      <List
        itemLayout="horizontal"
        dataSource={tokens}
        style={{ height: 500, overflow: 'auto' }}
        renderItem={(item) => (
          <TokenItem
            token={item}
            price={balances && item.symbol ? balances[item.symbol] : 0}
            onClick={onSelectToken}
          />
        )}
      />

      <Button
        className="mt-8 btn-primary"
        type="primary"
        block
        onClick={onSwap}
        icon={<MenuOutlined style={{ marginRight: '5px' }} />}
      >
        <strong>Manage</strong>
      </Button>
    </Modal>
  );
  return {
    modal,
    showModal,
    currentToken,
  };
};
