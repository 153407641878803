import React from "react";
import ReloadIcon from "./ReloadIcon";

interface IconsComponentProps {
  iconName: string;
  className?: string;
  cursor?: boolean;
  loading: boolean;
}

const IconsComponent: React.FunctionComponent<IconsComponentProps> = ({
  iconName,
  className,
  cursor,
  loading,
}) => {
  switch (iconName) {
    case "reload":
      return (
        <ReloadIcon className={className ?? "reload--icon"} cursor loading />
      );
    default:
      return <></>;
  }
};

export default IconsComponent;
