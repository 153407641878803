import { ChainId } from "constants/chainIds";
import { networkOptionsPreset } from "constants/networkSwitch";
import { Currency } from "entities/currency";
import { useMemo } from "react";

export function useNativeCurrency(chainId?: ChainId) {
  return useMemo(() => {
    return networkOptionsPreset.filter(
      (network) => network.chainId === chainId
    );
  }, [chainId]);
}
