import { useWeb3React } from "@web3-react/core";
import { Image } from "antd";
import { Header } from "antd/lib/layout/layout";
import { useAppDispatch, useAppTranslation } from "app/hooks";
import metaMask from "assets/images/MetaMask_Fox.svg.png";
import logo from "assets/images/dexwonderFull.png";
import { setIsLoading } from "components/UIElements/loadingSlice";
import LoadingSpinner from "components/UIElements/LoadingSpinner";
import Web3Status from "components/Web3Status";
import { useNativeBalance } from "hooks/useBalances";
import { useConnectWalletModal } from "hooks/useConnectWalletModal";
import { default as React, useEffect, useState } from "react";
import { useThemeSwitcher } from "react-css-theme-switcher";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { cutString } from "utils/commonUtil";

interface HeaderProps {}

export const MyHeader: React.FunctionComponent<HeaderProps> = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const t = useAppTranslation();
  const { account, chainId } = useWeb3React();
  // const { modalConnectWallet, showModalConnectWallet } =
  //   useConnectWalletModal();
  const location = useLocation();
  const { switcher, themes, currentTheme } = useThemeSwitcher();
  const [isDropdown, setIsDropdow] = useState(false);

  const checkActive = (match: string) => {
    if (!location) return false;
    const { pathname } = location;
    return pathname === match;
  };

  const switchTheme = () => {
    switcher({ theme: currentTheme === "dark" ? themes.light : themes.dark });
    localStorage.setItem(
      "product-theme",
      currentTheme === "dark" ? themes.light : themes.dark
    );
  };

  const showDropdownMenu = () => {
    setIsDropdow(!isDropdown);
  };

  useEffect(() => {
    // account ? dispatch(setIsLoading(false)) : dispatch(setIsLoading(true));
  }, [account]);

  return (
    <>
      <Header className="header">
        <div className="left--side">
          {/* logo */}
          <div
            className="header--logo"
            onClick={() => {
              navigate("/");
            }}
          >
            <Image src={logo} preview={false} width={200} />
          </div>
          <div className="header--nav">
            <NavItems />
          </div>

        </div>
        <div className="right--side">
          <Web3Status />
          {/* {!account && (
            <button
              className="btn btn-default"
              onClick={showModalConnectWallet}
            >
              Connect wallet
            </button>
          )} */}

          {/* {account && (
            <div className="header--wallet">
              <div className="header--wallet__img">
                <Image src={metaMask} preview={false} width={28} height={28} />
              </div>
              <div className="header--wallet__balance">
                {chainId && nativeBalance && nativeBalance[account] ? (
                  <>
                    <span>
                      {nativeBalance[account]?.amount.toFixed(4)}{" "}
                      {nativeBalance[account]?.currency.symbol}
                    </span>
                    <span>
                      {cutString({
                        start: 6,
                        end: 4,
                        originalString: account,
                        replaceBy: "...",
                      })}
                    </span>
                  </>
                ) : (
                  <LoadingSpinner />
                )}
              </div>
            </div>
          )} */}
        </div>
      </Header>
      {/* {modalConnectWallet} */}
    </>
  );
};

const NavItems = () => {
  const location = useLocation();

  return (
    <>
      {/* {["Swap", "Pool", "DeG", "Vote"].map((name) => (
        <NavItem
          name={name}
          pathname={location.pathname}
          url={"/my/" + name.toLowerCase()}
        />
      ))} */}
    </>
  );
};

interface NavItemProps {
  name: string;
  url: string;
  pathname: string;
}

const NavItem = ({ name, pathname, url }: NavItemProps) => {
  return (
    <>
      <NavLink
        to={url}
        className={`nav--item ${pathname === url ? "nav--item__active" : ""}`}
      >
        {name}
      </NavLink>
    </>
  );
};
