import { createAction } from "@reduxjs/toolkit";
export interface SerializedToken {
  chainId: number;
  address: string;
  decimals: number;
  symbol?: string;
  name?: string;
}
export const updateUserBonusRouter = createAction<{
  userBonusRouterDisabled: boolean;
}>("user/updateUserBonusRouter");

export const addSerializedToken = createAction<{
  serializedToken: SerializedToken;
}>("user/addSerializedToken");
export const removeSerializedToken = createAction<{
  chainId: number;
  address: string;
}>("user/removeSerializedToken");
