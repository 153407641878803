import contracts from 'config/constants/contracts';
import multi from '../abis/Multicall.json';
import { useSingleCallResult } from 'state/multicall/multiCallHook';
import { BigNumber } from 'ethers';
import { useWeb3React } from '@web3-react/core';
import { useContract, useMultiCallContract } from 'hooks/useContract';

// gets the current timestamp from the blockchain
export default function useCurrentBlockTimestamp(): BigNumber | undefined {
  const multical = useMultiCallContract();
  return useSingleCallResult(multical, 'getCurrentBlockTimestamp')?.result?.[0];
}
