import { createSlice, createAction } from "@reduxjs/toolkit";
import { RouterTypes } from "libs/ape-swap/constants";
import {
  RouterTypeParams,
  setBestRoute,
  setSwapDelay,
  SwapDelay,
} from "./actions";
import {
  DerivedPairDataNormalized,
  Field,
  PairDataNormalized,
  PairDataTimeWindowEnum,
} from "./types";
import { SmartRouter } from "@ape.swap/sdk";

export interface SwapState {
  readonly independentField: Field;
  readonly typedValue: string;
  readonly [Field.INPUT]: {
    readonly currencyId: string | undefined;
  };
  readonly [Field.OUTPUT]: {
    readonly currencyId: string | undefined;
  };
  readonly recipient: string | null;
  readonly pairDataById: Record<number, Record<string, PairDataNormalized>>;
  readonly derivedPairDataById: Record<
    number,
    Record<string, DerivedPairDataNormalized>
  >;
  readonly swapDelay: SwapDelay;
  readonly bestRoute: RouterTypeParams;
}

const initialState: SwapState = {
  independentField: Field.INPUT,
  typedValue: "",
  [Field.INPUT]: {
    currencyId: "",
  },
  [Field.OUTPUT]: {
    currencyId: "",
  },
  pairDataById: {},
  derivedPairDataById: {},
  recipient: null,
  swapDelay: SwapDelay.INIT,
  bestRoute: { routerType: RouterTypes.APE, smartRouter: SmartRouter.APE },
};

export const selectCurrency = createAction<{
  field: Field;
  currencyId: string;
}>("swap/selectCurrency");

export const switchCurrencies = createAction<void>("swap/switchCurrencies");
export const typeInput = createAction<{ field: Field; typedValue: string }>(
  "swap/typeInput"
);
export const replaceSwapState = createAction<{
  field: Field;
  typedValue: string;
  inputCurrencyId?: string;
  outputCurrencyId?: string;
  recipient: string | null;
  swapDelay: SwapDelay;
  bestRoute: RouterTypeParams;
}>("swap/replaceSwapState");

export const setRecipient = createAction<{
  recipient: string | null;
}>("swap/setRecipient");

export const updatePairData = createAction<{
  pairData: PairDataNormalized;
  pairId: number;
  timeWindow: PairDataTimeWindowEnum;
}>("swap/updatePairData");

export const updateDerivedPairData = createAction<{
  pairData: DerivedPairDataNormalized;
  pairId: number;
  timeWindow: PairDataTimeWindowEnum;
}>("swap/updateDerivedPairData");

export const swapSlice = createSlice({
  name: "swap",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(
        replaceSwapState,
        (
          state,
          {
            payload: {
              typedValue,
              recipient,
              field,
              inputCurrencyId,
              outputCurrencyId,
              swapDelay,
              bestRoute,
            },
          }
        ) => {
          return {
            [Field.INPUT]: {
              currencyId: inputCurrencyId,
            },
            [Field.OUTPUT]: {
              currencyId: outputCurrencyId,
            },
            independentField: field,
            typedValue,
            recipient,
            pairDataById: state.pairDataById,
            derivedPairDataById: state.derivedPairDataById,
            swapDelay,
            bestRoute,
          };
        }
      )
      .addCase(selectCurrency, (state, { payload: { currencyId, field } }) => {
        const otherField = field === Field.INPUT ? Field.OUTPUT : Field.INPUT;
        if (currencyId === state[otherField].currencyId) {
          return {
            ...state,
            independentField:
              state.independentField === Field.INPUT
                ? Field.OUTPUT
                : Field.INPUT,
            [field]: { currencyId },
            [otherField]: { currencyId: state[field].currencyId },
          };
        }
        return {
          ...state,
          [field]: { currencyId },
        };
      })
      .addCase(switchCurrencies, (state) => {
        return {
          ...state,
          independentField:
            state.independentField === Field.INPUT ? Field.OUTPUT : Field.INPUT,
          [Field.INPUT]: { currencyId: state[Field.OUTPUT].currencyId },
          [Field.OUTPUT]: { currencyId: state[Field.INPUT].currencyId },
        };
      })
      .addCase(typeInput, (state, { payload: { field, typedValue } }) => {
        return {
          ...state,
          independentField: field,
          typedValue,
        };
      })
      .addCase(setRecipient, (state, { payload: { recipient } }) => {
        state.recipient = recipient;
      })
      .addCase(
        updatePairData,
        (state, { payload: { pairData, pairId, timeWindow } }) => {
          if (!state.pairDataById[pairId]) state.pairDataById[pairId] = {};
          state.pairDataById[pairId][timeWindow] = pairData;
        }
      )
      .addCase(
        updateDerivedPairData,
        (state, { payload: { pairData, pairId, timeWindow } }) => {
          if (!state.derivedPairDataById[pairId])
            state.derivedPairDataById[pairId] = {};
          state.derivedPairDataById[pairId][timeWindow] = pairData;
        }
      )
      .addCase(setSwapDelay, (state, { payload: { swapDelay } }) => {
        state.swapDelay = swapDelay;
      })
      .addCase(setBestRoute, (state, { payload: { bestRoute } }) => {
        state.bestRoute = bestRoute;
      });
  },
});

export const actions = swapSlice.actions;

export default swapSlice.reducer;
