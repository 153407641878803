import { useDerivedSwapInfo } from "../state/swap/swapHook";
import { useSwapCallback } from "../libs/pancake-swap/hooks/useSwapCallback";
import { useUserSlippageTolerance } from "../state/user/userHook";
import { useMdexSwapCallback } from "../libs/mdex-swap/hooks/useMdexSwapCallback";
import { useSwapCallback as useBiSwapCallback } from "../libs/bi-swap/hooks/useSwapCallback";
import { useApeSwapCallback } from "../libs/ape-swap/hooks/useApeSwapCallback";
import {
  ApprovalState,
  useApproveCallbackFromTrade,
} from "../libs/pancake-swap/hooks/useApproveCallback";
import { useApproveCallbackFromTrade as useApproveCallbackFromTradeApe } from "../libs/ape-swap/hooks/useApproveCallback";
import { useApproveCallbackFromTrade as useApproveCallbackFromTradeMdex } from "../libs/mdex-swap/hooks/useApproveCallback";
import { useApproveCallbackFromTrade as useApproveCallbackFromTradeBi } from "../libs/bi-swap/hooks/useApproveCallback";
import { Field } from "state/swap/types";
import { RawCurrency } from "entities/currency";
export const useSwapHandler = ({
  independentField,
  typedValue,
  inputCurrency,
  outputCurrency,
  recipient,
}: {
  independentField: Field;
  typedValue: string;
  inputCurrency: RawCurrency | undefined;
  outputCurrency: RawCurrency | undefined;
  recipient: string | null;
}) => {
  const {
    currencyBalances,
    trade,
    mdexTrade,
    biswapTrade,
    apeswapTrade,
    apeswapBestRoute,
    currencies,
  } = useDerivedSwapInfo(
    independentField,
    typedValue,
    inputCurrency,
    outputCurrency,
    recipient
  );
  const [allowedSlippage] = useUserSlippageTolerance();

  const { callback: swapCallback } = useSwapCallback(
    trade.trade,
    allowedSlippage,
    recipient
  );

  const { callback: mdexSwapCallback } = useMdexSwapCallback(
    mdexTrade.trade,
    allowedSlippage,
    recipient
  );

  const { callback: biSwapCallback } = useBiSwapCallback(
    biswapTrade.trade,
    allowedSlippage,
    recipient
  );

  const { callback: apeswapCallback, error: swapCallbackError } =
    useApeSwapCallback(
      apeswapTrade.trade,
      allowedSlippage,
      recipient
      // apeswapBestRoute
    );

  const [approvalState, approveCallback] = useApproveCallbackFromTrade(
    trade.trade,
    allowedSlippage
  );

  const [approvalStateMdex, approveCallbacMdex] =
    useApproveCallbackFromTradeMdex(mdexTrade.trade, allowedSlippage);

  const [approvalStateBi, approveCallbackBi] = useApproveCallbackFromTradeBi(
    biswapTrade.trade,
    allowedSlippage
  );

  const [approvalApe, approveCallbackApe] = useApproveCallbackFromTradeApe(
    apeswapTrade.trade,
    allowedSlippage,
    false,
    apeswapBestRoute.routerType,
    apeswapBestRoute.smartRouter
  );

  return {
    Pancake: swapCallback,
    Mdex: mdexSwapCallback,
    Biswap: biSwapCallback,
    Apeswap: apeswapCallback,
  };
};
