import { NetworkDetails } from "models/network";

export const switchOrAddNetwork = (
  networkDetails?: NetworkDetails,
  account?: string
) => {
  if (
    !window.ethereum ||
    !window.ethereum.request ||
    !window.ethereum.isMetaMask ||
    !networkDetails ||
    !account
  )
    return Promise.reject();
  return window.ethereum
    .request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId: networkDetails.chainId }],
    })
    .catch((error: { code: number }) => {
      if (error.code !== 4902) {
        console.error(
          "error switching to chain id",
          networkDetails.chainId,
          error
        );
      }
      if (!window.ethereum || !window.ethereum.request) return;
      window.ethereum
        .request({
          method: "wallet_addEthereumChain",
          params: [{ ...networkDetails }, account],
        })
        .catch((error: any) => {
          console.error(
            "error adding chain with id",
            networkDetails.chainId,
            error
          );
        });
    });
};
