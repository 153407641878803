import { useAppSelector } from "app/hooks";
import { gas } from "hooks";
import React, { useState } from "react";

interface AdvanceMoreProps {}

const AdvanceMore: React.FunctionComponent<AdvanceMoreProps> = (props) => {
  const [active, setActive] = useState<number>(2);

  const [value, setValue] = useState<string>("");

  const [activeGWEI, setActiveGWEI] = useState<number>(1);

  const userSelector = useAppSelector((state) => {
    return state.user;
  });

  return (
    <>
      <div className="d-flex-col gap-10">
        <div>
          <div className="d-flex-between">
            <div className="text-yoyo">Slippage Tolerance</div>
            <div className="text-yoyo">
              {userSelector.userSlippageTolerance / 100}%
            </div>
          </div>
        </div>
        <div className="d-flex-row items-between items-center">
          <div className="text-yoyo">Transaction deadline (mins)</div>
          <div className="text-yoyo">{userSelector.userDeadline / 60}</div>
        </div>
        <div className="d-flex-row items-between items-center">
          <div className="text-yoyo">Transaction Speed (GWEI)</div>
          <div className="text-yoyo">
            {gas.find((x) => x.value === userSelector.gasPrice)?.name}
          </div>
        </div>
      </div>
    </>
  );
};

export default AdvanceMore;
