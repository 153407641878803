import { Token } from "@ape.swap/sdk";
import { useActiveWeb3React } from "../hooks/useActiveWeb3React";
import { useMemo } from "react";
import { useCombinedActiveList } from "../state/lists/hooks";
import useUserAddedTokens from "../state/user/userHook";
import { TokenAddressMap } from "../state/lists/hooks";
import { WrappedTokenInfo } from "../state/lists/wrapped-token-info";

export const useAllTokens = () => {
  const allTokens = useCombinedActiveList();
  return useTokensFromMap(allTokens, true);
};

const useTokensFromMap = (
  tokenMap: TokenAddressMap,
  includeUserAdded: boolean
) => {
  const { chainId } = useActiveWeb3React();
  const userAddedTokens = useUserAddedTokens();

  return useMemo(() => {
    if (!chainId || !tokenMap[chainId]) return {};

    // reduce to just tokens
    const mapWithoutUrls = Object.keys(tokenMap[chainId]).reduce<{
      [address: string]: Token;
    }>((newMap, address) => {
      let token = tokenMap[chainId][address].token;

      // if (token.equals(WETH[chainId]))
      //   token = new WrappedTokenInfo(
      //     tokenMap[chainId][address].token.tokenInfo,
      //     tokenMap[chainId][address].token.list,
      //     WETH[chainId]
      //   );

      newMap[address] = token;
      return newMap;
    }, {});

    if (includeUserAdded) {
      return (
        userAddedTokens
          // reduce into all ALL_TOKENS filtered by the current chain
          .reduce<{ [address: string]: Token }>(
            (tokenMap, token) => {
              tokenMap[token.address] = token;
              return tokenMap;
            },
            // must make a copy because reduce modifies the map, and we do not
            // want to make a copy in every iteration
            { ...mapWithoutUrls }
          )
      );
    }

    return mapWithoutUrls;
  }, [chainId, userAddedTokens, tokenMap, includeUserAdded]);
};
