import { ChainId } from "../constants/chainIds";
import BNBLogo from "../assets/images/binance-chain-logo.svg";
import EtherLogo from "../assets/images/ethereum-logo.svg";

const trustWalletChainMapping: { [chainId in ChainId]?: string } = {
  [ChainId.MAINNET]: "ethereum",
  [ChainId.BNB]: "binance",
};
export const getTokenLogoURL = (address: string, chainId = ChainId.BNB) => {
  const chainKey = trustWalletChainMapping[chainId] || "binance";

  return `https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/${chainKey}/assets/${address}/logo.png`;
};

export const NATIVE_CURRENCY_LOGO: { [chainId in ChainId]: string } = {
  [ChainId.BNB]: BNBLogo,
  [ChainId.GOERLI]: EtherLogo,
  [ChainId.tBNB]: BNBLogo,
  [ChainId.MAINNET]: EtherLogo,
  [ChainId.ROPSTEN]: EtherLogo,
};
