import React from "react";
import MyAssets from "../../../components/MyAssets/index";
interface SwapPageWrapperProps {}

const SwapPageWrapper: React.FunctionComponent<SwapPageWrapperProps> = ({
  children,
}) => {
  return (
    <>
      {/* <MyAssets /> */}
      <div className="swap-page">{children}</div>
    </>
  );
};

export default SwapPageWrapper;
