import { useWeb3React } from "@web3-react/core";
import Triangle from "../../assets/images/triangle.svg";

import React from "react";
import { useActiveWeb3React } from "hooks/useActiveWeb3React";

import { Image } from "antd";
import { cutString } from "utils/commonUtil";
import { useNativeCurrencyBalance } from "hooks/useNativeCurrencyBalance";
import { Fraction } from "@ape.swap/sdk";
import { useNativeCurrency } from "hooks/useNativeCurrency";
import { useNetworkSwitcherToggle } from "hooks";

interface Web3StatusInnerProps {}

const Web3StatusInner: React.FunctionComponent<Web3StatusInnerProps> = (
  props
) => {
  const { account } = useWeb3React();
  const { chainId: networkConnectorChainId } = useActiveWeb3React();
  const userNativeCurrencyBalance = useNativeCurrencyBalance();
  const nativeCurrency = useNativeCurrency(networkConnectorChainId);
  const toggleNetworkSwitcher = useNetworkSwitcherToggle();

  const numberFormater = Intl.NumberFormat("en-US", {
    notation: "compact",
    compactDisplay: "short",
    minimumFractionDigits: 3,
  });
  return (
    <>
      <div className="header-wallet--self">
        {/* {account && nativeBalance && nativeBalance[account] && (
          <div className="header-wallet--self__balance">
            {nativeBalance[account]?.amount.toFixed(4)}
          </div>
        )} */}
        {userNativeCurrencyBalance && (
          <div className="header-wallet--self__balance">
            {`${
              userNativeCurrencyBalance &&
              userNativeCurrencyBalance.greaterThan(new Fraction("10000"))
                ? numberFormater.format(
                    parseInt(userNativeCurrencyBalance.toFixed(2))
                  )
                : userNativeCurrencyBalance &&
                  userNativeCurrencyBalance.toFixed(4)
            }`}{" "}
            {"BNB"}
          </div>
        )}
        <div className="header-wallet--self__address">
          {cutString({
            start: 6,
            end: 4,
            originalString: account || "2",
            replaceBy: "...",
          })}
        </div>
        <div
          className="header-wallet--self__network"
          onClick={toggleNetworkSwitcher}
        >
          <Image
            src={nativeCurrency[0].logoSrc}
            preview={false}
            width={20}
            height={20}
          />
          {/* <div>
            {nativeCurrency && nativeCurrency.length > 0
              ? nativeCurrency[0].name
              : "Not found network"}
          </div> */}
          <div>
            <Image src={Triangle} preview={false}></Image>
          </div>
        </div>
      </div>
    </>
  );
};

export default Web3StatusInner;
