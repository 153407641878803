import ArrowDown from "assets/icons/ArrowDown";
import { Currency } from "entities/currency";
import CurrencyLogo from "features/manageLists/components/CurrencyLogo";
import React, { useMemo } from "react";
import { Field } from "state/swap/types";
import { computeSlippageAdjustedAmounts } from "../../../utils/computeSlippageAdjustedAmounts";

interface ConfirmSwapModalHeaderProps {
  trade: any;
  inputAmount: string;
  outputAmount: string;
  inputSwapData?: Currency | null;
  outputSwapData?: Currency | null;
  allowedSlippage: number;
}

const ConfirmSwapModalHeader: React.FunctionComponent<
  ConfirmSwapModalHeaderProps
> = ({
  trade,
  inputAmount,
  outputAmount,
  inputSwapData,
  outputSwapData,
  allowedSlippage,
}) => {
  const slippageAdjustedAmounts = useMemo(
    () => computeSlippageAdjustedAmounts(trade.trade, allowedSlippage),
    [trade.trade, allowedSlippage]
  );

  return (
    <>
      <div className="modal--swap--confirm--header">
        <div className="modal--swap--confirm--header__input">
          <div className="modal--swap--confirm--header__input__currency">
            <CurrencyLogo
              currency={
                inputSwapData ? inputSwapData : trade.trade.inputAmount.currency
              }
            />
            <div className="modal--swap--confirm--header__input__currency__amount">
              {inputAmount}
            </div>
          </div>

          <div className="modal--swap--confirm--header__input__symbol">
            {trade.trade.inputAmount.currency.symbol}
          </div>
        </div>
        <ArrowDown />
        <div className="modal--swap--confirm--header__input">
          <div className="modal--swap--confirm--header__input__currency">
            <CurrencyLogo
              currency={
                outputSwapData
                  ? outputSwapData
                  : trade.trade.outputAmount.currency
              }
            />
            <div className="modal--swap--confirm--header__input__currency__amount">
              {outputAmount}
            </div>
          </div>

          <div className="modal--swap--confirm--header__input__symbol">
            {trade.trade.outputAmount.currency.symbol}
          </div>
        </div>

        <div className="modal--swap--confirm--header__slippage">
          <div>SLIPPAGE TOLERANCE</div>
          <div>{allowedSlippage / 100}%</div>
        </div>
        <div className="modal--swap--confirm--header__estimated">
          Output is estimated. You will receive at least{" "}
          <strong>
            {" "}
            {slippageAdjustedAmounts[Field.OUTPUT]?.toSignificant(6)}{" "}
            {trade.trade.outputAmount.currency.symbol}{" "}
          </strong>
          or the transaction will revert.
        </div>
      </div>
    </>
  );
};

export default ConfirmSwapModalHeader;
