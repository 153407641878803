import { Interface } from "@ethersproject/abi";
import { parseUnits } from "@ethersproject/units";
import { useWeb3React } from "@web3-react/core";
import pancakePairABI from "config/abis/pancakePair.json";
import { RawCurrency } from "entities/currency";
import {
  ChainId,
  Currency,
  CurrencyAmount,
  currencyEquals,
  JSBI,
  Pair,
  Percent,
  Token,
  TokenAmount,
  Trade,
  WETH,
} from "libs/pancake-swap";
import { mainnetTokens, testnetTokens } from "libs/pancake-swap/tokens";
import flatMap from "lodash/flatMap";
import { useMemo } from "react";
import { useMultipleContractSingleData } from "state/multicall/multiCallHook";
import { Field } from "state/swap/types";

enum PairState {
  LOADING,
  NOT_EXISTS,
  EXISTS,
  INVALID,
}

// a list of tokens by chain
type ChainTokenList = {
  readonly [chainId in ChainId]: Token[];
};

const MAX_HOPS = 3;
const ZERO_PERCENT = new Percent("0");
const ONE_HUNDRED_PERCENT = new Percent("1");
const PAIR_INTERFACE = new Interface(pancakePairABI);

// used to construct intermediary pairs for trading
const BASES_TO_CHECK_TRADES_AGAINST: ChainTokenList = {
  [ChainId.MAINNET]: [
    mainnetTokens.wbnb,
    mainnetTokens.cake,
    mainnetTokens.busd,
    mainnetTokens.usdt,
    mainnetTokens.btcb,
    mainnetTokens.ust,
    mainnetTokens.eth,
    mainnetTokens.usdc,
  ],
  [ChainId.TESTNET]: [
    testnetTokens.wbnb,
    testnetTokens.cake,
    testnetTokens.busd,
  ],
};

const ADDITIONAL_BASES: {
  [chainId in ChainId]?: { [tokenAddress: string]: Token[] };
} = {
  [ChainId.MAINNET]: {},
};

const CUSTOM_BASES: {
  [chainId in ChainId]?: { [tokenAddress: string]: Token[] };
} = {
  [ChainId.MAINNET]: {},
};

const BETTER_TRADE_LESS_HOPS_THRESHOLD = new Percent(
  JSBI.BigInt(50),
  JSBI.BigInt(10000)
);

// try to parse a user entered amount for a given token
const tryParseAmount = (
  value?: string,
  currency?: Currency
): CurrencyAmount | TokenAmount | undefined => {
  if (!value || !currency) {
    return undefined;
  }
  try {
    const typedValueParsed = parseUnits(value, currency.decimals).toString();

    if (typedValueParsed !== "0") {
      return currency instanceof Token
        ? new TokenAmount(currency, JSBI.BigInt(typedValueParsed))
        : CurrencyAmount.ether(JSBI.BigInt(typedValueParsed));
    }
  } catch (error) {
    // should fail if the user specifies too many decimal places of precision (or maybe exceed max uint?)
  }
  // necessary for all paths to return a value
  return undefined;
};

// returns whether tradeB is better than tradeA by at least a threshold percentage amount
function isTradeBetter(
  tradeA: Trade | undefined | null,
  tradeB: Trade | undefined | null,
  minimumDelta: Percent = ZERO_PERCENT
): boolean | undefined {
  if (tradeA && !tradeB) return false;
  if (tradeB && !tradeA) return true;
  if (!tradeA || !tradeB) return undefined;

  if (
    tradeA.tradeType !== tradeB.tradeType ||
    !currencyEquals(tradeA.inputAmount.currency, tradeB.inputAmount.currency) ||
    !currencyEquals(tradeA.outputAmount.currency, tradeB.outputAmount.currency)
  ) {
    throw new Error("Trades are not comparable");
  }

  if (minimumDelta.equalTo(ZERO_PERCENT)) {
    return tradeA.executionPrice.lessThan(tradeB.executionPrice);
  }
  return tradeA.executionPrice.raw
    .multiply(minimumDelta.add(ONE_HUNDRED_PERCENT))
    .lessThan(tradeB.executionPrice);
}

const CurrencyBT = {
  
}

function wrappedCurrency(
  currency: Currency | undefined,
  chainId: ChainId | undefined
): Token | undefined {

  return chainId && currency?.symbol === "BNB"
    ? WETH[chainId]
    : currency instanceof Token
    ? currency
    : undefined;
}

function usePairs(
  currencies: [Currency | undefined, Currency | undefined][]
): [PairState, Pair | null][] {
  const { chainId } = useWeb3React();

  const tokens = useMemo(
    () =>
      currencies.map(([currencyA, currencyB]) => [
        wrappedCurrency(currencyA, chainId),
        wrappedCurrency(currencyB, chainId),
      ]),
    [chainId, currencies]
  );

  const pairAddresses = useMemo(
    () =>
      tokens.map(([tokenA, tokenB]) => {
        try {
          return tokenA && tokenB && !tokenA.equals(tokenB)
            ? Pair.getAddress(tokenA, tokenB)
            : undefined;
        } catch (error: any) {
          // Debug Invariant failed related to this line
          return undefined;
        }
      }),
    [tokens]
  );

  const results = useMultipleContractSingleData(
    pairAddresses,
    PAIR_INTERFACE,
    "getReserves"
  );
  
  return useMemo(() => {
    return results.map((result, i) => {
      const { result: reserves, loading } = result;
      const tokenA = tokens[i][0];
      const tokenB = tokens[i][1];

      if (loading) return [PairState.LOADING, null];
      if (!tokenA || !tokenB || tokenA.equals(tokenB))
        return [PairState.INVALID, null];
      if (!reserves) return [PairState.NOT_EXISTS, null];
      const { reserve0, reserve1 } = reserves;
      const [token0, token1] = tokenA.sortsBefore(tokenB)
        ? [tokenA, tokenB]
        : [tokenB, tokenA];
      return [
        PairState.EXISTS,
        new Pair(
          new TokenAmount(token0, reserve0.toString()),
          new TokenAmount(token1, reserve1.toString())
        ),
      ];
    });
  }, [results, tokens]);
}

function useAllCommonPairs(currencyA?: Currency, currencyB?: Currency, name?: string): Pair[] {
  const { chainId = 56 } = useWeb3React();
  
  const [tokenA, tokenB] = chainId
    ? [wrappedCurrency(currencyA, chainId), wrappedCurrency(currencyB, chainId)]
    : [undefined, undefined];
    
  const bases: Token[] = useMemo(() => {
    if (!chainId) return [];

    const pancakeChainId = chainId === 56 ? ChainId.MAINNET : ChainId.TESTNET;
    const common = BASES_TO_CHECK_TRADES_AGAINST[pancakeChainId] ?? [];
    const additionalA = tokenA
      ? ADDITIONAL_BASES[pancakeChainId]?.[tokenA.address] ?? []
      : [];
    const additionalB = tokenB
      ? ADDITIONAL_BASES[pancakeChainId]?.[tokenB.address] ?? []
      : [];

    return [...common, ...additionalA, ...additionalB];
  }, [chainId, tokenA, tokenB]);

  const basePairs: [Token, Token][] = useMemo(
    () =>
      flatMap(bases, (base): [Token, Token][] =>
        bases.map((otherBase) => [base, otherBase])
      ),
    [bases]
  );

  const allPairCombinations: [Token, Token][] = useMemo(
    () =>
      tokenA && tokenB
        ? [
            // the direct pair
            [tokenA, tokenB],
            // token A against all bases
            ...bases.map((base): [Token, Token] => [tokenA, base]),
            // token B against all bases
            ...bases.map((base): [Token, Token] => [tokenB, base]),
            // each base against all bases
            ...basePairs,
          ]
            .filter((tokens): tokens is [Token, Token] =>
              Boolean(tokens[0] && tokens[1])
            )
            .filter(([t0, t1]) => t0.address !== t1.address)
            .filter(([tokenA_, tokenB_]) => {
              if (!chainId) return true;

              const pancakeChainId =
                chainId === 56 ? ChainId.MAINNET : ChainId.TESTNET;
              const customBases = CUSTOM_BASES[pancakeChainId];

              const customBasesA: Token[] | undefined =
                customBases?.[tokenA_.address];
              const customBasesB: Token[] | undefined =
                customBases?.[tokenB_.address];

              if (!customBasesA && !customBasesB) return true;

              if (
                customBasesA &&
                !customBasesA.find((base) => tokenB_.equals(base))
              )
                return false;
              if (
                customBasesB &&
                !customBasesB.find((base) => tokenA_.equals(base))
              )
                return false;

              return true;
            })
        : [],
    [tokenA, tokenB, bases, basePairs, chainId]
  );

  const allPairs = usePairs(allPairCombinations);

  // only pass along valid pairs, non-duplicated pairs
  return useMemo(
    () =>
      Object.values(
        allPairs
          // filter out invalid pairs
          .filter((result): result is [PairState.EXISTS, Pair] =>
            Boolean(result[0] === PairState.EXISTS && result[1])
          )
          // filter out duplicated pairs
          .reduce<{ [pairAddress: string]: Pair }>((memo: any, [, curr]) => {
            memo[curr.liquidityToken.address] =
              memo[curr.liquidityToken.address] ?? curr;
            return memo;
          }, {})
      ),
    [allPairs]
  );
}

function useTradeExactIn(
  currencyAmountIn?: CurrencyAmount,
  currencyOut?: Currency,
  name?: string
): Trade | null {
  const allowedPairs = useAllCommonPairs(
    currencyAmountIn?.currency,
    currencyOut,
    name
  );
  
  return useMemo(() => {
    if (currencyAmountIn && currencyOut && allowedPairs.length > 0) {
      // search through trades with varying hops, find best trade out of them
      let bestTradeSoFar: Trade | null = null;
      for (let i = 1; i <= MAX_HOPS; i++) {
        const currentTrade: Trade | null =
          Trade.bestTradeExactIn(allowedPairs, currencyAmountIn, currencyOut, {
            maxHops: i,
            maxNumResults: 1,
          })[0] ?? null;
        // if current trade is best yet, save it
        if (
          isTradeBetter(
            bestTradeSoFar,
            currentTrade,
            BETTER_TRADE_LESS_HOPS_THRESHOLD
          )
        ) {
          bestTradeSoFar = currentTrade;
        }
      }
      return bestTradeSoFar;
    }
    return null;
  }, [allowedPairs, currencyAmountIn, currencyOut]);
}

/**
 * Returns the best trade for the token in to the exact amount of token out
 */
function useTradeExactOut(
  currencyIn?: Currency,
  currencyAmountOut?: CurrencyAmount,
  name?: string
): Trade | null {
  const allowedPairs = useAllCommonPairs(
    currencyIn,
    currencyAmountOut?.currency,
    name
  );

  return useMemo(() => {
    if (currencyIn && currencyAmountOut && allowedPairs.length > 0) {
      // search through trades with varying hops, find best trade out of them
      let bestTradeSoFar: Trade | null = null;
      for (let i = 1; i <= MAX_HOPS; i++) {
        const currentTrade =
          Trade.bestTradeExactOut(allowedPairs, currencyIn, currencyAmountOut, {
            maxHops: i,
            maxNumResults: 1,
          })[0] ?? null;
        if (
          isTradeBetter(
            bestTradeSoFar,
            currentTrade,
            BETTER_TRADE_LESS_HOPS_THRESHOLD
          )
        ) {
          bestTradeSoFar = currentTrade;
        }
      }
      return bestTradeSoFar;
    }
    return null;
  }, [currencyIn, currencyAmountOut, allowedPairs]);
}

export function usePancakeDerivedSwapInfo(
  independentField: Field,
  inputCurrency: RawCurrency | undefined,
  outputCurrency: RawCurrency | undefined,
  typedValue: string
): {
  trade: Trade | undefined;
  parsedAmount: CurrencyAmount | TokenAmount | undefined;
} {
  const wrappedInputCurrency = inputCurrency
    ? inputCurrency.address
      ? Token.wrapRawCurrencyToToken(inputCurrency)
      : Currency.wrapRawCurrency(inputCurrency)
    : undefined;

  const wrappedOutputCurrency = outputCurrency
    ? outputCurrency.address
      ? Token.wrapRawCurrencyToToken(outputCurrency)
      : Currency.wrapRawCurrency(outputCurrency)
    : undefined;

  const isExactIn: boolean = independentField === Field.INPUT;

  const parsedAmount = tryParseAmount(
    typedValue,
    (isExactIn ? wrappedInputCurrency : wrappedOutputCurrency) ?? undefined
  );

  const bestTradeExactIn = useTradeExactIn(
    isExactIn ? parsedAmount : undefined,
    wrappedOutputCurrency ?? undefined,
    "useTradeExactIn"
  );
  
  const bestTradeExactOut = useTradeExactOut(
    wrappedInputCurrency ?? undefined,
    !isExactIn ? parsedAmount : undefined,
    "useTradeExactOut"
  );

  const v2Trade = isExactIn ? bestTradeExactIn : bestTradeExactOut;

  return {
    trade: v2Trade ?? undefined,
    parsedAmount,
  };
}
