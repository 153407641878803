import React, { useMemo } from "react";

interface TokenInfoProps {
  name: string;
  field: string;
  token: any;
}

const TokenInfo: React.FunctionComponent<TokenInfoProps> = ({
  name,
  field,
  token,
}) => {
  const row = useMemo(() => [{}], []);
  return (
    <>
      <div className="s-tab--content">
        <div>{name}</div>
        <div>
          {token && token.length !== 0
            ? field === "market_cap_rank"
              ? "#" + token[0][field]
              : token[0][field]
            : " - - -"}
          {/* {field === "market_cap_rank"
            ? "#" + token[0][field]
            : token[0][field]} */}
        </div>
      </div>
    </>
  );
};

export default TokenInfo;
