import React from "react";

interface LoadingSpinnerProps {}

const LoadingSpinner: React.FunctionComponent<LoadingSpinnerProps> = (
  props
) => {
  return (
    <>
      <div className="lds-ellipsis">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </>
  );
};

export default LoadingSpinner;
