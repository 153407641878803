import SearchField from "components/Input/SearchField";
import { ERC20Token } from "entities/erc20token";
import { useToken } from "hooks/Token";
import { useActiveWeb3React } from "hooks/useActiveWeb3React";
import { CurrencyModalView } from "models";
import React, {
  ChangeEvent,
  useCallback,
  useMemo,
  useRef,
  useState,
} from "react";
import { isAddress } from "utils/addressHelpers";
import CardToken from "../components/CardToken";
import useUserAddedTokens, {
  useRemoveUserAddedToken,
} from "../../../state/user/userHook";
import CurrencyLogo from "../components/CurrencyLogo";
import { Trash } from "react-feather";

interface ManageTokensProps {
  setModalView: (view: CurrencyModalView) => void;
  setImportToken: (token: ERC20Token) => void;
}

const ManageTokens: React.FunctionComponent<ManageTokensProps> = ({
  setModalView,
  setImportToken,
}) => {
  const { chainId } = useActiveWeb3React();

  const [searchQuery, setSearchQuery] = useState<string>("");
  const inputRef = useRef<HTMLInputElement>();
  const handleInput = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const input = event.target.value;
    const checksummedInput = isAddress(input);
    setSearchQuery(checksummedInput || input);
  }, []);

  // search following address
  const isAddressSearch = isAddress(searchQuery);
  const searchToken = useToken(searchQuery);


  // all tokens for local list
  const userAddedTokens = useUserAddedTokens();

  const removeToken = useRemoveUserAddedToken();
  const handleRemoveAll = useCallback(() => {
    if (chainId && userAddedTokens) {
      userAddedTokens.map((token) => {
        return removeToken(chainId, token.address);
      });
    }
  }, [removeToken, userAddedTokens, chainId]);

  const tokenList = useMemo(() => {
    return userAddedTokens.map((token) => {
      return (
        <>
          <div className="manage--single--token__items__item">
            <div className="manage--single--token__items__item__info">
              <CurrencyLogo currency={token} />
              <div className="manage--single--token__items__item__info__network">
                <div>{token.symbol}</div>
                <div>{token.name}</div>
              </div>
            </div>
            <div className="manage--single--token__items__item__remove">
              <Trash
                onClick={() => {
                  removeToken(chainId, token.address);
                }}
                color="white"
                cursor="pointer"
              />
            </div>
          </div>
        </>
      );
    });
  }, [userAddedTokens, chainId]);

  return (
    <>
      <div className="manage--single--token">
        <SearchField
          name="token"
          placeholder="0x0000"
          value={searchQuery}
          onChange={handleInput}
        />
        {searchQuery !== "" && !isAddressSearch && (
          <div className="manage--single--token__message">
            Enter valid token address
          </div>
        )}
        {searchToken && (
          <CardToken
            token={searchToken}
            showImportView={() => setModalView(CurrencyModalView.IMPORT_TOKEN)}
            setImportToken={setImportToken}
          />
        )}
        <div className="manage--single--token__count">
          <div>{userAddedTokens?.length} tokens imported</div>
          {userAddedTokens.length > 0 && (
            <div className="manage--single--token__clear">
              <button className="btn btn--clear" onClick={handleRemoveAll}>
                Clear all
              </button>
            </div>
          )}
        </div>
        <div className="manage--single--token__items">{tokenList}</div>
      </div>
    </>
  );
};

export default ManageTokens;
