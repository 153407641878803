import { TokenList } from "@uniswap/token-lists";
import { useState } from "react";
import { CurrencyModalView } from "../models/swap/CurrencySearchModal";
import { ERC20Token } from "../entities/erc20token";
import { useListRowContextSwap } from "features/manageLists/hooks/useListRowContextSwap";
import {
  useCurrencySearchContextSwap,
  useManageListsContextSwap,
} from "components/CurrenySearchModal/hooks/useCurrencySearchContextSwap";

export interface CurrencySearchModalContextType {
  listURL: string | undefined;
  modalView: CurrencyModalView;
  setListUrl: (listUrl: string | undefined) => void;
  importList: TokenList | undefined;
  importToken: ERC20Token | undefined;
  setModalView: (val: CurrencyModalView) => void;
  setImportList: (importList: TokenList | undefined) => void;
  setImportToken: (importToken: ERC20Token | undefined) => void;
}

export const useCurrencySearchModalContext =
  (): CurrencySearchModalContextType => {
    const [importList, setImportList] = useState<TokenList | undefined>();
    const [importToken, setImportToken] = useState<ERC20Token | undefined>();
    const [listURL, setListUrl] = useState<string | undefined>();

    const [modalView, setModalView] = useState<CurrencyModalView>(
      CurrencyModalView.SEARCH
    );

    return {
      listURL,
      setListUrl,
      modalView,
      setModalView,
      importList,
      setImportList,
      importToken,
      setImportToken,
    };
  };

export const useCurrencySearchModalSwap = () => {
  const currencySearchModalContext = useCurrencySearchModalContext();
  const { setImportList, setListUrl, setModalView } =
    currencySearchModalContext;
  const listRowContext = useListRowContextSwap();
  const currencySearchContext = useCurrencySearchContextSwap();
  const manageListsContext = useManageListsContextSwap({
    setImportList,
    setListUrl,
    setModalView,
  });

  return {
    listRowContext,
    manageListsContext,
    currencySearchContext,
    currencySearchModalContext,
  };
};
