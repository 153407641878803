import { Button, Result } from 'antd'
import React from 'react'

interface NotFoundPageProps {}

export const NotFoundPage: React.FunctionComponent<NotFoundPageProps> = () => {
  return (
    <Result
      status="404"
      title="404"
      subTitle="Not Found!!!"
      extra={<Button type="primary">Back</Button>}
    />
  )
}
