import React, { Dispatch } from "react";
import ChervonDown from "assets/icons/ChervonDown";

interface ManageTokenHeaderProps {
  showSearchView: () => void;
}

const ManageTokenHeader: React.FunctionComponent<ManageTokenHeaderProps> = ({
  showSearchView,
}) => {
  return (
    <>
      <div className="manage--tokens--header">
        <ChervonDown onClick={showSearchView} />
        <div className="manage--tokens--header__title">Manage</div>
      </div>
    </>
  );
};

export default ManageTokenHeader;
