import { useAppSelector } from "app/hooks";
import SwapIcon from "assets/icons/SwapIcon";
import { selectSwapRoutes } from "features/swap/swapSlice";
import React, { useMemo } from "react";
import { Field } from "state/swap/types";
import { computeSlippageAdjustedAmounts } from "utils/computeSlippageAdjustedAmounts";

interface ConfirmSwapModalBottomProps {
  trade: any;
  allowedSlippage: number;
  formattedPrice: string;
  switchPrice: () => void;
}

const ConfirmSwapModalBottom: React.FunctionComponent<
  ConfirmSwapModalBottomProps
> = ({ formattedPrice, switchPrice, trade, allowedSlippage }) => {
  const swapRoutes = useAppSelector(selectSwapRoutes);

  const slippageAdjustedAmounts = useMemo(
    () => computeSlippageAdjustedAmounts(trade.trade, allowedSlippage),
    [trade.trade, allowedSlippage]
  );

  return (
    <>
      <div className="modal--swap--confirm--bottom">
        <div className="modal--swap--confirm--bottom__price">
          <div className="modal--swap--confirm--bottom__price__title">
            Price
          </div>
          <div className="modal--swap--confirm--bottom__price__amount">
            {formattedPrice}
            <button className="btn" onClick={switchPrice}>
              <SwapIcon />
            </button>
          </div>
        </div>
        <div className="modal--swap--confirm--bottom__minimum">
          <div className="modal--swap--confirm--bottom__minimum__title">
            Minimum received
          </div>
          <div>
            {" "}
            {slippageAdjustedAmounts[Field.OUTPUT]?.toSignificant(4)}{" "}
            {trade.trade.outputAmount.currency.symbol}{" "}
          </div>
        </div>
        <div className="modal--swap--confirm--bottom__priceImpact">
          <div className="modal--swap--confirm--bottom__priceImpact__title">
            Price Impact
          </div>
          <div>
            {" "}
            {swapRoutes && swapRoutes.length > 0
              ? swapRoutes[0].priceImpact
              : "- - -"}
          </div>
        </div>
        <div className="modal--swap--confirm--bottom__fee">
          <div className="modal--swap--confirm--bottom__fee__title">
            Liquidity Provider Fee
          </div>
          <div>
            {" "}
            {swapRoutes && swapRoutes.length > 0
              ? swapRoutes[0].providerFee
              : "- - -"}
          </div>
        </div>
      </div>
    </>
  );
};

export default ConfirmSwapModalBottom;
