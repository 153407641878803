import { Trade } from "../entities";
import { useWeb3React } from "@web3-react/core";
import { parseUnits } from "@ethersproject/units";
import { useSwapCallArguments } from "./useSwapCallArguments";
import { useMemo } from "react";
import { BigNumber } from "@ethersproject/bignumber";
import { useTransactionAdder } from "state/transactions/hooks";
import { basisPointsToPercent } from "../utils/prices";
import { TradeType } from "@pancakeswap/sdk";
import { isAddress } from "utils/addressHelpers";
import truncateHash from "utils/truncateHash";
import { useGasPrice } from "state/user/userHook";
export enum SwapCallbackState {
  INVALID,
  LOADING,
  VALID,
}

// export function useGasPrice() {
//   return parseUnits("20", "gwei").toString();
// }

export function isZero(hexNumberString: string) {
  return /^0x0*$/.test(hexNumberString);
}

export function calculateGasMargin(value: BigNumber): BigNumber {
  return value
    .mul(BigNumber.from(10000).add(BigNumber.from(1000)))
    .div(BigNumber.from(10000));
}

export function useSwapCallback(
  trade: Trade | undefined,
  allowedSlippage: number = 50,
  recipientAddressOrName: string | null
) {
  const { account, chainId, library } = useWeb3React();
  const gasPrice = useGasPrice();
  const swapCalls = useSwapCallArguments(
    trade,
    allowedSlippage,
    recipientAddressOrName
  );
  const recipient = account;

  const addTransaction = useTransactionAdder();

  return useMemo(() => {
    if (!trade || !library || !account || !chainId) {
      return {
        state: SwapCallbackState.INVALID,
        callback: null,
        error: "Missing dependencies",
      };
    }

    if (!recipient) {
      return {
        state: SwapCallbackState.INVALID,
        callback: null,
        error: "Invalid recipient",
      };
    }

    return {
      state: SwapCallbackState.VALID,
      callback: async function onSwap() {
        const estimatedCalls = await Promise.all(
          swapCalls.map((call) => {
            const {
              parameters: { methodName, args, value },
              contract,
            } = call;

            const options = !value || isZero(value) ? {} : { value };

            return contract.estimateGas[methodName](...args, options)
              .then((gasEstimate) => {
                return {
                  call,
                  gasEstimate,
                };
              })
              .catch((gasError) => {
                console.error(
                  "Gas estimate failed, trying eth_call to extract error",
                  call
                );
                return contract.callStatic[methodName](...args, options)
                  .then((result) => {
                    console.error(
                      "Unexpected successful call after failed estimate gas",
                      call,
                      gasError,
                      result
                    );
                    return {
                      call,
                      error:
                        "Unexpected issue with estimating the gas. Please try again.",
                    };
                  })
                  .catch((callError) => {
                    console.error("Call threw error", call, callError);
                    return {
                      call,
                      error: swapErrorToUserReadableMessage(callError),
                    };
                  });
              });
          })
        );

        const successfulEstimation = estimatedCalls.find(
          (el, ix, list) =>
            "gasEstimate" in el &&
            (ix === list.length - 1 || "gasEstimate" in list[ix + 1])
        );

        if (!successfulEstimation) {
          throw new Error("Could not estimate gas for the swap");
        }

        const {
          call: {
            contract,
            parameters: { methodName, args, value },
          },
          gasEstimate,
        } = successfulEstimation as any;

        return contract[methodName](...args, {
          gasLimit: calculateGasMargin(gasEstimate),
          gasPrice,
          ...(value && !isZero(value)
            ? { value, from: account }
            : { from: account }),
        })
          .then((response: any) => {
            const inputSymbol = trade.inputAmount.currency.symbol;
            const outputSymbol = trade.outputAmount.currency.symbol;
            const pct = basisPointsToPercent(allowedSlippage);
            const inputAmount =
              trade.tradeType === TradeType.EXACT_INPUT
                ? trade.inputAmount.toSignificant(3)
                : trade.maximumAmountIn(pct).toSignificant(3);

            const outputAmount =
              trade.tradeType === TradeType.EXACT_OUTPUT
                ? trade.outputAmount.toSignificant(3)
                : trade.minimumAmountOut(pct).toSignificant(3);

            const base = `Swap ${
              trade.tradeType === TradeType.EXACT_OUTPUT ? "max." : ""
            } ${inputAmount} ${inputSymbol} for ${
              trade.tradeType === TradeType.EXACT_INPUT ? "min." : ""
            } ${outputAmount} ${outputSymbol}`;

            const recipientAddressText =
              recipientAddressOrName && isAddress(recipientAddressOrName)
                ? truncateHash(recipientAddressOrName)
                : recipientAddressOrName;

            const withRecipient =
              recipient === account
                ? base
                : `${base} to ${recipientAddressText}`;

            const translatableWithRecipient =
              trade.tradeType === TradeType.EXACT_OUTPUT
                ? recipient === account
                  ? "Swap max. %inputAmount% %inputSymbol% for %outputAmount% %outputSymbol%"
                  : "Swap max. %inputAmount% %inputSymbol% for %outputAmount% %outputSymbol% to %recipientAddress%"
                : recipient === account
                ? "Swap %inputAmount% %inputSymbol% for min. %outputAmount% %outputSymbol%"
                : "Swap %inputAmount% %inputSymbol% for min. %outputAmount% %outputSymbol% to %recipientAddress%";
            addTransaction(response, {
              summary: withRecipient,
            });
            return response.hash;
          })
          .catch((error: any) => {
            if (error?.code === 4001) throw new Error("Transaction rejected");
            console.error("Swap failed", error, methodName, args, value);
            throw new Error("Swap failed " + error.message);
          });
      },
      error: null,
    };
  }, [trade, library, account, chainId, recipient, swapCalls, gasPrice]);
}

/**
 * This is hacking out the revert reason from the ethers provider thrown error however it can.
 * This object seems to be undocumented by ethers.
 * @param error an error from the ethers provider
 */
function swapErrorToUserReadableMessage(error: any) {
  let reason: string | undefined;
  while (error) {
    reason = error.reason ?? error.data?.message ?? error.message ?? reason;
    // eslint-disable-next-line no-param-reassign
    error = error.error ?? error.data?.originalError;
  }

  if (reason?.indexOf("execution reverted: ") === 0)
    reason = reason.substring("execution reverted: ".length);
  return reason;
}
