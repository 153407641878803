import { Col, Row } from "antd";
import React, { useState } from "react";
import { Swap } from "../components";
import SwapPageWrapper from "./SwapPageWrapper";
import TableExchange from "../components/TableExchange";
import  {All__Platform__Trades}  from "../../../constants/index";

interface SwapPageProps {}

export const SwapPage: React.FunctionComponent<SwapPageProps> = () => {
  const [exchangeSelected, setExchangeSelected] = useState<number>(1);

  const [isEnter, setIsEnter] = useState<boolean>(false);
  const [platformOverride, setPlatformOverride] = useState<string>(All__Platform__Trades[0])

  return (
    <SwapPageWrapper>
      <Row gutter={[16, 0]}>
        <Col xxl={24} md={24}>
          <Row gutter={[16, { xs: 8, sm: 16, md: 16, lg: 16 }]}>
            <Col lg={24} md={24} sm={24} xs={24}>
              <Row gutter={[16, { xs: 8, sm: 16, md: 16, lg: 16 }]}>
                {isEnter && (
                  <Col lg={16} md={16} sm={24} xs={24}>
                    <TableExchange
                      platformOverride={platformOverride}
                      setPlatformOverride={setPlatformOverride}
                    />
                  </Col>
                )}
                <Col lg={8} md={8} sm={24} xs={24} offset={isEnter ? 0 : 8}>
                  <Swap
                    setIsEnter={setIsEnter}
                    platformOverride={platformOverride}
                    setPlatformOverride={setPlatformOverride}
                  />
                </Col>
              </Row>
            </Col>
            {/* <Col lg={24} md={24} sm={24} xs={24}>
              <History />
            </Col> */}
          </Row>
        </Col>
      </Row>
    </SwapPageWrapper>
  );
};
