// import { Token } from "@ape.swap/sdk";
import { ERC20Token } from "entities/erc20token";
import React, {
  ChangeEvent,
  Dispatch,
  KeyboardEvent,
  MutableRefObject,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from "react";
import SearchField from "../../../components/Input/SearchField";
import { CurrencySearchContext } from "../context/CurrencySearchContext";
import { filterTokens } from "../../../utils/filtering";
import { useTokenComparator } from "../hooks/sorting";
import { useSortedTokensByQuery } from "../hooks/filtering";
import { Currency } from "entities/currency";
import { useNativeCurrency } from "hooks/useNativeCurrency";
import { useActiveWeb3React } from "hooks/useActiveWeb3React";
import CurrencyList from "../../CurrencyList/CurrencyList";
import { ComponentType } from "react";
import { FixedSizeList } from "react-window";
import { useSearchInactiveTokenLists } from "hooks/Token";
import { isAddress } from "utils/addressHelpers";
import { Token as MyToken } from "../../../models/manageToken/index";

interface CurrencySearchPageProps {
  showManageView: () => void;
  isOutputPanel?: boolean;
  onSelectToken: (token: MyToken) => void;
}

const CurrencySearchPage: React.FunctionComponent<CurrencySearchPageProps> = ({
  isOutputPanel,
  showManageView,
  onSelectToken,
}) => {
  const {
    allTokens,
    allTokensOnSecondChain,
    searchToken,
    searchQuery,
    setSearchQuery,
    debouncedQuery,
    selectedTokenList,
    showFallbackTokens,
  } = useContext(CurrencySearchContext);

  const tokens = isOutputPanel ? allTokensOnSecondChain : allTokens;

  const [invertSearchOrder] = useState<boolean>(false);

  const tokenComparator = useTokenComparator(invertSearchOrder);

  const filteredTokens: ERC20Token[] = useMemo(() => {
    return filterTokens(Object.values(tokens ?? {}), debouncedQuery);
  }, [tokens, debouncedQuery]);

  const sortedTokens: ERC20Token[] = useMemo(() => {
    return filteredTokens.sort(tokenComparator);
  }, [filteredTokens, tokenComparator]);

  const filteredSortedTokens = useSortedTokensByQuery(
    sortedTokens,
    debouncedQuery
  );

  const { chainId: networkConnectorChainId } = useActiveWeb3React();

  const fixedList = useRef<FixedSizeList>();

  const nativeCurrency = useNativeCurrency(networkConnectorChainId);

  const isAddressSearch = isAddress(debouncedQuery);

  // if no results on main list, show option to expand into inactive
  const filteredInactiveTokens = useSearchInactiveTokenLists(
    filteredTokens.length === 0 ||
      (debouncedQuery.length > 2 && !isAddressSearch)
      ? debouncedQuery
      : undefined
  );

  const filteredInactiveTokensWithFallback = useMemo(() => {
    if (filteredTokens.length > 0) return [];
    // if (showFallbackTokens && filteredInactiveTokens.length > 0)
    //   return filteredInactiveTokens;
    if (searchToken) return [searchToken];
    return [];
  }, [
    filteredInactiveTokens,
    filteredTokens.length,
    searchToken,
    showFallbackTokens,
  ]);

  const filteredSortedTokensWithNativeCurrency: Currency[] | any =
    useMemo(() => {
      if (nativeCurrency && !nativeCurrency[0].name)
        return filteredSortedTokens;

      if (
        nativeCurrency &&
        new RegExp(debouncedQuery.replace(/\s/g, ""), "gi").test(
          `${nativeCurrency[0].symbol} ${nativeCurrency[0].name}`
        )
      ) {
        const tokensWithoutNativeCurrency = filteredSortedTokens.filter(
          (token) => token.name !== nativeCurrency[0].name
        );
        return [nativeCurrency[0], ...tokensWithoutNativeCurrency];
      }
      return filteredSortedTokens;
    }, [nativeCurrency, filteredSortedTokens, debouncedQuery]); // concat 2 native currency and tokens list

  const onChangeSearchField = (event: ChangeEvent<HTMLInputElement>) => {
    const input = event.target.value;
    const checkSummedInput = isAddress(input);
    setSearchQuery(checkSummedInput || input);
    fixedList.current?.scrollTo(0);
  };

  const onKeyDownSearchField = useCallback(
    (event: KeyboardEvent<HTMLInputElement>) => {
      if (event.key === "Enter") {
      }
    },
    [debouncedQuery, filteredSortedTokensWithNativeCurrency]
  );

  return (
    <>
      <SearchField
        name="Search"
        placeholder="Search name of paste address"
        onChange={onChangeSearchField}
        // onChange={() => {}}
      />

      <div className="currencies">
        <div style={{ flex: "1" }}>
          {(filteredSortedTokensWithNativeCurrency?.length > 0 ||
            filteredInactiveTokensWithFallback.length > 0) &&
          fixedList !== undefined ? (
            <CurrencyList
              onSelectToken={onSelectToken}
              currencies={filteredSortedTokensWithNativeCurrency}
              fixedListRef={fixedList as MutableRefObject<FixedSizeList>}
              otherListTokens={filteredInactiveTokensWithFallback}
            />
          ) : (
            <div className="text-center text-white">No results found</div>
          )}
        </div>
      </div>

      <div className="manage--btn" onClick={showManageView}>
        Manage tokens list
      </div>
    </>
  );
};

export default CurrencySearchPage;
