import React from "react";

interface TabProps {
  label: string;
  currentTab: number;
  indexTab: number;
  onClickTab: (i: number) => void;
}

const Tab: React.FunctionComponent<TabProps> = ({
  label,
  currentTab,
  indexTab,
  onClickTab,
}) => {
  return (
    <>
      <div
        className={`manage--tokens--tabs__tab ${
          currentTab === indexTab && "active"
        }`}
        onClick={onClickTab.bind(null, indexTab)}
      >
        {label}
      </div>
    </>
  );
};

export default Tab;
