import React, { useCallback, useMemo } from "react";
import { toast } from "react-toastify";
interface ParamsProps {
  title?: string;
  message?: string;
  onOk?: () => void;
  onCancel?: () => void;
  kind?: "error" | "success" | "promise";
}

const useNotification = () => {
  return useCallback(({ title, message, kind }: ParamsProps) => {
    if (kind === "error") {
      toast.error(message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
    if (kind === "success") {
      toast.success(message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  }, []);
};

export default useNotification;
