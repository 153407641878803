import { Image } from "antd";
import React from "react";
import serviceIcon1 from "assets/images/service/service-icon-1.svg";
import serviceIcon2 from "assets/images/service/service-icon-2.svg";
import serviceIcon3 from "assets/images/service/service-icon-3.svg";
import serviceIcon4 from "assets/images/service/service-icon-4.svg";

interface ServiceProps {}

const Service: React.FunctionComponent<ServiceProps> = (props) => {
  return (
    <>
      <section className="services">
        <div className="service">
          <Image preview={false} src={serviceIcon1} />
          <div className="service--title">Instant Finance</div>
        </div>
        <div className="service">
          <Image preview={false} src={serviceIcon2} />
          <div className="service--title">Maximize Savings</div>
        </div>
        <div className="service">
          <Image preview={false} src={serviceIcon3} />
          <div className="service--title">Market Integration</div>
        </div>
        <div className="service">
          <Image preview={false} src={serviceIcon4} />
          <div className="service--title">Optimal Proposition</div>
        </div>
      </section>
    </>
  );
};

export default Service;
