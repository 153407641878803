import { InjectedConnector } from "@web3-react/injected-connector";
import { useCallback } from "react";
import { NavigateFunction, useLocation, useNavigate } from "react-router-dom";

import { NETWORK_DETAIL } from "../constants/networkSwitch";
import { switchOrAddNetwork } from "utils/switchOrAddNetwork";
import { useUnsupportedChainIdError } from "./useUnsupportedChainIdError";
import { ChainId } from "constants/chainIds";
import { CustomNetworkConnector } from "connectors/CustomNetworkConnector";
import { useActiveWeb3React } from "./useActiveWeb3React";

export type UseNetworkSwitchProps = {
  onSelectNetworkCallback?: () => void;
};

export const useNetworkSwitch = ({
  onSelectNetworkCallback,
}: UseNetworkSwitchProps = {}) => {
  const { connector, chainId, account } = useActiveWeb3React();
  const unsupportedChainIdError = useUnsupportedChainIdError();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const selectNetwork = useCallback(
    async (optionChainId?: ChainId) => {
      if (
        optionChainId === undefined ||
        (optionChainId === chainId && !unsupportedChainIdError)
      )
        return;

      let changeChainIdResult: unknown;
      if (
        !account &&
        !unsupportedChainIdError &&
        connector instanceof CustomNetworkConnector
      ) {
        connector.changeChainId(optionChainId);
      } else if (
        !account &&
        unsupportedChainIdError &&
        connector instanceof CustomNetworkConnector
      )
        changeChainIdResult = await connector.switchUnsupportedNetwork(
          NETWORK_DETAIL[optionChainId]
        );
      else if (connector instanceof InjectedConnector)
        changeChainIdResult = await switchOrAddNetwork(
          NETWORK_DETAIL[optionChainId],
          account || undefined
        );
      if (onSelectNetworkCallback) onSelectNetworkCallback();
    },
    [
      account,
      chainId,
      connector,
      navigate,
      onSelectNetworkCallback,
      pathname,
      unsupportedChainIdError,
    ]
  );

  return {
    selectNetwork,
  };
};
