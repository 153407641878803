import { createContext } from "react";
import { CurrencySearchModalContextType } from "../../../models/currencySearchModal/CurrencySearchModalContextType";
import { CurrencyModalView } from "../../../models/manageList/ManageListsProps";

export const CurrencySearchModalContext =
  createContext<CurrencySearchModalContextType>({
    listURL: undefined,
    modalView: CurrencyModalView.SEARCH,
    setListUrl: () => null,
    importList: undefined,
    importToken: undefined,
    setModalView: () => null,
    setImportList: () => null,
    setImportToken: () => null,
  });
