import React, { FC, useState } from "react";
import { Modal, Row, Col, Image } from "antd";
import SUPPORTED_WALLETS, { WalletInfo } from "../../constants";
import {
  useModalOpen,
  useUnsupportedChainIdError,
  useWalletModalToggle,
} from "hooks";
import { ApplicationModal } from "models/application";
import { AbstractConnector } from "@web3-react/abstract-connector";
import { WalletConnectConnector } from "@web3-react/walletconnect-connector";
import { UnsupportedChainIdError, useWeb3React } from "@web3-react/core";
import useNotification from "hooks/useNotification";

export enum WALLET_VIEWS {
  Pending,
  Account,
}
interface WalletModalProps {}

const WalletModal: React.FunctionComponent<WalletModalProps> = (props) => {
  const walletModalOpen = useModalOpen(ApplicationModal.WALLET_SWITCHER);
  const toggleWalletSwitcher = useWalletModalToggle();

  const handleOk = () => {
    toggleWalletSwitcher();
  };

  const handleCancel = () => {
    toggleWalletSwitcher();
  };

  const getExtensionWallet = () => {
    return Object.keys(SUPPORTED_WALLETS).map((item, i) => {
      const option = SUPPORTED_WALLETS[item];
      return <WalletItem key={`wallet-item-${i}`} item={option} />;
    });
  };

  return (
    <>
      <Modal
        className="modal--connect--wallet"
        open={walletModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        destroyOnClose={true}
        closable={true}
        title={"Connect Wallet"}
      >
        {getExtensionWallet()}
      </Modal>
    </>
  );
};

interface WalletItemProps {
  item: WalletInfo;
}

const WalletItem: React.FunctionComponent<WalletItemProps> = ({ item }) => {
  const [pendingWallet, setPendingWallet] = useState<
    AbstractConnector | undefined
  >();

  const { active, activate, account, error } = useWeb3React();
  const toggleWalletSwitcher = useWalletModalToggle();

  const notification = useNotification();
  const [walletView, setWalletView] = useState<WALLET_VIEWS | null>();

  const tryActivation = async (connector: AbstractConnector | undefined) => {
    setPendingWallet(connector);
    setWalletView(WALLET_VIEWS.Pending);

    // if the connector is walletconnect and the user has already tried to connect, manually reset the connector
    if (
      connector instanceof WalletConnectConnector &&
      connector.walletConnectProvider?.connector?.uri
    ) {
      connector.walletConnectProvider = undefined;
    }

    connector &&
      activate(connector, undefined, true).catch((error) => {
        if (error instanceof UnsupportedChainIdError) {
          activate(connector);
          notification({
            kind: "error",
            message: "Unsupported network. Please connect metamask with BSC",
          });
        } else {
          notification({ kind: "error", message: error.message });
        }
      });
  };

  return (
    <>
      <div
        className="modal--wallet--item"
        onClick={() => {
          toggleWalletSwitcher();
          tryActivation(item.connector);
        }}
      >
        <div>{item.name}</div>
        <Image src={item.iconName} width={24} height={24} preview={false} />
      </div>
    </>
  );
};

export default WalletModal;
