import { CurrencySearchModalContext } from "components/CurrenySearchModal/context/CurrencySearchModalContext";
import { ERC20Token } from "entities/erc20token";
import React, { useContext } from "react";
import { useAddUserToken } from "../../../state/user/userHook";
import TokenInfo from "../components/TokenInfo";

interface ImportTokenProps {}

const ImportToken: React.FunctionComponent<ImportTokenProps> = (props) => {
  const addToken = useAddUserToken();
  const { importToken } = useContext(CurrencySearchModalContext);
  // const list =
  //   importToken instanceof WrappedTokenInfo ? importToken.list ?  : undefined;
  const tokens = [importToken] as ERC20Token[];

  return (
    <>
      <div className="manage--tokens">
        <div className="manage--tokens--import--token">
          <div className="manage--tokens--import--token__info">
            {tokens.map((token) => {
              return <TokenInfo token={token} />;
            })}
          </div>
          <div className="manage--tokens--import--token__ruleOne">
            Anyone can create a BEP20 token on Binance Smart Chain Testnet with
            any name, including creating fake versions of existing tokens and
            tokens that claim to represent projects that do not have a token.
          </div>

          <div className="manage--tokens--import--token__ruleTwo">
            If you purchase an arbitrary token,{" "}
            <span>you may not be able to sell it back.</span>
          </div>

          <div className="manage--tokens--import--token__btn">
            <button
              className="btn btn-primary"
              onClick={() => {
                tokens.map((token) => {
                  addToken(token);
                });
              }}
            >
              Import
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ImportToken;
