import { TESTNETS } from "../constants";
import { ChainId } from "constants/chainIds";
import {
  NetworkOptionsPreset,
  NetworkSwitcherTags,
} from "constants/networkSwitch";

export type NetworksList = {
  tag?: NetworkSwitcherTags;
  networks: NetworkOptions[];
};

export type NetworkOptions = {
  preset: NetworkOptionsPreset;
  active?: boolean;
  disabled?: boolean;
  onClick?: any;
  connected?: boolean;
};

const createNetworkOptions = ({
  networkPreset,
  onNetworkChange,
  isNetworkDisabled,
  selectedNetworkChainId,
  activeChainId,
}: {
  networkPreset: NetworkOptionsPreset;
  selectedNetworkChainId: ChainId;
  activeChainId: ChainId | undefined;
  onNetworkChange: (chainId: ChainId) => void;
  isNetworkDisabled: (
    optionChainId: ChainId,
    selectedNetworkChainId: ChainId
  ) => boolean;
}): NetworkOptions => {
  const { chainId } = networkPreset;
  return {
    preset: networkPreset,
    active: selectedNetworkChainId === activeChainId,
    disabled: isNetworkDisabled(networkPreset.chainId, selectedNetworkChainId),
    onClick: () => onNetworkChange(chainId),
  };
};

export const createNetworksList = ({
  networkOptionsPreset,
  onNetworkChange,
  isNetworkDisabled,
  selectedNetworkChainId,
  activeChainId,
  ignoreTags,
  showTestnets,
}: {
  networkOptionsPreset: NetworkOptionsPreset[];
  onNetworkChange: (chainId: ChainId) => void;
  isNetworkDisabled: (
    optionChainId: ChainId,
    selectedNetworkChainId: ChainId
  ) => boolean;
  selectedNetworkChainId: ChainId;
  activeChainId: ChainId | undefined;
  ignoreTags?: string[];
  showTestnets?: boolean;
}) => {
  let networks = networkOptionsPreset;

  return networks
    .filter(
      (network) =>
        showTestnets ||
        !TESTNETS.includes(network.chainId) ||
        network.chainId === activeChainId
    )
    .reduce<NetworksList[]>((taggedList, currentNet) => {
      const tag = currentNet.tag;
      const networkPreset = currentNet;
      const enhancedNetworkOptions = createNetworkOptions({
        networkPreset,
        selectedNetworkChainId,
        activeChainId,
        onNetworkChange,
        isNetworkDisabled,
      });

      // check if tag exist and if not create array
      const tagArrIndex = taggedList.findIndex(
        (existingTagArr) => existingTagArr.tag === tag
      );
      if (tagArrIndex > -1) {
        taggedList[tagArrIndex].networks.push(enhancedNetworkOptions);
      } else {
        taggedList.push({ tag, networks: [enhancedNetworkOptions] });
      }
      return taggedList;
    }, []);
};
