import React, { useState } from "react";

const isSource = (source: string | undefined): source is string => !!source;

interface LogoProps {
  sources: (string | undefined)[];
  size?: string;
  defaultText: string;
}
const BAD_SRCS: { [tokenAddress: string]: true } = {};

const Logo: React.FunctionComponent<LogoProps> = ({
  sources,
  size,
  defaultText,
  ...rest
}) => {
  const [, refresh] = useState<number>(0);
  const src = sources.filter(isSource).find((src) => !BAD_SRCS[src]);

  if (src) {
    return (
      <img
        {...rest}
        className="logo"
        src={src}
        onError={() => {
          if (src) BAD_SRCS[src] = true;
          refresh((i) => i + 1);
        }}
      />
    );
  }
  const numberSize = size ? parseInt(size) : 24;

  return (
    <>
      <svg height={numberSize} width={numberSize} {...rest} fill="none">
        <circle
          cx={numberSize / 2}
          cy={numberSize / 2}
          r="50%"
          fill={"white"}
        />
        <text
          height={numberSize}
          width={numberSize}
          fill={"black"}
          stroke="none"
          fontSize={Math.floor(numberSize / 4)}
          fontWeight="600"
          y="60%"
          x="50%"
          textAnchor="middle"
        >
          {defaultText.length > 4
            ? `${defaultText.slice(0, 3).toUpperCase()}...`
            : defaultText.toUpperCase()}
        </text>
      </svg>
    </>
  );
};

export default Logo;
