import { Action, configureStore, ThunkAction } from "@reduxjs/toolkit";
import multiCallReducer from "state/multicall/multiCallSlice";
import swapViewReducer from "features/swap/swapSlice";
import swapReducer from "state/swap/swapSlice";
import userSlice from "state/user/userSlice";
import authSlice from "../slice/authSlice";
import providerSlice from "../slice/providerSlice";
import commonSlice from "../slice/commonSlice";
import transactionsReducer from "../state/transactions/reducer";
import loadingSlice from "components/UIElements/loadingSlice";
import applicationReducer from "state/application/application";
import listsReducer from "state/lists/reducer";
export const store = configureStore({
  reducer: {
    multiCall: multiCallReducer,
    swapView: swapViewReducer,
    swap: swapReducer,
    user: userSlice,
    loading: loadingSlice,
    auth: authSlice,
    provider: providerSlice,
    common: commonSlice,
    transactions: transactionsReducer,
    application: applicationReducer,
    lists: listsReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
