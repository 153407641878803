import { Modal } from "antd";
import { useContext, useEffect, useState } from "react";
import { useWeb3React } from "@web3-react/core";
import { getAllNativeCurrenciesAndTokens } from "./useToken";
import { useCurrencyBalances } from "./useBalances";
import { Token } from "../models/manageToken/index";
import ManageToken from "features/manageToken/pages/ManageToken";
import usePrevious from "./usePrevious";
import ManageTokenHeader from "features/manageToken/components/ManageTokenHeader";
import { useAllTokens } from "./useAllTokens";

import _ from "lodash";

import CurrencySearchPage from "../components/CurrencySearch/pages/CurrencySearchPage";
import { CurrencySearchModalContext } from "../components/CurrenySearchModal/context/CurrencySearchModalContext";
import { CurrencyModalView } from "../models/swap/CurrencySearchModal";
import ImportListHeader from "../features/manageToken/components/ImportListHeader";
import ImportTokenHeader from "../features/manageToken/components/ImportTokenHeader";
import ImportList from "../components/ImportList/pages/ImportList";
import ImportToken from "../components/ImportToken/pages/ImportToken";
interface useModalTokenProps {
  className: string;
}

const useModalToken = (className?: string) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { chainId = 0, account } = useWeb3React();
  const [currentToken, setCurrentToken] = useState<Token | null>(null);
  const { modalView, setModalView, importList, listURL, importToken } =
    useContext(CurrencySearchModalContext);
  const tokens = getAllNativeCurrenciesAndTokens(chainId); // default list

  const balances = useCurrencyBalances(account ?? undefined, tokens).reduce(
    (memo: any, balance) => {
      if (balance?.currency.symbol)
        memo[balance?.currency.symbol] = balance?.amount.toFixed(4);
      return memo;
    },
    {}
  );

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const onSelectToken = (token: Token) => {
    setCurrentToken(token);
    setIsModalVisible(false);
  };

  const [filterTokens, setFilterTokens] = useState<any>([]);

  const prevTokens = usePrevious(tokens);

  const [rememberToken, setRememberToken] = useState<number>(tokens.length);

  useEffect(() => {
    // if (tokens && tokens.length > 0) {
    //   setFilterTokens([...tokens]);
    // } else {
    //   setFilterTokens([]);
    // }

    if (!_.isEqual(prevTokens, tokens)) {
      setFilterTokens([...tokens]);
      console.log("Run when reference value is changed.");
    }
  }, [prevTokens]);

  const [view, setView] = useState<number>(0);
  const allTokens = useAllTokens();
  const modal: JSX.Element = (
    <>
      <Modal
        className={className}
        open={isModalVisible}
        destroyOnClose={true}
        closable={true}
        footer={null}
        title={
          <>
            {modalView === CurrencyModalView.MANAGE && (
              <ManageTokenHeader
                showSearchView={() => setModalView(CurrencyModalView.SEARCH)}
              />
            )}
            {modalView === CurrencyModalView.SEARCH && (
              <div className="manage--tokens--header__title">
                Select a token
              </div>
            )}
            {modalView === CurrencyModalView.IMPORT_LIST && (
              <ImportListHeader
                showManageView={() => setModalView(CurrencyModalView.MANAGE)}
              />
            )}
            {modalView === CurrencyModalView.IMPORT_TOKEN && (
              <ImportTokenHeader
                showManageView={() => setModalView(CurrencyModalView.MANAGE)}
              />
            )}
          </>
        }
        onOk={handleOk}
        onCancel={handleCancel}
      >
        {modalView === CurrencyModalView.SEARCH && (
          <CurrencySearchPage
            onSelectToken={onSelectToken}
            showManageView={() => setModalView(CurrencyModalView.MANAGE)}
          />
        )}
        {modalView === CurrencyModalView.IMPORT_LIST && <ImportList />}
        {modalView === CurrencyModalView.IMPORT_TOKEN && <ImportToken />}
        {/* {view === 0 && (
          <>
            <SearchField
              name="Search"
              placeholder="Search name of paste address"
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                const value = e.target.value;

                if (value) {
                  const character = value.toLowerCase();
                  setFilterTokens(
                    filterTokens.filter((token: any) => {
                      return (
                        token.name.toLowerCase().includes(character) ||
                        token.symbol.toLowerCase().includes(character) ||
                        token.address.toLowerCase().includes(character)
                      );
                    })
                  );
                } else {
                  setFilterTokens([...tokens]);
                }
              }}
            />
            <div className="currencies">
              {filterTokens.map((item: any) => {
                return (
                  <TokenItem
                    token={item}
                    price={balances && item.symbol ? balances[item.symbol] : 0}
                    onClick={onSelectToken}
                  />
                );
              })}
            </div>
            <div
              className="manage--btn"
              onClick={() => {
                setView(1);
              }}
            >
              Manage Token
            </div>
          </>
        )} */}
        {modalView === CurrencyModalView.MANAGE && (
          <>
            <ManageToken />
          </>
        )}
      </Modal>
    </>
  );
  return {
    modal,
    showModal,
    currentToken,
  };
};

export default useModalToken;
