import { Image } from "antd";
import { useAppDispatch } from "app/hooks";
import { CurrencySearchModalContext } from "components/CurrenySearchModal/context/CurrencySearchModalContext";
import { useFetchListCallback } from "hooks/useFetchListCallback";
import { CurrencyModalView } from "models";
import React, { useCallback, useContext, useState } from "react";
import { enableList, removeList } from "state/lists/actions";
import { useAllLists } from "state/lists/hooks";

interface ImportListProps {}

const ImportList: React.FunctionComponent<ImportListProps> = (props) => {
  const {
    listURL: listURI,
    importList: list,
    setModalView,
  } = useContext(CurrencySearchModalContext);
  const lists = useAllLists();
  const fetchList = useFetchListCallback();
  const adding = listURI && Boolean(lists[listURI]?.loadingRequestId);
  const [addError, setAddError] = useState<string>("");
  const dispatch = useAppDispatch();

  const handleAddList = useCallback(() => {
    if (adding || !listURI) return;
    setAddError("");
    fetchList(listURI)
      .then(() => {
        dispatch(enableList(listURI));
        setModalView(CurrencyModalView.MANAGE);
      })
      .catch((error) => {
        setAddError(error.message);
        dispatch(removeList(listURI));
      });
  }, [adding, dispatch, fetchList, listURI, setModalView]);

  return (
    <>
      <div className="manage--tokens">
        <div className="manage--tokens--import--list">
          <div className="manage--tokens--import--list__info">
            <Image src={list?.logoURI} preview={false} width={50} height={50} />
            <div className="manage--tokens--import--list__info__nameEx">
              <div className="">{list?.name}</div>
              <a
                target="_blank"
                href={`https://tokenlists.org/token-list?url=${listURI}`}
              >
                View on token lists explorer
              </a>
            </div>
          </div>

          <div className="manage--tokens--import--list__ruleOne">
            By adding this list you are implicitly trusting that the data is
            correct. Anyone can create a list, including creating fake versions
            of existing lists and lists that claim to represent projects that do
            not have one.
          </div>

          <div className="manage--tokens--import--list__ruleTwo">
            If you purchase a token from this list,{" "}
            <span>you may not be able to sell it back.</span>
          </div>

          <div className="manage--tokens--import--list__btn">
            <button className="btn btn-primary" onClick={handleAddList}>
              Import
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ImportList;
