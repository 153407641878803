import { parseUnits } from '@ethersproject/units';
import {
  Currency,
  CurrencyAmount,
  ETHER,
  JSBI,
  Token,
  TokenAmount,
  Trade,
  ChainId,
  SmartRouter,
} from '@ape.swap/sdk';
import { ParsedQs } from 'qs';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useENS from 'libs/ape-swap/hooks/ENS/useENS';
import { useCurrency } from 'libs/ape-swap/hooks/Tokens';
import { useAllCommonPairs } from 'libs/ape-swap/hooks/Trades';
import useParsedQueryString from 'libs/ape-swap/hooks/useParsedQueryString';
import contracts from 'config/constants/contracts';
import useFindBestRoute from 'libs/ape-swap/hooks/useFindBestRoute';
import { isAddress } from 'utils/addressHelpers';
import { RouterTypes } from 'libs/ape-swap/constants';
import { AppDispatch, RootState } from 'app/store';
import { useCurrencyBalances } from 'state/wallet/hooks';
import {
  Field,
  replaceSwapState,
  selectCurrency,
  setRecipient,
  switchCurrencies,
  typeInput,
  setSwapDelay,
  SwapDelay,
  RouterTypeParams,
  setBestRoute,
} from 'state/swap/actions';

import { SwapState } from 'state/swap/swapSlice';
import { useUserSlippageTolerance } from 'state/user/userHook';
import { computeSlippageAdjustedAmounts } from 'libs/ape-swap/utils/prices';
import { useWeb3React } from '@web3-react/core';
import { useAppDispatch } from 'app/hooks';

export function useSwapState(): RootState['swap'] {
  return useSelector<RootState, RootState['swap']>((state) => state.swap);
}

export function useSwapActionHandlers(): {
  onCurrencySelection: (field: Field, currency: Currency, typedValue?: string) => void;
  onSwitchTokens: () => void;
  onUserInput: (field: Field, typedValue: string) => void;
  onChangeRecipient: (recipient: string | null) => void;
  onSetSwapDelay: (swapDelay: SwapDelay) => void;
  onBestRoute: (bestRoute: RouterTypeParams) => void;
} {
  const dispatch = useAppDispatch();
  const timer = useRef<NodeJS.Timeout>();

  const onSetSwapDelay = useCallback(
    (swapDelay: SwapDelay) => {
      dispatch(setSwapDelay({ swapDelay }));
    },
    [dispatch]
  );

  const onSwitchTokens = useCallback(() => {
    dispatch(switchCurrencies());
    // Set input to complete to recalculate the best path
    onSetSwapDelay(SwapDelay.USER_INPUT_COMPLETE);
  }, [dispatch, onSetSwapDelay]);

  const onUserInput = useCallback(
    (field: Field, typedValue: string) => {
      dispatch(typeInput({ field, typedValue }));
      if (!typedValue) {
        onSetSwapDelay(SwapDelay.INIT);
        return;
      }
      // Set state as user input delay
      onSetSwapDelay(SwapDelay.USER_INPUT);
      // Reset previous timer on user input
      clearTimeout(timer.current);
      // Set new timer to check wallchain router
      timer.current = setTimeout(() => {
        // Set state that user has finished inputing
        onSetSwapDelay(SwapDelay.USER_INPUT_COMPLETE);
      }, 300);
    },
    [dispatch, onSetSwapDelay, timer]
  );

  const onCurrencySelection = useCallback(
    (field: Field, currency: Currency, typedValue?: string) => {
      // We dont need to timeout if their is no typed value
      dispatch(
        selectCurrency({
          field,
          currencyId:
            currency instanceof Token ? currency.address : currency === ETHER ? 'ETH' : '',
        })
      );
      // Set input to complete to recalculate the best path
      if (!typedValue) {
        onSetSwapDelay(SwapDelay.USER_INPUT_COMPLETE);
      } else {
        onSetSwapDelay(SwapDelay.USER_INPUT);
        timer.current = setTimeout(() => {
          // Set state that user has finished inputing
          onSetSwapDelay(SwapDelay.USER_INPUT_COMPLETE);
        }, 1000);
      }
    },
    [dispatch, onSetSwapDelay]
  );

  const onChangeRecipient = useCallback(
    (recipient: string | null) => {
      dispatch(setRecipient({ recipient }));
    },
    [dispatch]
  );

  const onBestRoute = useCallback(
    (bestRoute: RouterTypeParams) => {
      dispatch(setBestRoute({ bestRoute }));
    },
    [dispatch]
  );

  return {
    onSwitchTokens,
    onCurrencySelection,
    onUserInput,
    onChangeRecipient,
    onSetSwapDelay,
    onBestRoute,
  };
}

// try to parse a user entered amount for a given token
export function tryParseAmount(value?: string, currency?: Currency): CurrencyAmount | undefined {
  if (!value || !currency) {
    return undefined;
  }
  try {
    const typedValueParsed = parseUnits(value, currency.decimals).toString();
    if (typedValueParsed !== '0') {
      return currency instanceof Token
        ? new TokenAmount(currency, JSBI.BigInt(typedValueParsed))
        : CurrencyAmount.ether(JSBI.BigInt(typedValueParsed));
    }
  } catch (error) {
    // should fail if the user specifies too many decimal places of precision (or maybe exceed max uint?)
    console.debug(`Failed to parse input amount: "${value}"`, error);
  }
  // necessary for all paths to return a value
  return undefined;
}

const BAD_RECIPIENT_ADDRESSES: string[] = [
  '0xBCfCcbde45cE874adCB698cC183deBcF17952812', // v2 factory
  '0xf164fC0Ec4E93095b804a4795bBe1e041497b92a', // v2 router 01
  '0x05fF2B0DB69458A0750badebc4f9e13aDd608C7F', // v2 router 02
];

/**
 * Returns true if any of the pairs or tokens in a trade have the given checksummed address
 * @param trade to check for the given address
 * @param checksummedAddress address to check in the pairs and tokens
 */
function involvesAddress(trade: Trade, checksummedAddress: string): boolean {
  return (
    trade.route.path.some((token) => token.address === checksummedAddress) ||
    trade.route.pairs.some((pair) => pair.liquidityToken.address === checksummedAddress)
  );
}

// from the current swap inputs, compute the best trade and return it.
export function useApeswapDerivedSwapInfo(): {
  currencies: { [field in Field]?: Currency };
  currencyBalances: { [field in Field]?: CurrencyAmount };
  parsedAmount: CurrencyAmount | undefined;
  trade: Trade | undefined;
  inputError?: string;
  v1Trade: Trade | undefined;
} {
  const { account, chainId } = useWeb3React();

  const {
    independentField,
    typedValue,
    [Field.INPUT]: { currencyId: inputCurrencyId },
    [Field.OUTPUT]: { currencyId: outputCurrencyId },
    recipient,
  } = useSwapState();

  const inputCurrency = useCurrency(inputCurrencyId);
  const outputCurrency = useCurrency(outputCurrencyId);
  useAllCommonPairs(inputCurrency as never, outputCurrency as never);
  const { v2Trade, bestTradeExactIn, bestTradeExactOut } = useFindBestRoute();
  // const recipientLookup = useENS(recipient ?? undefined);
  // const to: string | null = (recipient === null ? account : recipientLookup.address) ?? null;

  const relevantTokenBalances = useCurrencyBalances(account ?? undefined, [
    inputCurrency ?? undefined,
    outputCurrency ?? undefined,
  ]);

  const isExactIn: boolean = independentField === Field.INPUT;
  const parsedAmount = tryParseAmount(
    typedValue,
    (isExactIn ? inputCurrency : outputCurrency) ?? undefined
  );

  const currencyBalances = useMemo(
    () => ({
      [Field.INPUT]: relevantTokenBalances[0] || parsedAmount,
      [Field.OUTPUT]: relevantTokenBalances[1],
    }),
    [relevantTokenBalances]
  );

  const currencies: { [field in Field]?: Currency | null } = useMemo(
    () => ({
      [Field.INPUT]: inputCurrency ?? undefined,
      [Field.OUTPUT]: outputCurrency ?? undefined,
    }),
    [inputCurrency, outputCurrency]
  );

  let inputError: string | undefined;
  if (!account) {
    inputError = 'Connect Wallet';
  }

  if (!parsedAmount) {
    inputError = inputError ?? 'Enter an amount';
  }

  if (!currencies[Field.INPUT] || !currencies[Field.OUTPUT]) {
    inputError = inputError ?? 'Select a token';
  }

  // const formattedTo = isAddress(to);
  // if (!to || !formattedTo) {
  //   inputError = inputError ?? 'Enter a recipient';
  // } else if (
  //   BAD_RECIPIENT_ADDRESSES.indexOf(formattedTo) !== -1 ||
  //   (bestTradeExactIn && involvesAddress(bestTradeExactIn, formattedTo)) ||
  //   (bestTradeExactOut && involvesAddress(bestTradeExactOut, formattedTo))
  // ) {
  //   inputError = inputError ?? 'Invalid recipient';
  // }

  const [allowedSlippage] = useUserSlippageTolerance();

  const slippageAdjustedAmounts =
    v2Trade && allowedSlippage && computeSlippageAdjustedAmounts(v2Trade, allowedSlippage);
  // const swapCalls = useSwapCallArguments(v2Trade, allowedSlippage, recipient)

  // compare input balance to max input based on version
  const [balanceIn, amountIn] = [
    currencyBalances[Field.INPUT],
    slippageAdjustedAmounts ? slippageAdjustedAmounts[Field.INPUT] : null,
  ];

  if (balanceIn && amountIn && balanceIn.lessThan(amountIn)) {
    inputError = `${'Insufficient'} ${amountIn.currency.getSymbol(chainId)} ${'balance'}`;
  }

  return {
    currencies: currencies as any,
    currencyBalances,
    parsedAmount,
    trade: v2Trade ?? undefined,
    inputError,
    v1Trade: undefined,
  };
}

function parseCurrencyFromURLParameter(urlParam: any): string {
  if (typeof urlParam === 'string') {
    const valid = isAddress(urlParam);
    if (valid) return valid;
    if (urlParam.toUpperCase() === 'ETH') return 'ETH';
    if (valid === false) return 'ETH';
  }
  return 'ETH' ?? '';
}

function parseTokenAmountURLParameter(urlParam: any): string {
  // eslint-disable-next-line no-restricted-globals
  return typeof urlParam === 'string' && !isNaN(parseFloat(urlParam)) ? urlParam : '';
}

function parseIndependentFieldURLParameter(urlParam: any): Field {
  return typeof urlParam === 'string' && urlParam.toLowerCase() === 'output'
    ? Field.OUTPUT
    : Field.INPUT;
}

const ENS_NAME_REGEX =
  /^[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&/=]*)?$/;
const ADDRESS_REGEX = /^0x[a-fA-F0-9]{40}$/;
function validatedRecipient(recipient: any): string | null {
  if (typeof recipient !== 'string') return null;
  const address = isAddress(recipient);
  if (address) return address;
  if (ENS_NAME_REGEX.test(recipient)) return recipient;
  if (ADDRESS_REGEX.test(recipient)) return recipient;
  return null;
}

export function queryParametersToSwapState(parsedQs: ParsedQs, chainId: ChainId): SwapState {
  let inputCurrency = parseCurrencyFromURLParameter(parsedQs.inputCurrency);
  let outputCurrency = parseCurrencyFromURLParameter(parsedQs.outputCurrency);

  const chainAddress = contracts.banana[chainId as never];

  if (inputCurrency === outputCurrency) {
    if (typeof parsedQs.outputCurrency === 'string') {
      inputCurrency = '';
    } else {
      outputCurrency = chainAddress;
    }
  }

  const recipient = validatedRecipient(parsedQs.recipient);
  const swapDelay = SwapDelay.INIT;
  const bestRoute = { routerType: RouterTypes.APE, smartRouter: SmartRouter.APE };

  return {
    [Field.INPUT]: {
      currencyId: inputCurrency,
    },
    [Field.OUTPUT]: {
      currencyId: outputCurrency,
    },
    typedValue: parseTokenAmountURLParameter(parsedQs.exactAmount),
    independentField: parseIndependentFieldURLParameter(parsedQs.exactField),
    recipient,
    swapDelay,
    bestRoute,
    pairDataById: {},
    derivedPairDataById: {},
  };
}

// updates the swap state to use the defaults for a given network
export function useDefaultsFromURLSearch():
  | { inputCurrencyId: string | undefined; outputCurrencyId: string | undefined }
  | undefined {
  const { chainId } = useWeb3React();
  const dispatch = useDispatch<AppDispatch>();
  const parsedQs = useParsedQueryString();
  const parsed = useMemo(() => {
    return queryParametersToSwapState(parsedQs, chainId as number);
  }, [parsedQs, chainId]);
  const swapDelay = SwapDelay.INIT;
  const bestRoute = { routerType: RouterTypes.APE, smartRouter: SmartRouter.APE };
  useEffect(() => {
    if (!chainId) return;
    const inputCurrencyId = parsed[Field.INPUT].currencyId ?? undefined;
    const outputCurrencyId = parsed[Field.OUTPUT].currencyId ?? undefined;

    dispatch(
      replaceSwapState({
        typedValue: parsed.typedValue,
        field: parsed.independentField,
        inputCurrencyId,
        outputCurrencyId,
        recipient: parsed.recipient,
        swapDelay,
        bestRoute,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, chainId]);

  return {
    inputCurrencyId: parsed[Field.INPUT].currencyId,
    outputCurrencyId: parsed[Field.OUTPUT].currencyId,
  };
}
