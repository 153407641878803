export enum ApplicationModal {
  SETTINGS,
  SELF_CLAIM,
  ADDRESS_CLAIM,
  CLAIM_POPUP,
  MENU,
  MOBILE,
  WALLET_SWITCHER,
  NETWORK_SWITCHER,
  ETHEREUM_OPTION,
  NETWORK_SWITCHER_FROM,
  NETWORK_SWITCHER_TO,
  UNSUPPORTED_NETWORK,
  SIMPLE_SETTINGS,
  EXPEDITIONS,
}
