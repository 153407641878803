import { useWeb3React } from "@web3-react/core";
import { useAppSelector } from "app/hooks";
import { Currency, RawCurrency } from "entities/currency";
import { useCurrencyBalances } from "hooks/useBalances";
import { RouterTypes } from "libs/ape-swap/constants";
import { Field } from "state/swap/types";
import { useApeswapDerivedSwapInfo } from "./hook/useApeSwapBestTrade";
import { useBiSwapDerivedSwapInfo } from "./hook/useBiSwapBestTrade";
import { useMdexDerivedSwapInfo } from "./hook/useMdexBestTrade";
import { usePancakeDerivedSwapInfo } from "./hook/usePancakeBestTrade";
import { SmartRouter, Trade } from "@ape.swap/sdk";
import { useEffect, useState } from "react";
import { CurrencyAmount, Percent } from "@pancakeswap/sdk";
import { StaticJsonRpcProvider } from "@ethersproject/providers";
import { EcoRouterResults } from "../../libs/eco-router/types";
import { useSwapRoute } from "hooks/useSwapRoute";
import { All__Platform__Trades } from "../../constants/index";

export function useSwapState() {
  return useAppSelector((state) => state.swap);
}

export function useDerivedSwapInfo(
  independentField: Field,
  typedValue: string,
  inputCurrency: RawCurrency | undefined,
  outputCurrency: RawCurrency | undefined,
  recipient: string | null,
  platformOverride?: string
) {
  const { account } = useWeb3React();

  const relevantTokenBalances = useCurrencyBalances(account ?? undefined, [
    inputCurrency ?? undefined,
    outputCurrency ?? undefined,
  ]);

  const currencyBalances = {
    [Field.INPUT]: relevantTokenBalances[0],
    [Field.OUTPUT]: relevantTokenBalances[1],
  };

  const currencies = {
    [Field.INPUT]: inputCurrency ?? undefined,
    [Field.OUTPUT]: outputCurrency ?? undefined,
  };

  const pancakeTrade = usePancakeDerivedSwapInfo(
    independentField,
    inputCurrency,
    outputCurrency,
    typedValue
  );

  const mdexTrade = useMdexDerivedSwapInfo(
    independentField,
    inputCurrency,
    outputCurrency,
    typedValue
  );

  const biswapTrade = useBiSwapDerivedSwapInfo(
    independentField,
    inputCurrency,
    outputCurrency,
    typedValue
  );

  const apeswapTrade = useApeswapDerivedSwapInfo();

  const [allPlatformTrades, setAllPlatformTrades] = useState<Trade[]>([]);

  const apeswapBestRoute = {
    routerType: RouterTypes.APE,
    smartRouter: SmartRouter.PANCAKE,
  };

  let inputError: string | undefined;

  if (!account) {
    inputError = "Connect Wallet";
  } else if (!typedValue) {
    inputError = "Enter an amount";
  } else if (!currencies[Field.INPUT] || !currencies[Field.OUTPUT]) {
    inputError = "Select a token";
  }


  // const [platformTrade, setPlatformTrade] = useState<any>()

  // useEffect(() => {
  //   let _allTrades = [{ name: "PancakeSwap", trade: pancakeTrade }, {
  //     name: "MdexSwap", trade: mdexTrade
  //   }, {
  //     name: "Biswap",
  //     trade: biswapTrade
  //   }, { name: "ApeSwap", trade: apeswapTrade }]
  //   if (platformOverride) {
  //     setPlatformTrade(_allTrades.find((t: any) => { return t.name === platformOverride }))
  //   }
  // }, [platformOverride, pancakeTrade, mdexTrade, biswapTrade, apeswapTrade]);
  // console.log("platformTrade ne", platformTrade)


  return {
    currencies,
    currencyBalances,
    inputError,
    trade: pancakeTrade,
    mdexTrade: mdexTrade,
    biswapTrade: biswapTrade,
    apeswapTrade: apeswapTrade,
    apeswapBestRoute: apeswapBestRoute,
  };
}
