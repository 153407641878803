import { QuestionCircleFilled } from "@ant-design/icons";
import { Button, Input, Modal, Typography } from "antd";
import React, { useState } from "react";
import { GAS_PRICE_GWEI } from "state/types";
import {
  useGasPriceManager,
  useUserSlippageTolerance,
  useUserTransactionTTL,
} from "state/user/userHook";

interface UseSettingModalResult {
  modal: JSX.Element;
  showModal: () => void;
}

interface Gas {
  id: number;
  name: string;
  value: string;
}

interface Slippage {
  id: number;
  name: string;
  value: number;
}

interface Deadline {
  id: number;
  name: string;
  value: number;
}

export const gas: Gas[] = [
  {
    id: 1,
    name: "Default",
    value: "rpcDefault",
  },
  {
    id: 2,
    name: "Standard (3)",
    value: GAS_PRICE_GWEI.default,
  },
  {
    id: 3,
    name: "Fast (4)",
    value: GAS_PRICE_GWEI.fast,
  },
  {
    id: 4,
    name: "Instant (5)",
    value: GAS_PRICE_GWEI.instant,
  },
];

const slippages: Slippage[] = [
  {
    id: 1,
    name: "0.1%",
    value: 0.1,
  },
  {
    id: 2,
    name: "0.5%",
    value: 0.5,
  },
  {
    id: 3,
    name: "1%",
    value: 1,
  },
];

const deadlines: Deadline[] = [
  {
    id: 1,
    name: "5",
    value: 5,
  },
  {
    id: 2,
    name: "10",
    value: 10,
  },
  {
    id: 3,
    name: "20",
    value: 20,
  },
];

export const useSettingModal = (): UseSettingModalResult => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentGas, setCurrentGas] = useState<Gas>(gas[0]);
  const [currentSlippage, setCurrentSlippage] = useState<Slippage | number>(
    slippages[1]
  );
  const [currentDeadline, setCurrentDeadline] = useState<Deadline | number>(
    deadlines[2]
  );
  const [allowedSlippage, setUserSlippageTolerance] =
    useUserSlippageTolerance();
  const [gasPrice, setGasPrice] = useGasPriceManager();
  const [ttl, setTtl] = useUserTransactionTTL()

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const changeGas = (gas: Gas) => {
    setGasPrice(gas.value);
    setCurrentGas(gas);
  };

  const changeSlippage = (slippage: Slippage | number) => {
    if (typeof slippage === "number") {
      setUserSlippageTolerance(slippage * 100);
    } else {
      setUserSlippageTolerance(slippage.value * 100);
    }
    setCurrentSlippage(slippage);
  };

  const changeDeadline = (deadline: Deadline | number) => {
    if (typeof deadline === "number") {
      setTtl(deadline * 60);
    } else {
      setTtl(deadline.value * 60);
    }
    setCurrentDeadline(deadline);
  };

  const modal: JSX.Element = (
    <Modal
      open={isModalVisible}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={null}
      destroyOnClose={true}
      closable={true}
      title={<div className="manage--tokens--header__title">Setting</div>}
    >
      <div className="mb-3" />
      <div className="mb-3">
        <Typography.Title className="text-white" level={4}>
          Default Transaction Speed (GWEI) <QuestionCircleFilled />
        </Typography.Title>
        <div className="d-flex align-items-center cg-10">
          {gas.map((g) => {
            return (
              <Button
                className={
                  typeof currentGas !== "number" && g.id === currentGas.id
                    ? "background-primary text-secondary text-bold"
                    : "background-secondary text-primary"
                }
                key={`${g.name}`}
                style={{
                  border: "none",
                  outline: "none",
                  borderRadius: "20px",
                }}
                onClick={() => changeGas(g)}
                // type={g.id === currentGas.id ? "primary" : "default"}
              >
                {g.name}
              </Button>
            );
          })}
        </div>
      </div>
      <div className="mb-3">
        <Typography.Title className="text-white" level={4}>
          Slippage Tolerance <QuestionCircleFilled />
        </Typography.Title>
        <div className="d-flex align-items-center cg-10">
          {slippages.map((s) => {
            return (
              <Button
                className={
                  typeof currentSlippage !== "number" &&
                  s.id === currentSlippage.id
                    ? "background-primary text-secondary text-bold"
                    : "background-secondary text-primary"
                }
                key={`${s.name}`}
                style={{
                  border: "none",
                  outline: "none",
                  borderRadius: "20px",
                }}
                onClick={() => changeSlippage(s)}
                type={
                  typeof currentSlippage !== "number" &&
                  s.id === currentSlippage.id
                    ? "primary"
                    : "default"
                }
              >
                {s.name}
              </Button>
            );
          })}
          <Input
            className="background-secondary text-primary"
            placeholder="0.1%"
            style={{
              width: 60,
              border: "none",
              outline: "none",
              borderRadius: "20px",
            }}
            onChange={(e) => changeSlippage(Number(e.target.value))}
          />
          <span className="text-white">%</span>
        </div>
      </div>
      <div className="mb-3">
        <Typography.Title className="text-white" level={4}>
          Tx deadline (mins) <QuestionCircleFilled />
        </Typography.Title>
        <div className="d-flex align-items-center cg-10">
          {deadlines.map((d) => {
            return (
              <Button
                className={
                  typeof currentDeadline !== "number" &&
                  d.id === currentDeadline.id
                    ? "background-primary text-secondary text-bold"
                    : "background-secondary text-primary"
                }
                key={`${d.name}`}
                style={{
                  border: "none",
                  outline: "none",
                  borderRadius: "20px",
                }}
                onClick={() => changeDeadline(d)}
                type={
                  typeof currentDeadline !== "number" &&
                  d.id === currentDeadline.id
                    ? "primary"
                    : "default"
                }
              >
                {d.name}
              </Button>
            );
          })}
          <Input
            className="background-secondary text-primary"
            placeholder="10"
            style={{
              width: 60,
              border: "none",
              outline: "none",
              borderRadius: "20px",
            }}
            onChange={(e) => changeDeadline(Number(e.target.value))}
          />
        </div>
      </div>
    </Modal>
  );
  return {
    modal,
    showModal,
  };
};
