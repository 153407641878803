import type { Signer } from "@ethersproject/abstract-signer";
import { Contract } from "@ethersproject/contracts";
import type { Provider } from "@ethersproject/providers";
import { JsonRpcSigner, Web3Provider } from "@ethersproject/providers";
import { useWeb3React } from "@web3-react/core";
import IPancakeRouter02ABI from "config/abis/pancakeRouter02.json";
import { ChainId } from "libs/pancake-swap";
import { isAddress } from "utils/addressHelpers";
import { getSimpleRpcProvider } from "utils/providers";

export const AddressZero = "0x0000000000000000000000000000000000000000";

// account is not optional
export function getSigner(
  library: Web3Provider,
  account: string
): JsonRpcSigner {
  return library.getSigner(account).connectUnchecked();
}

// account is optional
export function getProviderOrSigner(
  library: Web3Provider,
  account?: string
): Web3Provider | JsonRpcSigner {
  return account ? getSigner(library, account) : library;
}

export function getContract(
  address: string,
  ABI: any,
  singer?: Signer | Provider
) {
  if (!isAddress(address) || address === AddressZero) {
    throw Error(`Invalid 'address' parameter '${address}'`);
  }
  return new Contract(
    address,
    ABI,
    singer ?? getSimpleRpcProvider(ChainId.MAINNET)
  );
}

export const ROUTER_ADDRESS: any = {
  56: "0x0384E9ad329396C3A6A401243Ca71633B2bC4333",
  97: "0xD99D1c33F9fC3444f8101754aBC46c52416550D1",
};

// account is optional
export function getRouterContract(
  _: number,
  library: Web3Provider,
  account?: string
) {
  return getContract(
    ROUTER_ADDRESS[_ as never],
    IPancakeRouter02ABI,
    getProviderOrSigner(library, account)
  );
}
