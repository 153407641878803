import useSWR, { useSWRConfig } from 'swr';
import useSWRImmutable from 'swr/immutable';
import { getSimpleRpcProvider } from 'utils/providers';
import { useWeb3React } from '@web3-react/core';

const REFRESH_BLOCK_INTERVAL = 6000;
const FAST_INTERVAL = 5000;
const SLOW_INTERVAL = 60000;

export const usePollBlockNumber = () => {
  const { cache, mutate } = useSWRConfig();
  const { chainId } = useWeb3React();

  const { data: bscData } = useSWR(
    'bscBlockNumber',
    async () => {
      const blockNumber = await getSimpleRpcProvider(chainId).getBlockNumber();
      if (!cache.get('bscInitialBlockNumber')) {
        mutate('bscInitialBlockNumber', blockNumber);
      }
      return blockNumber;
    },
    {
      refreshInterval: REFRESH_BLOCK_INTERVAL,
    }
  );

  const { data: ethData } = useSWR(
    'ethBlockNumber',
    async () => {
      const blockNumber = await getSimpleRpcProvider(chainId).getBlockNumber();
      if (!cache.get('ethInitialBlockNumber')) {
        mutate('ethInitialBlockNumber', blockNumber);
      }
      return blockNumber;
    },
    {
      refreshInterval: REFRESH_BLOCK_INTERVAL,
    }
  );

  useSWR([FAST_INTERVAL, 'bscBlockNumber'], async () => bscData, {
    refreshInterval: FAST_INTERVAL,
  });

  useSWR([SLOW_INTERVAL, 'bscBlockNumber'], async () => bscData, {
    refreshInterval: SLOW_INTERVAL,
  });

  useSWR([FAST_INTERVAL, 'ethBlockNumber'], async () => ethData, {
    refreshInterval: FAST_INTERVAL,
  });

  useSWR([SLOW_INTERVAL, 'ethBlockNumber'], async () => ethData, {
    refreshInterval: SLOW_INTERVAL,
  });
};

export const useCurrentBlock = (): number => {
  const { chainId } = useWeb3React();
  const { data: bscCurrentBlock = 0 } = useSWRImmutable('bscBlockNumber');
  const { data: ethCurrentBlock = 0 } = useSWRImmutable('ethBlockNumber');
  if (!chainId) return 0;

  if (chainId === 97 || chainId === 56) return bscCurrentBlock;
  else return ethCurrentBlock;
};

export const useInitialBlock = (): number => {
  const { chainId } = useWeb3React();
  const { data: bscInitialBlock = 0 } = useSWRImmutable('bscInitialBlockNumber');
  const { data: ethInitialBlock = 0 } = useSWRImmutable('ethInitialBlockNumber');
  if (!chainId) return 0;

  if (chainId === 97 || chainId === 56) return bscInitialBlock;
  else return ethInitialBlock;
};
