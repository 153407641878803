import { Row, Col, Image } from "antd";
import React, { useState } from "react";
import Tabs from "./components/Tabs";
import faqImage from "assets/images/faq/faq-image.png";

import { Collapse } from "antd";

const { Panel } = Collapse;
interface FaqProps {}

const Faq: React.FunctionComponent<FaqProps> = (props) => {
  const [tabCurrent, setTabCurrent] = useState<number>(0);
  return (
    <>
      
    </>
  );
};

export default Faq;
