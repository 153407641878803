import { ERC20Token } from "entities/erc20token";
import CurrencyLogo from "./CurrencyLogo";
import React from "react";
import { useIsTokenActive, useIsUserAddedToken } from "hooks/Token";
import { CheckCircle, Settings } from "react-feather";

interface CardTokenProps {
  token: ERC20Token;
  showImportView: () => void;
  setImportToken: (token: ERC20Token) => void;
}

const CardToken: React.FunctionComponent<CardTokenProps> = ({
  token,
  showImportView,
  setImportToken,
}) => {

  // check if already active on list or local storage tokens
  const isAdded = useIsUserAddedToken(token);
  const isActive = useIsTokenActive(token);
  return (
    <>
      <div className="manage--single--token--import">
        <div className="manage--single--token--import__info">
          <CurrencyLogo currency={token} />
          <div className="manage--single--token--import__info__symbol">
            {token.symbol}
          </div>
          <div className="manage--single--token--import__info__name">
            {token.name}
          </div>
        </div>

        <div className="manage--single--token--import__btn">
          {!isActive && !isAdded ? (
            <button
              className="btn btn--import"
              onClick={() => {
                setImportToken && setImportToken(token);
                showImportView();
              }}
            >
              Import
            </button>
          ) : (
            <div>
              <CheckCircle size={16} />
              <span>Active</span>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default CardToken;
