import React from "react";
import Marquee from "react-fast-marquee";

interface BlogsWrapperProps {}

const BlogsWrapper: React.FunctionComponent<BlogsWrapperProps> = ({
  children,
}) => {
  return (
    <>
      <div className="blogs--wrapper">
        <div className="blogs--marquee">
          <Marquee speed={30} gradientColor={[130, 128, 147]}>
            Mr.Bao? Mom~ 🫵 Mr.Bao? Mom~ 🫵 Mr.Bao? Mom~
          </Marquee>
        </div>

        <div className="blogs">{children}</div>
      </div>
    </>
  );
};

export default BlogsWrapper;
