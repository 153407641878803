import { Col, Row } from "antd";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import React from "react";
import useNotification from "hooks/useNotification";
import { useAddPopup } from "state/application/hook";

interface SubscribeProps { }

const Subscribe: React.FunctionComponent<SubscribeProps> = (props) => {
  const schema = yup.object({
    email: yup.string().required().email("Email is invalid")
  }).required();
  const { register, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(schema)
  });
  const notification = useNotification();

  const onSubmit = () => {
    notification({
      kind: "success",
      message: "Subscribe success"
    })
  }

  return (
    <>
      <section className="subscribe">
        <Row gutter={[12, 48]}>
          <Col span={24} className="subscribe--title">
          Eliminate unexpected transaction fees. <br></br>Just the Best in Line
          </Col>
        </Row>
      </section>
    </>
  );
};

export default Subscribe;
