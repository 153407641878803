import { CurrencyAmount, TokenAmount, Trade } from "../entities";
import { useWeb3React } from "@web3-react/core";
import { useCallWithGasPrice } from "./useCallWithGasPrice";
import { useTokenAllowance } from "./useTokenAllowance";
import { useMemo, useCallback } from "react";
import { computeSlippageAdjustedAmounts } from "../utils/prices";
import { Field } from "state/swap/types";
import { ROUTER_ADDRESS } from "../utils/contract";
import { useTokenContract } from "hooks/useContract";
import { MaxUint256 } from "@ethersproject/constants";
import { calculateGasMargin } from "./useSwapCallback";

export enum ApprovalState {
  UNKNOWN,
  NOT_APPROVED,
  PENDING,
  APPROVED,
}

export function useApproveCallback(
  amountToApprove?: CurrencyAmount,
  spender?: string
): [ApprovalState, () => Promise<void>] {
  const { account } = useWeb3React();
  const { callWithGasPrice } = useCallWithGasPrice();
  const token =
    amountToApprove instanceof TokenAmount ? amountToApprove.token : undefined;
  const currentAllowance = useTokenAllowance(
    token,
    account ?? undefined,
    spender
  );

  const approvalState = useMemo(() => {
    if (!amountToApprove || !spender) return ApprovalState.UNKNOWN;
    if (
      amountToApprove?.currency.symbol === "BNB" ||
      amountToApprove?.currency.symbol === "WBNB"
    )
      return ApprovalState.APPROVED;
    if (!currentAllowance) return ApprovalState.UNKNOWN;

    return currentAllowance.lessThan(amountToApprove)
      ? ApprovalState.NOT_APPROVED
      : ApprovalState.APPROVED;
  }, [currentAllowance, amountToApprove, spender]);

  const tokenContract = useTokenContract(token?.address);

  const approve = useCallback(async () => {
    if (approvalState !== ApprovalState.NOT_APPROVED) {
      console.error("Approve was called unnecessarily");
      return;
    }

    if (!token) {
      console.error("No token");
      return;
    }

    if (!tokenContract) {
      console.error("TokenContract is null");
      return;
    }

    if (!amountToApprove) {
      console.error("Missing amount to approve");
      return;
    }

    if (!spender) {
      console.error("No spender");
      return;
    }

    let useExact = false;

    const estimatedGas = await tokenContract.estimateGas
      .approve(spender, MaxUint256)
      .catch(() => {
        // general fallback for tokens who restrict approval amounts
        useExact = true;
        return tokenContract.estimateGas.approve(
          spender,
          amountToApprove.raw.toString()
        );
      });

    return callWithGasPrice(
      tokenContract,
      "approve",
      [spender, useExact ? amountToApprove.raw.toString() : MaxUint256],
      {
        gasLimit: calculateGasMargin(estimatedGas),
      }
    )
      .then((response: any) => {
      })
      .catch((error: any) => {
        console.error("Failed to approve token", error);
        if (error?.code !== 4001) {
          console.error("Failed to app");
        }
        throw error;
      });
  }, [
    amountToApprove,
    approvalState,
    callWithGasPrice,
    spender,
    token,
    tokenContract,
  ]);

  return [approvalState, approve];
}

export function useApproveCallbackFromTrade(
  trade?: Trade,
  allowedSlippage = 0
) {
  const { chainId } = useWeb3React();
  const amountToApprove = useMemo(
    () =>
      trade
        ? computeSlippageAdjustedAmounts(trade, allowedSlippage)[Field.INPUT]
        : undefined,
    [trade, allowedSlippage]
  );

  return useApproveCallback(
    amountToApprove,
    ROUTER_ADDRESS[(chainId as never) || 56]
  );
}
