import React from "react";
import Lottie from "lottie-react";
import Animation from "../../../config/Full Cube_Lottie Delivery 3 [D] (Final Pick).json";

interface ConfirmPendingContentProps {
  pendingText: string;
}

const ConfirmPendingContent: React.FunctionComponent<
  ConfirmPendingContentProps
> = ({ pendingText }) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: Animation,
    // rendererSettings: {
    //   preserveAspectRatio: "xMidYMid slice",
    // },
  };
  return (
    <>
      <div className="modal--swap--confirm--waiting">
        <Lottie
          animationData={Animation}
          style={{ width: "100%" }}
          className="my-custom"
        />
      </div>
      <div className="modal--swap--confirm--waiting__text">
        <div>Waiting For Confirmation</div>
        <div>{pendingText}</div>
        <div>Confirm this transaction in your wallet</div>
      </div>
    </>
  );
};

export default ConfirmPendingContent;
