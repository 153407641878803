import { nanoid } from "@reduxjs/toolkit";
import { TokenList } from "@uniswap/token-lists";
import { useAppDispatch } from "app/hooks";
import { getNetworkLibrary } from "connectors";
import { useCallback } from "react";
import { fetchTokenList } from "state/lists/hooks";
import getTokenList from "utils/getTokenList";
import resolveENSContentHash from "utils/resolveENSContentHash";
import { useActiveWeb3React } from "./useActiveWeb3React";

export function useFetchListCallback(): (
  listUrl: string,
  sendDispatch?: boolean
) => Promise<any> {
  const { chainId, library } = useActiveWeb3React();
  const dispatch = useAppDispatch();

  const ensResolver = useCallback(
    async (ensName: string) => {
      if (!library || chainId !== 1) {
        const networkLibrary = getNetworkLibrary();
        const network = await networkLibrary.getNetwork();
        if (networkLibrary && network.chainId === 1) {
          return resolveENSContentHash(ensName, networkLibrary);
        }
        throw new Error("Could not construct mainnet ENS resolver");
      }
      return resolveENSContentHash(ensName, library);
    },
    [chainId, library]
  );

  // note: prevent dispatch if using for list search or unsupported list
  return useCallback(
    async (listUrl: string, sendDispatch = true) => {

      const requestId = nanoid();
      sendDispatch &&
        dispatch(fetchTokenList.pending({ requestId, url: listUrl }));
      return getTokenList(listUrl, ensResolver)
        .then((tokenList: TokenList) => {
          sendDispatch &&
            dispatch(
              fetchTokenList.fulfilled({ url: listUrl, tokenList, requestId })
            );
          return tokenList;
        })
        .catch((error: Error) => {
          sendDispatch &&
            dispatch(
              fetchTokenList.rejected({
                url: listUrl,
                requestId,
                errorMessage: error.message,
              })
            );
          throw error;
        });
    },
    [dispatch, ensResolver]
  );
}
