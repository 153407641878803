import { DownOutlined } from "@ant-design/icons";
import { useWeb3React } from "@web3-react/core";
import useModalToken from "hooks/useModalToken";
import React, {
  ChangeEvent,
  useEffect,
  useState,
} from "react";
import { useDispatch } from "react-redux";
import { useSwapState } from "state/swap/swapHook";
import { selectCurrency } from "state/swap/swapSlice";
import { Field } from "state/swap/types";
import ArrowDown from "assets/icons/ArrowDown";
import SwapIcon from "assets/icons/SwapIcon";
import LoadingPlaceholder from "../../../../components/UIElements/LoadingPlaceholder";
import CurrencyLogo from "features/manageLists/components/CurrencyLogo";
import { Currency } from "entities/currency";
import LoadingSpinner from "../../../../components/UIElements/LoadingSpinner";

interface CurrencyInputProps {
  quickSelect?: boolean;
  className?: string;
  balance?: any;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  value: string;
  field: Field;
  onMax?: any;
  btnSwitch?: boolean;
  reverse?: () => void;
  currency?: Currency | null;
}

const CurrencyInput: React.FunctionComponent<CurrencyInputProps> = ({
  quickSelect,
  className,
  field,
  balance = 0,
  onChange,
  value,
  onMax,
  btnSwitch,
  reverse,
  currency,
}) => {
  const useManageTokenModal = useModalToken("modal--manage--token");
  const { chainId } = useWeb3React();
  // const tokens = getAllNativeCurrenciesAndTokens(chainId);
  const dispatch = useDispatch();
  const {
    [Field.INPUT]: { currencyId: inputCurrencyId },
    [Field.OUTPUT]: { currencyId: outputCurrencyId },
  } = useSwapState();

  // listen change tokens
  useEffect(() => {
    const currencyId = !useManageTokenModal.currentToken
      ? ""
      : useManageTokenModal.currentToken.address
      ? useManageTokenModal.currentToken.address
      : useManageTokenModal.currentToken.symbol;

    dispatch(
      selectCurrency({
        field,
        currencyId,
      })
    );
  }, [useManageTokenModal.currentToken, dispatch, field]);

  const onClickMax = () => {
    if (!onMax) return;
    onMax(field);
  };
  const [isSwitch, setIsSwitchIcon] = useState<boolean>(false);
  // const waiting = useAppSelector((state) => state.loading.isLoading);

  return (
    <>
      <div className={`card__tab ${className}`}>
        {/* <div className="d-flex-row items-between">
          {quickSelect ? (
            waiting ? (
              <LoadingCard />
            ) : (
              <>
                <div className="swap--balance">
                  Available: <span className="swap--available">{balance}</span>
                </div>
                <div className="swap--action">
                  <button className="btn btn--ghost" onClick={onClickMax}>
                    MAX
                  </button>
                </div>
              </>
            )
          ) : (
            ""
          )}
        </div> */}

        {field === Field.INPUT && (
          <>
           <div className="d-flex-row items-between">
            <div className="d-flex-row items-center gap-10">
              {/* avatar */}
              <div className="currency--input--logo">
                {currency && <CurrencyLogo currency={currency} />}
              </div>
              {/* select network */}
              <div className="d-flex-col">
                <div
                  className="d-flex-row items-center gap-5"
                  style={{ cursor: "pointer" }}
                  onClick={() => useManageTokenModal.showModal()}
                >
                  <div className="text-currency">
                    {currency ? currency.symbol : <LoadingSpinner />}
                  </div>
                  <DownOutlined
                    style={{ color: "#958fc0", cursor: "pointer" }}
                  />
                </div>
                {/* <div className="text-network">
                  {currency ? currency.name : <LoadingSpinner />}
                </div> */}
                
              </div>
            </div>
            <div className="d-flex-col">
              {
                <div>
                  <input
                    className="swap--input"
                    placeholder="0.0"
                    type="text"
                    onChange={onChange}
                    value={value}
                  />
                </div>
              }
            </div>
          </div>
          <div className="swap--balance">
          Available: <span className="swap--available" onClick={onClickMax}>{balance}</span>
        </div>
          </>
         
        
        )}
        
        {field === Field.OUTPUT ? (
          currency ? (
            <div className="d-flex-row items-between">
              <div className="d-flex-row items-center gap-10">
                {/* avatar */}
                <div className="currency--input--logo">
                  {currency && <CurrencyLogo currency={currency} />}
                </div>
                {/* select network */}
                <div className="d-flex-col">
                  <div
                    className="d-flex-row items-center gap-5"
                    style={{ cursor: "pointer" }}
                    onClick={() => useManageTokenModal.showModal()}
                  >
                    <div className="text-currency">
                      {currency ? currency.symbol : <LoadingSpinner />}
                    </div>
                    <DownOutlined
                      style={{ color: "#958fc0", cursor: "pointer" }}
                    />
                  </div>
                  {/* <div className="text-network">
                    {currency ? currency.name : <LoadingSpinner />}
                  </div> */}
                </div>
              </div>
              <div className="d-flex-col">
                {field === Field.OUTPUT && (
                  <div className="swap--compare__amount">
                    {value || "0"}
                  </div>
                )}
              </div>
            </div>
          ) : (
            <LoadingPlaceholder
              extraStyles={{
                width: "100%",
                height: "45px",
                borderRadius: "10px",
              }}
            />
          )
        ) : (
          ""
        )}

        {btnSwitch && (
          <button
            className="btn btn--swap"
            onMouseOver={() => setIsSwitchIcon && setIsSwitchIcon(true)}
            onMouseLeave={() => setIsSwitchIcon && setIsSwitchIcon(false)}
            onClick={reverse}
          >
            {isSwitch ? <SwapIcon /> : <ArrowDown />}
          </button>
        )}
      </div>
      {useManageTokenModal.modal}
    </>
  );
};

export default CurrencyInput;
