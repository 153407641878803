import { Token } from "entities/token";
import { useMemo } from "react";
import { ERC20Token } from "../../../entities/erc20token";
import { TokenInfo } from "@uniswap/token-lists";
import { isAddress } from "utils/addressHelpers";
import { Currency } from "@ape.swap/sdk";

export const useSortedTokensByQuery = (
  tokens: ERC20Token[] | undefined,
  searchQuery: string
): ERC20Token[] => {
  return useMemo(() => {
    if (!tokens) {
      return [];
    }

    const symbolMatch = searchQuery
      .toLowerCase()
      .split(/\s+/)
      .filter((s) => s.length > 0);

    if (symbolMatch.length > 1) {
      return tokens;
    }

    const exactMatches: ERC20Token[] = [];
    const symbolSubtrings: ERC20Token[] = [];
    const rest: ERC20Token[] = [];

    // sort tokens by exact match -> subtring on symbol match -> rest
    tokens.map((token) => {
      if (token.symbol?.toLowerCase() === symbolMatch[0]) {
        return exactMatches.push(token);
      } else if (
        token.symbol?.toLowerCase().startsWith(searchQuery.toLowerCase().trim())
      ) {
        return symbolSubtrings.push(token);
      } else {
        return rest.push(token);
      }
    });

    return [...exactMatches, ...symbolSubtrings, ...rest];
  }, [tokens, searchQuery]);
};

const alwaysTrue = () => true;

export function createTokenFilterFunction<T extends Token | TokenInfo>(
  search: string
): (tokens: T) => boolean {
  const searchingAddress = isAddress(search);

  if (searchingAddress) {
    const lower = searchingAddress.toLowerCase();
    return (t: T | Currency) =>
      "address" in t && lower === t.address?.toLowerCase();
  }

  const lowerSearchParts = search
    .toLowerCase()
    .split(/\s+/)
    .filter((s) => s.length > 0);

  if (lowerSearchParts.length === 0) return alwaysTrue;

  const matchesSearch = (s: string): boolean => {
    const sParts = s
      .toLowerCase()
      .split(/\s+/)
      .filter((s) => s.length > 0);

    return lowerSearchParts.every(
      (p) => p.length === 0 || sParts.some((sp) => sp.includes(p))
    );
  };

  return ({ name, symbol }: T): boolean =>
    Boolean((symbol && matchesSearch(symbol)) || (name && matchesSearch(name)));
}
