import { Image, Modal } from "antd";
import {
  useCloseModals,
  useModalOpen,
  useNetworkSwitcherToggle,
  useUnsupportedChainIdError,
} from "hooks";
import { useNetworkSwitch } from "hooks/useNetworkSwitch";
import { ApplicationModal } from "models/application";
import {
  createNetworksList,
  NetworkOptions,
  NetworksList,
} from "utils/networkList";
import {
  networkOptionsPreset,
  NetworkSwitcherTags,
} from "../../constants/networkSwitch";

import React, { useEffect } from "react";
import { ChainId } from "constants/chainIds";
import { useActiveWeb3React } from "hooks/useActiveWeb3React";
import ConnectedIcon from "assets/images/connected.svg";

interface NetworkModalProps {}

const NetworkModal: React.FunctionComponent<NetworkModalProps> = (props) => {
  const networkModalOpen = useModalOpen(ApplicationModal.NETWORK_SWITCHER);
  const closeModals = useCloseModals();
  const { connector, chainId: activeChainId, account } = useActiveWeb3React();

  const unsupportedChainIdError = useUnsupportedChainIdError();

  const toggleNetworkSwitcher = useNetworkSwitcherToggle();

  const handleOk = () => {
    toggleNetworkSwitcher();
  };

  const handleCancel = () => {
    toggleNetworkSwitcher();
  };

  const getExtensionNetwork = () => {};

  const { selectNetwork } = useNetworkSwitch({
    onSelectNetworkCallback: closeModals,
  });

  const isNetworkDisabled = (chainId: ChainId) => {
    return (
      connector?.supportedChainIds?.indexOf(chainId) === -1 ||
      (!unsupportedChainIdError && activeChainId === chainId)
    );
  };

  const networkList = createNetworksList({
    networkOptionsPreset,
    onNetworkChange: selectNetwork,
    isNetworkDisabled,
    selectedNetworkChainId: activeChainId ? activeChainId : -1,
    activeChainId: account ? activeChainId : -1,
    ignoreTags: [],
    showTestnets: true,
  });

  return (
    <>
      <Modal
        className="modal--network--switch"
        open={networkModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        destroyOnClose={true}
        closable={true}
        title={"Switch a network"}
      >
        <NetworkItem networkList={networkList} />
      </Modal>
    </>
  );
};

interface NetworkProps {
  networkList: NetworksList[];
}

const NetworkItem: React.FunctionComponent<NetworkProps> = ({
  networkList,
}) => {
  const { account, chainId } = useActiveWeb3React();

  return (
    <>
      <div className="modal--network--items">
        {networkList.map((network, index) => {
          return (
            <div key={`netword-${index}`}>
              <div className="modal--network--items__tag">
                {network.tag?.toUpperCase()}
              </div>
              {network.networks.map((props, index) => {
                return (
                  <Option
                    key={`network-select-${index}`}
                    {...props}
                    connected={!!account && chainId === props.preset.chainId}
                  />
                );
              })}
            </div>
          );
        })}
      </div>
    </>
  );
};

interface OptionProps {}

const Option: React.FunctionComponent<NetworkOptions> = ({
  preset,
  onClick,
  active = false,
  disabled = false,
  connected = false,
}) => {
  const { logoSrc, name } = preset;

  return (
    <>
      <div
        className={`modal--network--item ${disabled && "disabled"}`}
        onClick={onClick}
      >
        <div className="modal--network--item__info">
          <Image src={logoSrc} width={24} height={24} preview={false} />
          <div>{name}</div>
        </div>
        <div className="modal--network--item__status">
          {connected && (
            <Image src={ConnectedIcon} width={24} height={24} preview={false} />
          )}
          {disabled && !connected && "Coming soon"}
        </div>
      </div>
    </>
  );
};
export default NetworkModal;
