import { ChainId } from "@uniswap/smart-order-router";
import mainnetTokens from "../tokens/mainnet.json";
import ropstenTokens from "../tokens/ropsten.json";
import rinkebyTokens from "../tokens/rinkeby.json";
import goerliTokens from "../tokens/goerli.json";
import kovanTokens from "../tokens/kovan.json";
import polygonTokens from "../tokens/polygon.json";
import mumbaiTokens from "../tokens/mumbai.json";
import { useWeb3React } from "@web3-react/core";
import TOKENS from "config/constants/tokens";
import { Token } from "entities/token";
import { Currency } from "entities/currency";

export interface MyToken {
  name: string;
  address: string;
  symbol: string;
  decimals: number;
  chainId: number;
  logoURI: string;
}

export const useToken = () => {
  const getTokensByChain = (chainId: ChainId): MyToken[] => {
    switch (chainId) {
      case ChainId.MAINNET:
        return mainnetTokens;
      case ChainId.ROPSTEN:
        return ropstenTokens;
      case ChainId.RINKEBY:
        return rinkebyTokens;
      case ChainId.GÖRLI:
        return goerliTokens;
      case ChainId.KOVAN:
        return kovanTokens;
      case ChainId.POLYGON:
        return polygonTokens;
      case ChainId.POLYGON_MUMBAI:
        return mumbaiTokens;
      default:
        return mainnetTokens;
    }
  };

  return { getTokensByChain };
};

export function useTokens() {
  const { chainId = 0 } = useWeb3React();
  return TOKENS[chainId].map(
    ({ chainId, address, decimals, image, name, symbol }) =>
      new Token(chainId, address, decimals, symbol, name, image)
  );
}

export function getAllNativeCurrenciesAndTokens(chainId = 0) {
  if (chainId === 0) return [Currency.BNB].concat(TOKENS[chainId]);

  const tokens = TOKENS[chainId];
  return chainId === 97 || chainId === 56
    ? [Currency.BNB].concat(tokens)
    : [Currency.ETH].concat(tokens);
}
