import React, { ReactNode } from "react";
import { CurrencySearchModalContext } from "../context/CurrencySearchModalContext";
import { ListRowContextType } from "../../../models/manageList/ListRowContextType";
import { ManageListsContextType } from "../../../models/manageList/ManageListsContextType";
import { CurrencySearchContextType } from "../../../models/currencySearch/CurrencySearchContextType";
import { CurrencySearchModalContextType } from "../../../models/currencySearchModal/CurrencySearchModalContextType";
import { CurrencySearchContext } from "../../CurrencySearch/context/CurrencySearchContext";
import {
  ManageListsContext,
  ListRowContext,
} from "../../../features/manageLists/context/ManageListContext";

interface CurrencySearchModalProviderProps {
  listRowContext: ListRowContextType;
  manageListsContext: ManageListsContextType;
  currencySearchContext: CurrencySearchContextType;
  currencySearchModalContext: CurrencySearchModalContextType;
  children: ReactNode;
}

const CurrencySearchModalProvider: React.FunctionComponent<
  CurrencySearchModalProviderProps
> = ({
  children,
  listRowContext,
  manageListsContext,
  currencySearchContext,
  currencySearchModalContext,
}) => {
  return (
    <>
      <CurrencySearchModalContext.Provider value={currencySearchModalContext}>
        <CurrencySearchContext.Provider value={currencySearchContext}>
          <ManageListsContext.Provider value={manageListsContext}>
            <ListRowContext.Provider value={listRowContext}>
              {children}
            </ListRowContext.Provider>
          </ManageListsContext.Provider>
        </CurrencySearchContext.Provider>
      </CurrencySearchModalContext.Provider>
    </>
  );
};

export default CurrencySearchModalProvider;
