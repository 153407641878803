import React from "react";
import ChervonDown from "../../../assets/icons/ChervonDown";

interface ImportListHeaderProps {
  showManageView: () => void;
}

const ImportListHeader: React.FunctionComponent<ImportListHeaderProps> = ({
  showManageView,
}) => {
  return (
    <div className="manage--tokens--header">
      <ChervonDown onClick={showManageView} />
      <div className="manage--tokens--header__title">Import unknown list</div>
    </div>
  );
};

export default ImportListHeader;
