import React from "react";
import { Col, Image, Row, Select } from "antd";
import fud from "assets/images/fud.png";
interface BlogCardProps {}

const BlogCard: React.FunctionComponent<BlogCardProps> = (props) => {
  return (
    <>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="blog--card">
        <Col xs={2} sm={2} md={2} lg={2} xl={2} xxl={2}>
          08:25
        </Col>
        <Col xs={4} sm={4} md={6} lg={6} xl={6} xxl={6}>
          <Image
            preview={false}
            src="https://uploads-ssl.webflow.com/5fad86e2327507cecea2d5e8/633ce75da42ba3f8adc972b6_Are_Validators_Becoming_Increasingly_Frustrated_With_Binance_Smart-p-1080_0566078b33cc0f1e250354fb6dae8d24_2000.jpeg"
          />
        </Col>
        <Col xs={18} sm={18} md={16} lg={16} xl={16} xxl={16}>
          <Row gutter={[8, 4]}>
            <Col span={24}>
              <Image preview={false} src={fud} />
              <span className="blog--content__subject">Fact/FUD</span>
            </Col>
            <Col span={24} className="blog--content__title">
              Extreme Weather in North America Affects Binance’s Cloud Miners,
              Subscriptions Extended
            </Col>
            <Col span={24} className="blog--content__sumary">
              The historic weather storm is affecting users across the world
              with the outage.
            </Col>
            <Col className="blog--content__tag">BSCnews</Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default BlogCard;
