import { createAsyncThunk } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import apiClient from './api/apiClient';
import { redirectAccessForbidden, redirectLogin } from './commonSlice';
import { addLoading, removeLoading } from './loadingSlice';

interface CustomCreateAsyncThunk {
  createAsyncThunkWithLoading?:any,
  createAsyncThunkForPagination?:any
}

export const customCreateAsyncThunk:CustomCreateAsyncThunk = {};
const formatUrl = (url: any, additionalData: any) => {
  if (!additionalData) return url;
  let finalUrl = url;
  if (additionalData.pathVariables) {
    Object.keys(additionalData.pathVariables).forEach((key) => {
      if (additionalData.pathVariables[key] !== null && additionalData.pathVariables[key] !== undefined) {
        finalUrl = finalUrl.replace(key, additionalData.pathVariables[key]);
      }
    });
  }
  if (additionalData.params) {
    const keys = Object.keys(additionalData.params);
    let addedFirstParam = false;
    for (let i = 0; i < keys.length; i++) {
      if (additionalData.params[keys[i]] !== null && additionalData.params[keys[i]] !== undefined) {
        finalUrl += `${!addedFirstParam ? '?' : '&'}${keys[i]}=${encodeURIComponent(additionalData.params[keys[i]])}`;
        if (!addedFirstParam) {
          addedFirstParam = true;
        }
      }
    }
  }
  return finalUrl;
};

customCreateAsyncThunk.createAsyncThunkWithLoading = (type: any, method: any, url: any) => createAsyncThunk(
  type,
  async (additionalData:any, thunkAPI) => {
    const finalUrl = formatUrl(url, additionalData);
    if (!additionalData?.hideLoading) thunkAPI.dispatch(addLoading());
    let response;
    const header = additionalData?.isFormData ? apiClient.headerFormData() : null;
    switch (method) {
      case 'GET':
        response = await apiClient.getMethod(finalUrl, header);
        break;
      case 'POST':
        response = await apiClient.postMethod(finalUrl, additionalData?.body || {}, header);
        break;
      case 'PATCH':
        response = await apiClient.patchMethod(finalUrl, additionalData?.body || {}, header);
        break;
      case 'PUT':
        response = await apiClient.putMethod(finalUrl, additionalData?.body || {}, header);
        break;
      case 'DELETE':
        response = await apiClient.deleteMethod(finalUrl, additionalData?.body || {}, header);
        break;
      default:
        if (!additionalData?.hideLoading) thunkAPI.dispatch(removeLoading());
        throw new Error('Unknown method.');
    }
    if (response.status === 403) {
      thunkAPI.dispatch(redirectAccessForbidden());
    }
    if (response.status === 401 && type !== 'auth/login') {
      // const { auth } = thunkAPI.getState();
      // if (!auth.forceLogout) {
      // }
      // if (!additionalData?.hideLoading) thunkAPI.dispatch(removeLoading());
      throw new Error('Unauthorized');
    }
    if (response.status !== 200 && response.status !== 204) {
      if (!additionalData?.hideLoading) thunkAPI.dispatch(removeLoading());
      if (additionalData && additionalData.id) {
        return thunkAPI.rejectWithValue({
          id: additionalData.id,
          response: response.data,
        });
      }
      return thunkAPI.rejectWithValue(response.data);
    }
    if (!additionalData?.hideLoading) thunkAPI.dispatch(removeLoading());
    if (additionalData && additionalData.id) {
      return {
        id: additionalData.id,
        response: response.data,
      };
    }
    return response.data;
  },
);

const buildSearchUrl = (url: any, filter: any) => {
  if (!filter) return url;
  let searchUrl = url;
  const keys = Object.keys(filter).filter(x => x !== 'hideLoading');
  let addedFirstParam = false;
  for (let i = 0; i < keys.length; i++) {
    if (filter[keys[i]] !== null && filter[keys[i]] !== undefined) {
      searchUrl += `${!addedFirstParam ? '?' : '&'}${keys[i]}=${encodeURIComponent(filter[keys[i]])}`;
      if (!addedFirstParam) {
        addedFirstParam = true;
      }
    }
  }
  return searchUrl;
};

customCreateAsyncThunk.createAsyncThunkForPagination = (type: any, url: any) => createAsyncThunk(
  type,
  async (filter, thunkAPI) => {
    thunkAPI.dispatch(addLoading());
    // const header = apiClient.headerData();
    const response = await apiClient.getMethod(buildSearchUrl(url, filter));
    if (response.status === 403) {
      thunkAPI.dispatch(redirectAccessForbidden());
    }
    if (response.status === 401) {
      thunkAPI.dispatch(redirectLogin())
      throw new Error('Unauthorized');
    }
    if (response.status !== 200) {
      thunkAPI.dispatch(removeLoading());
      return thunkAPI.rejectWithValue(response.data);
    }
    thunkAPI.dispatch(removeLoading());
    return {
      // pagination: parseLink(response.headers.link),
      list: response.data,
      total: Number(response.headers['x-total-count']),
    };
  },
); 