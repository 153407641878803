import React from "react";
// import CountdownTimer from "react-component-countdown-timer";
import {
  FaBitcoin,
  FaCcMastercard,
  FaCcVisa,
  FaCcDiscover,
} from "react-icons/fa";
interface CoinFundProps {}
const settings = {
  count: 5432560,
  showTitle: true,
  size: 60,
  labelSize: 14,
  backgroundColor: "transparent",
  color: "#fff",
  dayTitle: "Days",
  hourTitle: "Hours",
  minuteTitle: "Minutes",
  secondTitle: "Seconds",
  id: "countdownwrap",
};
const CoinFund: React.FunctionComponent<CoinFundProps> = (props) => {
  return (
    <>
      <section className="coinFund--wrapper">
        <div className="coinFund--info">
          <div className="coinFund--info__title">
            <p>Business Core</p>
          </div>
          <div className="coinFund--info__sub">
            <p>
            At our core, we are driven by a relentless focus on building the best service possible, ensuring an equitable and fair playing field for all participants. We understand the importance of creating an environment that fosters equal opportunities and promotes transparency. With this commitment in mind, we tirelessly work to provide innovative solutions, cutting-edge features, and robust infrastructure that empower all players to thrive and succeed.
            </p>
          </div>
          <div className="coinFund--document">
            <a href="https://docs.dexwonder.com/" target="_blank"><button className="btn"> White Paper</button></a>
          </div>
        </div>
      </section>
    </>
  );
};

export default CoinFund;
