import { Col, Image, Row } from "antd";
import React from "react";
import githubLogo from "assets/images/languages/github.png";
import javascriptLogo from "assets/images/languages/javascript.png";
import pythonLogo from "assets/images/languages/python.png";
import reactLogo from "assets/images/languages/react.png";
import rubyLogo from "assets/images/languages/ruby.png";

interface StackProps {}

const Stack: React.FunctionComponent<StackProps> = (props) => {
  return (
    <>
    </>
  );
};

export default Stack;
