import { ChainId } from "constants/chainIds";
import { CustomNetworkConnector } from "./CustomNetworkConnector";
import { providers } from "ethers";
import getLibrary2 from "../utils/getLibrary";

export const INFURA_PROJECT_ID = "0ebf4dd05d6740f482938b8a80860d13";

export const network = new CustomNetworkConnector({
  urls: {
    [ChainId.MAINNET]: `https://mainnet.infura.io/v3/${INFURA_PROJECT_ID}`,
    [ChainId.BNB]: "https://bsc-dataseed.binance.org/",
    [ChainId.tBNB]: "https://data-seed-prebsc-1-s1.binance.org:8545/",
  },
  defaultChainId: ChainId.BNB,
});

let networkLibrary: providers.Web3Provider | undefined;
export function getNetworkLibrary(): providers.Web3Provider {
  return (networkLibrary = networkLibrary ?? getLibrary2(network.provider));
}
