import axios from 'axios';

const defaultParams = () => ({
  headers: {},
});

interface ApiClient{
  headerFormData?:any,
  deleteMethod?:any,
  getMethod?:any,
  patchMethod?:any,
  postMethod?:any,
  putMethod?:any
}

const apiClient:ApiClient = {};

apiClient.headerFormData = () => ({
  headers: {
    'Content-Type': 'multipart/form-data',
  },
});

const api = axios.create({
  baseURL: process.env.REACT_APP_GATEWAY_URL,
  withCredentials: true,
});

apiClient.deleteMethod = async (url:any, params:any, header:any) => {
  try {
    const response = await api.delete(url, { ...(header || defaultParams()), ...params });
    return response;
  } catch (e:any) {
    return e.response;
  }
};

apiClient.getMethod = async (url:any, params:any) => {
  try {
    const response = await api.get(url, { ...defaultParams(), ...params });
    return response;
  } catch (e:any) {
    return e.response;
  }
};

apiClient.patchMethod = async (url:any, body:any, params:any, header:any) => {
  try {
    const response = await api.patch(url, body, { ...(header || defaultParams()), ...params });
    return response;
  } catch (e:any) {
    return e.response;
  }
};

apiClient.postMethod = async (url:any, body:any, params:any, header:any) => {
  try {
    const response = await api.post(url, body, { ...(header || defaultParams()), ...params });
    return response;
  } catch (e:any) {
    return e.response;
  }
};

apiClient.putMethod = async (url:any, body:any, params:any, header:any) => {
  try {
    const response = await api.put(url, body, { ...(header || defaultParams()), ...params });
    return response;
  } catch (e:any) {
    return e.response;
  }
};

export default apiClient;
