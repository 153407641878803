import React from "react";

interface ButtonCheckCircleProps {
  isActive: boolean;
  toggle: () => void;
}

const ButtonCheckCircle: React.FunctionComponent<ButtonCheckCircleProps> = ({
  isActive,
  toggle,
}) => {
  return (
    <>
      <div className="btn--check--circle">
        <label className="switch">
          <input type="checkbox" checked={isActive} onClick={toggle} />
          <span className="slider round"></span>
        </label>
      </div>
    </>
  );
};

export default ButtonCheckCircle;
