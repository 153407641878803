import { ChainId } from "constants/chainIds";
import { SYMBOLS } from "constants/lists";
import { useMemo, useState } from "react";
import { CMC_PRO_API_KEY, CMC_URL } from "../constants/index";

interface QuoteProps {
  input: string;
  output: string;
}
// const IDS_MAPPING = {};

const IDS_MAPPING: { [a: string]: string } = {
  ["BTC"]: "bitcoin",
  ["ETH"]: "ethereum",
  ["USDT"]: "tether",
  ["BNB"]: "binancecoin",
  ["ALPACA"]: "alpaca",
  ["XVS"]: "venus",
  ["WBNB"]: "wbnb",
  ["bunny"]: "rocket-bunny",
  ["BUSD"]: "binance-usd",
};
interface TokenInfo {
  ath: number | null;
  ath_change_percentage: number | null;
  ath_date: string | "";
  atl: number | null;
  atl_change_percentage: number | null;
  atl_date: string | "";
  circulating_supply: number | null;
  current_price: number | null;
  fully_diluted_valuation: number | null;
  high_24h: number | null;
  id: string | "";
  image: string | "";
  last_updated: string | "";
  low_24h: number | null;
  market_cap: number | null;
  market_cap_change_24h: number | null;
  market_cap_change_percentage_24h: number | null;
  market_cap_rank: number | null;
  max_supply: number | null;
  name: string | "";
  price_change_24h: number | null;
  price_change_percentage_24h: number | null;
  roi: null;
  symbol: string | "";
  total_supply: number | null;
  total_volume: number | null;
}

const initialState = {
  ath: null,
  ath_change_percentage: null,
  ath_date: "",
  atl: 67.81,
  atl_change_percentage: 34039.67182,
  atl_date: "",
  circulating_supply: null,
  current_price: null,
  fully_diluted_valuation: null,
  high_24h: null,
  id: "",
  image: "",
  last_updated: "",
  low_24h: null,
  market_cap: null,
  market_cap_change_24h: null,
  market_cap_change_percentage_24h: null,
  market_cap_rank: null,
  max_supply: null,
  name: "",
  price_change_24h: null,
  price_change_percentage_24h: null,
  roi: null,
  symbol: "",
  total_supply: null,
  total_volume: null,
};

export const useGetInfoCoin = (input: string, output: string) => {
  const [tokenInfoInput, setTokenInput] = useState<TokenInfo[]>();
  const [tokenInfoOutput, seTokenInfoOutput] = useState<TokenInfo[]>();

  useMemo(() => {
    (async () => {
      return await fetch(`https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&ids=${IDS_MAPPING[input]}&order=market_cap_desc&per_page=100&page=1&sparkline=false
      `);
    })().then((res) => {
      const result = res.clone();
      result.json().then((data) => {
        setTokenInput(data);
      });
    });
  }, [input]);

  useMemo(() => {
    (async () => {
      return await fetch(`https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&ids=${IDS_MAPPING[output]}&order=market_cap_desc&per_page=100&page=1&sparkline=false
      `);
    })().then((res) => {
      const result = res.clone();
      result.json().then((data) => {
        seTokenInfoOutput(data);
      });
    });
  }, [output]);

  return {
    tokenInfoInput,
    tokenInfoOutput,
  };
};
