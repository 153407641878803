import { Divider, Layout } from "antd";
import { Content } from "antd/lib/layout/layout";
import { MyHeader } from "components";
import React from "react";
import { Outlet } from "react-router-dom";

interface ProductLayoutProps {}

const ProductLayout: React.FunctionComponent<ProductLayoutProps> = (props) => {
  return (
    <>
      <Layout>
        <MyHeader />
        <Content
          style={{ 
            padding: "10px 50px 10px 50px", 
            position: "relative", 
            height: "100vh",
            background:
            "linear-gradient(295.27deg, #000000 50%, #394867 84.52%)"
           }}
        >
          <Outlet />
        </Content>
      </Layout>
    </>
  );
};

export default ProductLayout;
