import { createWeb3ReactRoot, Web3ReactProvider } from "@web3-react/core";
import { App } from "App";
import { NetworkContextName } from "hooks/useActiveWeb3React";
import React, { Suspense } from "react";
import { ThemeSwitcherProvider } from "react-css-theme-switcher";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { usePollBlockNumber } from "state/block/hooks";
import MultiCallUpdater from "state/multicall/multiCallUpdater";
import { getLibrary } from "utils/web3React";
import getLibrary2 from "utils/getLibrary";
import { store } from "./app/store";
import "./index.css";
import TokenListUpdater from "./state/lists/updater";
import UserUpdater from "./state/user/updater";
import TransactionUpdater from "./state/transactions/updater";
import ApplicationUpdater from "./state/application/updater";
import * as serviceWorker from "./serviceWorker";

const themes = {
  dark: `${process.env.PUBLIC_URL}/dark-theme.css`,
  light: `${process.env.PUBLIC_URL}/light-theme.css`,
};

const defaultTheme = localStorage.getItem("product-theme");

const Web3ProviderNetwork = createWeb3ReactRoot(NetworkContextName);

if ("ethereum" in window) {
  (window.ethereum as any).autoRefreshOnNetworkChange = false;
}

function GlobalHooks() {
  usePollBlockNumber();
  return null;
}

function Updater() {
  return (
    <>
      <TransactionUpdater />
      <TokenListUpdater />
      <ApplicationUpdater />
    </>
  );
}

ReactDOM.render(
  <Web3ReactProvider getLibrary={getLibrary}>
    <Web3ProviderNetwork getLibrary={getLibrary2}>
      <Provider store={store}>
        <GlobalHooks />
        <MultiCallUpdater />
        <Updater />
        <BrowserRouter>
          <ThemeSwitcherProvider
            themeMap={themes}
            defaultTheme={defaultTheme || "light"}
            insertionPoint={document.getElementById("inject-styles-here")}
          >
            <Suspense fallback={<>Loading...</>}>
              <App />
            </Suspense>
          </ThemeSwitcherProvider>
        </BrowserRouter>
      </Provider>
    </Web3ProviderNetwork>
  </Web3ReactProvider>,

  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
