import { SettingOutlined } from "@ant-design/icons";
import { Button, Tabs } from "antd";
import React, { Dispatch, useState } from "react";
import { useSettingModal } from "hooks/useSettingModal";
import { SwapInfo } from "./SwapInfo";
import IconsComponent from "components/Icons";
import SwapTab from "./SwapTab";
import { Field } from "state/swap/types";
import { useDerivedSwapInfo, useSwapState } from "state/swap/swapHook";
import { useCurrency } from "hooks/useCurrency";

interface SwapProps {
  setIsEnter: Dispatch<boolean>;
  platformOverride: string;
  setPlatformOverride: Dispatch<string>;
}

export const Swap: React.FunctionComponent<SwapProps> = ({
  setIsEnter,
  platformOverride,
  setPlatformOverride,
}) => {
  const [value, setValue] = useState<string | number>("Swap");
  const { modal, showModal } = useSettingModal();

  const operations = (
    <div className="setting-group">
      <IconsComponent iconName="reload" loading={true} />
      <Button
        type="text"
        icon={<SettingOutlined />}
        onClick={() => showModal()}
      />
    </div>
  );

  const onComplete = (elapseTime: number) => {
    return {
      shouldRepeat: true,
      delay: 0,
      newInitialRemainingTime: 30,
    };
  };

  const {
    independentField,
    typedValue,
    recipient,
    [Field.INPUT]: { currencyId: inputCurrencyId },
    [Field.OUTPUT]: { currencyId: outputCurrencyId },
  } = useSwapState();

  const inputCurrency = useCurrency(inputCurrencyId);
  const outputCurrency = useCurrency(outputCurrencyId);

  const { currencies } = useDerivedSwapInfo(
    independentField,
    typedValue,
    inputCurrency,
    outputCurrency,
    recipient
  );

  return (
    <div className="card right--side">
      <Tabs defaultActiveKey="1" tabBarExtraContent={operations}>
        <Tabs.TabPane tab="Swap" key="1">
          <SwapTab
            setIsEnter={setIsEnter}
            platformOverride={platformOverride}
            setPlatformOverride={setPlatformOverride}
          />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Info" key="2">
          <SwapInfo
            tokenInput={currencies[Field.INPUT]}
            tokenOutput={currencies[Field.OUTPUT]}
          />
        </Tabs.TabPane>
      </Tabs>
      {modal}
    </div>
  );
};
