import { createSlice } from '@reduxjs/toolkit';
import { customCreateAsyncThunk } from 'slice/customAsynSlice';

export const login = customCreateAsyncThunk.createAsyncThunkWithLoading(
  'auth/login', 'POST', '/api/auth',
);

interface InitialState {
    error?:any,
    data?:any,
    isLogin?:boolean,
    redirectLogin?:boolean,
}

const initialState:InitialState = {
  data: null,
  error: null,
  isLogin: false,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    resetAuthReducer: () => initialState,
  },
  extraReducers: {
    [login.fulfilled]: (state:any, action:any) => {
      state.data = action.payload;
      state.isLogin = true
    },
    [login.rejected]: (state:any, action:any) => {
      state.error = action.payload;
    },
  },
});
// export const { resetRedirectLogin } = authSlice.actions;

const authReducer = authSlice.reducer;
export default authReducer;
export const selectAuth = (state:any) => state.auth;
