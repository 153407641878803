export interface RawCurrency {
  chainId: number;
  symbol: string;
  name: string;
  address: string;
  decimals: number;
  image: string;
}

/**
 * A currency is any fungible financial instrument on Ethereum, including Ether and all ERC20 tokens.
 *
 * The only instance of the base class `Currency` is Ether.
 */
export class Currency {
  public readonly decimals: number;
  public readonly symbol?: string;
  public readonly name?: string;
  public readonly image?: string;
  public readonly address?: string;

  public static readonly ETH = {
    chainId: 1,
    address: "",
    decimals: 18,
    symbol: "ETH",
    name: "Ethereum",
    image: "https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png",
  };

  public static readonly BNB = {
    chainId: 56,
    address: "",
    decimals: 18,
    symbol: "BNB",
    name: "BNB",
    image: "https://s2.coinmarketcap.com/static/img/coins/64x64/1839.png",
  };

  /**
   * Constructs an instance of the base class `Currency`. The only instance of the base class `Currency` is `Currency.ETHER`.
   * @param decimals decimals of the currency
   * @param symbol symbol of the currency
   * @param name of the currency
   * @param image of the currency
   */
  public constructor(
    decimals: number,
    symbol?: string,
    name?: string,
    image?: string
  ) {
    this.decimals = decimals;
    this.symbol = symbol;
    this.name = name;
    this.image = image;
  }
}

export const getNativeCurrency = (chainId?: number) => {
  if (chainId === 56 || chainId === 97)
    return {
      decimals: 18,
      symbol: "BNB",
      name: "BNB",
      image: "https://s2.coinmarketcap.com/static/img/coins/64x64/1839.png",
    };
  else
    return {
      decimals: 18,
      symbol: "ETH",
      name: "Ethereum",
      image: "https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png",
    };
};
