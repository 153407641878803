import React, { useEffect, useState } from "react";

interface ReloadIconProps {
  className: string;
  cursor?: boolean;
  loading?: false | true;
}

const ReloadIcon: React.FunctionComponent<ReloadIconProps> = ({
  className,
  cursor,
  loading,
}) => {
  const [refreshLoading, setRefreshLoading] = useState<boolean>(false);

  const [rotateClick, setRotateClick] = useState<boolean>(false);

  useEffect(() => {
    const interval = setInterval(() => {
      if (refreshLoading) {
        setRefreshLoading(false);
      } else setRefreshLoading(true);
    }, 3000);
    return () => clearInterval(interval);
  }, [refreshLoading]);

  useEffect(() => {
    if (rotateClick) {
      setTimeout(() => {
        setRefreshLoading(false);
        setRotateClick(false);
      }, 500);
    }
  }, [rotateClick]);
  return (
    <>
      <svg
        id="text"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={`${className} ${refreshLoading ? "rotate" : ""} ${
          rotateClick ? "rotateClick" : ""
        }`}
        cursor={cursor ? "pointer" : ""}
        onClick={() => {
          setRotateClick(true);
          setRefreshLoading(true);
        }}
      >
        <g clipPath="url(#clip0_513_2)">
          <path d="M14.66 15.66C13.352 16.9694 11.6305 17.7848 9.78879 17.9673C7.94705 18.1498 6.09901 17.6881 4.55952 16.6608C3.02004 15.6335 1.88436 14.1042 1.34597 12.3335C0.807587 10.5628 0.899804 8.66013 1.60691 6.94978C2.31402 5.23942 3.59227 3.82714 5.22389 2.95356C6.85551 2.07997 8.73954 1.79912 10.555 2.15887C12.3705 2.51861 14.005 3.49669 15.1802 4.92645C16.3554 6.35622 16.9985 8.14923 17 9.99999H15C15.0012 8.6118 14.521 7.26612 13.6413 6.19229C12.7615 5.11845 11.5366 4.38289 10.1753 4.11096C8.81404 3.83902 7.40056 4.04754 6.17577 4.70097C4.95098 5.3544 3.99066 6.41232 3.45845 7.69445C2.92625 8.97657 2.85509 10.4036 3.25711 11.7323C3.65913 13.061 4.50944 14.2092 5.66315 14.9812C6.81687 15.7533 8.20259 16.1014 9.58419 15.9662C10.9658 15.831 12.2578 15.221 13.24 14.24L14.66 15.66ZM12 9.99999H20L16 14L12 9.99999Z" />
        </g>
        <defs>
          <clipPath id="clip0_513_2">
            <rect width="20" height="20" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </>
  );
};

export default ReloadIcon;
