import { Token } from "libs/pancake-swap";
import { ChainId } from "../constants/chainIds";

export const CAKE_MAINNET = new Token(
  56,
  "0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82",
  18,
  "CAKE",
  "PancakeSwap Token"
);

export const CAKE = {
  [ChainId.BNB]: CAKE_MAINNET,
  [ChainId.tBNB]: CAKE_MAINNET,
  [ChainId.MAINNET]: CAKE_MAINNET,
  [ChainId.GOERLI]: CAKE_MAINNET,
  [ChainId.ROPSTEN]: CAKE_MAINNET,
};

export const USDT_BNB = new Token(
  56,
  "0x55d398326f99059fF775485246999027B3197955",
  18,
  "USDT",
  "Binance Pegged USDT"
)

export const USDT_tBNB = new Token(
  97,
  "0x337610d27c682E347C9cD60BD4b3b107C9d34dDd",
  18,
  "USDT",
  "USDT Token"
)

export const USDT = {
  [ChainId.BNB]: USDT_BNB,
  [ChainId.tBNB]: USDT_tBNB,
}