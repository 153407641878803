import React from "react";

interface ArrowDownProps {}

const ArrowDown: React.FunctionComponent<ArrowDownProps> = (props) => {
  return (
    <>
      <svg
        width="16"
        height="22"
        viewBox="0 0 16 22"
        className="arrow--icon"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8 2V18"
          stroke="#8E83AA"
          strokeWidth="3"
          strokeLinecap="round"
        />
        <path
          d="M14 13L8 19L2 13"
          stroke="#8E83AA"
          strokeWidth="3"
          strokeLinecap="round"
        />
      </svg>
    </>
  );
};

export default ArrowDown;
