import { useAppDispatch, useAppSelector } from "app/hooks";
import { ApplicationModal } from "models/application";
import { useCallback } from "react";
import { setOpenModal } from "state/application/application";

export function useModalOpen(modal: ApplicationModal): boolean {
  const openModal = useAppSelector((state) => state.application.openModal);
  return openModal === modal;
}

export function useToggleModal(modal: ApplicationModal): () => void {
  const open = useModalOpen(modal);
  const dispatch = useAppDispatch();
  return useCallback(
    () => dispatch(setOpenModal(open ? null : modal)),
    [dispatch, modal, open]
  );
}

export function useWalletModalToggle(): () => void {
  return useToggleModal(ApplicationModal.WALLET_SWITCHER);
}

export function useNetworkSwitcherToggle(): () => void {
  return useToggleModal(ApplicationModal.NETWORK_SWITCHER);
}

export function useCloseModals(): () => void {
  const dispatch = useAppDispatch();
  return useCallback(() => dispatch(setOpenModal(null)), [dispatch]);
}
