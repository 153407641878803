import { useAppDispatch, useAppSelector } from "app/hooks";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "app/store";
import {
  updateUserBonusRouter,
  updateUserSlippageTolerance,
  updateUserSingleHopOnly,
  updateGasPrice,
  updateUserDeadline,
} from "./userSlice";
import { useMemo } from "react";
import { ChainId, Token } from "@ape.swap/sdk";
import { GAS_PRICE_GWEI, deserializeToken } from "./helpers";
import { useWeb3React } from "@web3-react/core";
import {
  addSerializedToken,
  removeSerializedToken,
  SerializedToken,
} from "./userAction";
import { ERC20Token } from "entities/erc20token";
import useSWR from "swr";

export function useUserSlippageTolerance(): [
  number,
  (slippage: number) => void
] {
  const dispatch = useAppDispatch();
  const userSlippageTolerance = useAppSelector(
    (state) => state.user.userSlippageTolerance
  );

  const setUserSlippageTolerance = useCallback(
    (slippage: number) => {
      dispatch(
        updateUserSlippageTolerance({ userSlippageTolerance: slippage })
      );
    },
    [dispatch]
  );

  return [userSlippageTolerance, setUserSlippageTolerance];
}

export function useBonusRouterManager(): [boolean, () => void] {
  const dispatch = useDispatch<AppDispatch>();
  const bonusRouterDisabled = useSelector<
    RootState,
    RootState["user"]["userBonusRouterDisabled"]
  >((state) => state.user.userBonusRouterDisabled);

  const toggleSetBonusRouter = useCallback(() => {
    dispatch(
      updateUserBonusRouter({ userBonusRouterDisabled: !bonusRouterDisabled })
    );
  }, [bonusRouterDisabled, dispatch]);

  return [bonusRouterDisabled, toggleSetBonusRouter];
}

export function useUserSingleHopOnly(): [
  boolean,
  (newSingleHopOnly: boolean) => void
] {
  const dispatch = useDispatch<AppDispatch>();

  const singleHopOnly = useSelector<
    RootState,
    RootState["user"]["userSingleHopOnly"]
  >((state) => state.user.userSingleHopOnly);

  const setSingleHopOnly = useCallback(
    (newSingleHopOnly: boolean) => {
      dispatch(
        updateUserSingleHopOnly({ userSingleHopOnly: newSingleHopOnly })
      );
    },
    [dispatch]
  );

  return [singleHopOnly, setSingleHopOnly];
}

export default function useUserAddedTokens(): Token[] {
  const { chainId } = useWeb3React();
  const serializedTokensMap = useSelector<
    RootState,
    RootState["user"]["tokens"]
  >(({ user: { tokens } }) => tokens);

  return useMemo(() => {
    if (!chainId) return [];
    return Object.values(serializedTokensMap?.[chainId as ChainId] ?? {}).map(
      deserializeToken
    );
  }, [serializedTokensMap, chainId]);
}
function serializeToken(token: ERC20Token): SerializedToken {
  return {
    chainId: token.chainId,
    address: token.address,
    decimals: token.decimals,
    symbol: token.symbol,
    name: token.name,
  };
}
export function useAddUserToken(): (token: ERC20Token) => void {
  const dispatch = useDispatch<AppDispatch>();

  return useCallback(
    (token: ERC20Token) => {
      dispatch(addSerializedToken({ serializedToken: serializeToken(token) }));
    },
    [dispatch]
  );
}

export function useRemoveUserAddedToken(): (
  chainId: number | any,
  address: string
) => void {
  const dispatch = useDispatch<AppDispatch>();
  return useCallback(
    (chainId: number, address: string) => {
      dispatch(removeSerializedToken({ chainId, address }));
    },
    [dispatch]
  );
}

export function useGasPriceManager(): [string, (userGasPrice: string) => void] {
  const dispatch = useAppDispatch();

  const userGasPrice = useAppSelector((state) => state.user.gasPrice);

  const setGasPrice = useCallback(
    (gasPrice: string) => {
      dispatch(updateGasPrice({ gasPrice }));
    },
    [dispatch]
  );

  return [userGasPrice, setGasPrice];
}

export function useUserTransactionTTL(): [number, (slippage: number) => void] {
  const dispatch = useAppDispatch();
  const userDeadline = useAppSelector((state) => {
    return state.user.userDeadline;
  });

  const setUserDeadline = useCallback(
    (deadline: number) => {
      dispatch(updateUserDeadline({ userDeadline: deadline }));
    },
    [dispatch]
  );

  return [userDeadline, setUserDeadline];
}

const DEFAULT_BSC_GAS_BIGINT = BigInt(GAS_PRICE_GWEI.default)
const DEFAULT_BSC_TESTNET_GAS_BIGINT = BigInt(GAS_PRICE_GWEI.testnet)

export function useGasPrice(chainIdOverride?: number): bigint | undefined {
  const { chainId: chainId_, library } = useWeb3React();
  const chainId = chainIdOverride ?? chainId_
  // const { data: signer } = useWeb3React({ chainId })
  const userGas = useAppSelector((state) => state.user.gasPrice)
  const { data: bscProviderGasPrice = DEFAULT_BSC_GAS_BIGINT } = useSWR(
    library && chainId === ChainId.BSC && userGas === GAS_PRICE_GWEI.rpcDefault && ['bscProviderGasPrice', library],
    async () => {
      // @ts-ignore
      const gasPrice = await signer?.request({
        method: 'eth_gasPrice',
      })
      return BigInt(gasPrice)
    },
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    },
  )
  if (chainId === ChainId.BSC) {
    return userGas === GAS_PRICE_GWEI.rpcDefault ? bscProviderGasPrice : BigInt(userGas ?? GAS_PRICE_GWEI.default)
  }
  if (chainId === ChainId.BSC_TESTNET) {
    return DEFAULT_BSC_TESTNET_GAS_BIGINT
  }
  return undefined
}