import { createAction, createSlice } from "@reduxjs/toolkit";

// export interface Call {
//   address: string;
//   callData: string;
// }

// const ADDRESS_REGEX = /^0x[a-fA-F0-9]{40}$/;
// const LOWER_HEX_REGEX = /^0x[a-f0-9]*$/;

// export function toCallKey(call: Call): string {
//   if (!ADDRESS_REGEX.test(call.address)) {
//     throw new Error(`Invalid address: ${call.address}`);
//   }
//   if (!LOWER_HEX_REGEX.test(call.callData)) {
//     throw new Error(`Invalid hex: ${call.callData}`);
//   }
//   return `${call.address}-${call.callData}`;
// }

// export function parseCallKey(callKey: string): Call {
//   const pcs = callKey.split("-");
//   if (pcs.length !== 2) {
//     throw new Error(`Invalid call key: ${callKey}`);
//   }
//   return {
//     address: pcs[0],
//     callData: pcs[1],
//   };
// }

export interface Call {
  address: string;
  callData: string;
  gasRequired?: number;
}

export function toCallKey(call: Call): string {
  let key = `${call.address}-${call.callData}`;
  if (call.gasRequired) {
    if (!Number.isSafeInteger(call.gasRequired)) {
      throw new Error(`Invalid number: ${call.gasRequired}`);
    }
    key += `-${call.gasRequired}`;
  }
  return key;
}

export function parseCallKey(callKey: string): Call {
  const pcs = callKey.split("-");
  if (![2, 3].includes(pcs.length)) {
    throw new Error(`Invalid call key: ${callKey}`);
  }
  return {
    address: pcs[0],
    callData: pcs[1],
    ...(pcs[2] ? { gasRequired: Number.parseInt(pcs[2]) } : {}),
  };
}

export interface ListenerOptions {
  readonly blocksPerFetch?: number;
}

export const addMultiCallListeners = createAction<{
  chainId: number;
  calls: Call[];
  options?: ListenerOptions;
}>("multiCall/addMultiCallListeners");

export const removeMultiCallListeners = createAction<{
  chainId: number;
  calls: Call[];
  options?: ListenerOptions;
}>("multiCall/removeMultiCallListeners");

export const fetchingMultiCallResults = createAction<{
  chainId: number;
  calls: Call[];
  fetchingBlockNumber: number;
}>("multiCall/fetchingMultiCallResults");

export const errorFetchingMultiCallResults = createAction<{
  chainId: number;
  calls: Call[];
  fetchingBlockNumber: number;
}>("multiCall/errorFetchingMultiCallResults");

export const updateMultiCallResults = createAction<{
  chainId: number;
  blockNumber: number;
  results: {
    [callKey: string]: string | null;
  };
}>("multiCall/updateMultiCallResults");

export interface MultiCallState {
  callListeners?: {
    // on a per-chain basis
    [chainId: number]: {
      // stores for each call key the listeners' preferences
      [callKey: string]: {
        // stores how many listeners there are per each blocks per fetch preference
        [blocksPerFetch: number]: number;
      };
    };
  };

  callResults: {
    [chainId: number]: {
      [callKey: string]: {
        data?: string | null;
        blockNumber?: number;
        fetchingBlockNumber?: number;
      };
    };
  };
}

const initialState: MultiCallState = {
  callResults: {},
};

export const multiCallSlice = createSlice({
  name: "multiCall",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(
        addMultiCallListeners,
        (
          state,
          { payload: { calls, chainId, options: { blocksPerFetch = 1 } = {} } }
        ) => {
          const listeners: MultiCallState["callListeners"] = state.callListeners
            ? state.callListeners
            : (state.callListeners = {});
          listeners[chainId] = listeners[chainId] ?? {};
          calls.forEach((call) => {
            const callKey = toCallKey(call);
            listeners[chainId][callKey] = listeners[chainId][callKey] ?? {};
            listeners[chainId][callKey][blocksPerFetch] =
              (listeners[chainId][callKey][blocksPerFetch] ?? 0) + 1;
          });
        }
      )
      .addCase(
        removeMultiCallListeners,
        (
          state,
          { payload: { chainId, calls, options: { blocksPerFetch = 1 } = {} } }
        ) => {
          const listeners: MultiCallState["callListeners"] = state.callListeners
            ? state.callListeners
            : (state.callListeners = {});

          if (!listeners[chainId]) return;
          calls.forEach((call) => {
            const callKey = toCallKey(call);
            if (!listeners[chainId][callKey]) return;
            if (!listeners[chainId][callKey][blocksPerFetch]) return;
            if (listeners[chainId][callKey][blocksPerFetch] === 1) {
              delete listeners[chainId][callKey][blocksPerFetch];
            } else {
              listeners[chainId][callKey][blocksPerFetch]--;
            }
          });
        }
      )
      .addCase(
        fetchingMultiCallResults,
        (state, { payload: { chainId, fetchingBlockNumber, calls } }) => {
          state.callResults[chainId] = state.callResults[chainId] ?? {};
          calls.forEach((call) => {
            const callKey = toCallKey(call);
            const current = state.callResults[chainId][callKey];
            if (!current) {
              state.callResults[chainId][callKey] = { fetchingBlockNumber };
            } else {
              if ((current.fetchingBlockNumber ?? 0) >= fetchingBlockNumber)
                return;
              state.callResults[chainId][callKey].fetchingBlockNumber =
                fetchingBlockNumber;
            }
          });
        }
      )
      .addCase(
        errorFetchingMultiCallResults,
        (state, { payload: { fetchingBlockNumber, chainId, calls } }) => {
          state.callResults[chainId] = state.callResults[chainId] ?? {};
          calls.forEach((call) => {
            const callKey = toCallKey(call);
            const current = state.callResults[chainId][callKey];
            if (!current) return;
            if (current.fetchingBlockNumber === fetchingBlockNumber) {
              delete current.fetchingBlockNumber;
              current.data = null;
              current.blockNumber = fetchingBlockNumber;
            }
          });
        }
      )
      .addCase(
        updateMultiCallResults,
        (state, { payload: { chainId, results, blockNumber } }) => {
          state.callResults[chainId] = state.callResults[chainId] ?? {};
          Object.keys(results).forEach((callKey) => {
            const current = state.callResults[chainId][callKey];
            if ((current?.blockNumber ?? 0) > blockNumber) return;
            state.callResults[chainId][callKey] = {
              data: results[callKey],
              blockNumber,
            };
          });
        }
      );
  },
});

export const actions = multiCallSlice.actions;

export default multiCallSlice.reducer;
