import type { Signer } from '@ethersproject/abstract-signer';
import { Contract } from '@ethersproject/contracts';
import type { Provider } from '@ethersproject/providers';
import { JsonRpcSigner, Web3Provider } from '@ethersproject/providers';
import { useWeb3React } from '@web3-react/core';
import IPancakeRouter02ABI from 'config/abis/pancakeRouter02.json';
import { isAddress } from 'utils/addressHelpers';
import { getSimpleRpcProvider } from 'utils/providers';
import { ChainId } from '../constants';

export const AddressZero = '0x0000000000000000000000000000000000000000';

// account is not optional
export function getSigner(library: Web3Provider, account: string): JsonRpcSigner {
  return library.getSigner(account).connectUnchecked();
}

// account is optional
export function getProviderOrSigner(
  library: Web3Provider,
  account?: string
): Web3Provider | JsonRpcSigner {
  return account ? getSigner(library, account) : library;
}

export function getContract(address: string, ABI: any, singer?: Signer | Provider) {
  if (!isAddress(address) || address === AddressZero) {
    throw Error(`Invalid 'address' parameter '${address}'`);
  }
  return new Contract(address, ABI, singer ?? getSimpleRpcProvider(ChainId.MAINNET));
}

export const ROUTER_ADDRESS: any = {
  [ChainId.MAINNET]: '0x3a6d8cA21D1CF76F653A67577FA0D27453350dD8',
  [ChainId.TESTNET]: '',
};

// account is optional
export function getRouterContract(_: number, library: Web3Provider, account?: string) {
  return getContract(
    ROUTER_ADDRESS[_ as never],
    IPancakeRouter02ABI,
    getProviderOrSigner(library, account)
  );
}
