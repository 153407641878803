import { useWeb3React } from "@web3-react/core";
import { useAppSelector } from "app/hooks";
import { useModalOpen } from "hooks";
import { ApplicationModal } from "models/application";
import React from "react";
import NetworkModal from "./NetworkModal";
import WalletModal from "./WalletModal";

interface ConnectWalletWrapperProps {}
const ConnectWalletWrapper: React.FunctionComponent<
  ConnectWalletWrapperProps
> = ({ children }) => {
  const { connector, active, deactivate } = useWeb3React();
  const walletModalOpen = useModalOpen(ApplicationModal.WALLET_SWITCHER);
  return (
    <>
      {children}
      <WalletModal />
      <NetworkModal />
    </>
  );
};

export default ConnectWalletWrapper;
