import React, { useContext } from "react";
import SearchField from "../../../components/Input/SearchField";
import { ManageListsContext } from "../context/ManageListContext";
import { useListRow } from "../hooks/useListRow";
import { Image } from "antd";
import ButtonCheckCircle from "assets/icons/ButtonCheckCircle";
import CardImport from "../components/CardImport";

interface ManageListsProps {}

export interface ListRowProps {
  listUrl: string;
}

const ManageLists: React.FunctionComponent<ManageListsProps> = (props) => {
  const {
    addError,
    handleImport,
    handleInput,
    isImported,
    listUrlInput,
    renderableLists,
    tempList,
    disableListImport,
  } = useContext(ManageListsContext);
 
  return (
    <>
      <SearchField
        name="lists"
        placeholder="https:// or ipfs:// or ENS name"
        value={listUrlInput}
        onChange={handleInput}
      />
      {addError && <div className="manage--tokens--error">{addError}</div>}

      {tempList && (
        <CardImport
          tempList={tempList}
          isImported={isImported}
          handleImport={handleImport}
        />
      )}
      <div className="manage--tokens--content">
        {/* <CardList /> */}
        {renderableLists.map((listUrl) => {
          return <ListRow key={listUrl} listUrl={listUrl} />;
        })}
      </div>
    </>
  );
};

const ListRow = ({ listUrl }: ListRowProps) => {
  const {
    handleAcceptListUpdate,
    handleDisableList,
    handleEnableList,
    handleRemoveList,
    isActive,
    tokensAmountInCurrentChain,
    list,
    listsByUrl,
    disableListInfo,
  } = useListRow({ listUrl });
  if (!list || tokensAmountInCurrentChain === 0) return null;

  return (
    <>
      <div className="manage--tokens--card">
        <div className="manage--tokens--card__info">
          <Image src={list.logoURI} preview={false} width={35} height={35} />
          <div className="manage--tokens--card__info__nameEx">
            <div>{list.name}</div>
            <div>{tokensAmountInCurrentChain} tokens</div>
          </div>
        </div>

        <div className="manage--tokens--card__btn">
          <ButtonCheckCircle
            isActive={isActive}
            toggle={() => {
              isActive ? handleDisableList() : handleEnableList();
            }}
          />
        </div>
      </div>
    </>
  );
};
export default ManageLists;
