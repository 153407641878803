import About from "features/layout/landing/About";
import Awards from "features/layout/landing/Awards";
import CoinFund from "features/layout/landing/CoinFund";
import Faq from "features/layout/landing/Faq";
import Footer from "features/layout/landing/Footer";
import Hero from "features/layout/landing/Hero";
import Service from "features/layout/landing/Service";
import Stack from "features/layout/landing/Stack";
import Subscribe from "features/layout/landing/Subscribe";
import Wallet from "features/layout/landing/Wallet";
import React from "react";

interface LandingPageProps {}

export const LandingPage: React.FunctionComponent<LandingPageProps> = () => {
  return (
    <>
      <Hero />
      <Service />
      <CoinFund />
      <About />
      <Awards />
      <Wallet />
      <Faq />
      <Stack />
      <Subscribe />
      <Footer />
    </>
  );
};
