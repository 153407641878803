import { Row, Col, Image } from "antd";
import React from "react";
import SectionParticle from "assets/images/particles/crypto-managment-wallet.svg";
import walletImage from "assets/images/wallet/wallet-image.svg";
import Counter from "components/UIElements/Counter";

interface WalletProps {}

const data = [
  { count: 47, suffixText: "+", body: "Crypto Wallets" },
  { count: 26, suffixText: "+", body: "Trading Apps" },
  { count: 10, suffixText: "+", body: "Price Trackers" },
];

const Wallet: React.FunctionComponent<WalletProps> = (props) => {
  return (
    <>
    </>
  );
};

export default Wallet;
