import { Currency } from "entities/currency";
// import { Flex } from "rebass";
import { useCurrencyBalances } from "../../hooks/useBalances";
import { CellMeasurerCache } from "react-virtualized";
import React, {
  CSSProperties,
  MutableRefObject,
  useCallback,
  useMemo,
  useState,
} from "react";
import TokenPickerItem from "../TokenPickerItem/TokenPickerItem";
import { useWeb3React } from "@web3-react/core";
import { FixedSizeList } from "react-window";
import { Token } from "entities/token";
import { WrappedTokenInfo } from "state/lists/wrapped-token-info";
import { Token as MyToken } from "../../models/manageToken/index";
import _ from "lodash";

interface CurrencyRowProps {
  currency: Currency;
  style: CSSProperties;
  balance?: any;
  onSelectToken: (token: MyToken) => void;
}

function currencyKey(currency: Currency | any): string {
  return currency?.isToken
    ? currency.address
    : currency?.isNative
    ? currency.symbol
    : _.uniqueId();
}

export const CurrencyRow = ({
  currency,
  style,
  balance,
  onSelectToken,
}: CurrencyRowProps) => {
  return (
    <>
      <TokenPickerItem
        onSelectToken={onSelectToken}
        token={currency}
        style={style}
        price={
          balance
            ? balance.amount.toFixed(4) === "0.0000"
              ? "0"
              : balance.amount.toFixed(4)
            : "0"
        }
      />
    </>
  );
};

export const BREAK_LINE = "BREAK";

export interface CurrencyListProps {
  currencies: Currency[];
  fixedListRef?: MutableRefObject<FixedSizeList>;
  otherListTokens: Token[] | any;
  onSelectToken: (token: MyToken) => void;
}

const CurrencyList: React.FunctionComponent<CurrencyListProps> = ({
  currencies,
  fixedListRef,
  otherListTokens,
  onSelectToken,
}) => {
  const { account } = useWeb3React();
  const [hasBreakLine, setHasBreakLine] = useState(false);

  const itemData = useMemo(() => {
    if (otherListTokens && otherListTokens?.length > 0) {
      const foundByAddressAndNotInAnyList =
        otherListTokens.length === 1 &&
        !(otherListTokens[0] instanceof WrappedTokenInfo);
      if (foundByAddressAndNotInAnyList) {
        setHasBreakLine(false);
        return otherListTokens;
      }
      setHasBreakLine(true);
      return [...otherListTokens];
    }
    setHasBreakLine(false);
    return currencies;
  }, [currencies, otherListTokens]);
  const balances = useCurrencyBalances(
    account ?? undefined,
    hasBreakLine ? itemData.slice(1) : itemData
  ) as any;

  const Row = useCallback(
    ({ data, index, style }: any) => {
      const currency = data[index];
      if (currency) {
        return (
          <CurrencyRow
            onSelectToken={onSelectToken}
            currency={currency}
            style={style}
            balance={balances[hasBreakLine ? index - 1 : index]}
          />
        );
      }
      return <></>;
    },
    [balances, currencies.length]
  );

  const cache = React.useRef(
    new CellMeasurerCache({
      fixedWidth: false,
      defaultHeight: 500,
    })
  );
  const itemKey = useCallback(
    (index: number, data: any) => currencyKey(data[index]),
    []
  );

  return (
    <>
      {/* <AutoSizer style={{ height: "100%", width: "100%", overflow: "hidden" }}>
        {({ width, height }) => {
          return (
            <FixedSizeList
              className="List"
              width={width}
              height={height}
              itemData={itemData}
              itemCount={itemData.length}
              itemSize={30}
              itemKey={itemKey}
            >
              {Row}
            </FixedSizeList>
          );
        }}
      </AutoSizer> */}
      <FixedSizeList
        className="List"
        height={400}
        ref={fixedListRef}
        itemData={itemData}
        itemCount={itemData.length}
        itemSize={56}
        width="100%"
        itemKey={itemKey}
      >
        {Row}
      </FixedSizeList>
      {/* <FixedSizeList
        className="List"
        height={500}
        ref={fixedListRef as any}
        width="100%"
        itemSize={56}
        itemData={itemData}
        itemCount={itemData.length}
        itemKey={itemKey}
      >
        {Row}
      </FixedSizeList> */}
      {/* <div style={{ width: "100%", height: "100vh" }}>
        <AutoSizer>
          {({ width, height }) => (
            <List
              width={width}
              height={height}
              rowHeight={cache.current.rowHeight}
              deferredMeasurementCache={cache.current}
              rowCount={itemData.length}
              rowRenderer={({ key, index, style, parent }) => {
                const currency = itemData[index];

                return (
                  <CellMeasurer
                    key={key}
                    cache={cache.current}
                    parent={parent}
                    columnIndex={0}
                    rowIndex={index}
                  >
                    <CurrencyRow currency={currency} />
                  </CellMeasurer>
                );
              }}
            />
          )}
        </AutoSizer>
      </div> */}

      {/*  *
      {/* {currencies.map((currency) => {
        return (
          <>
            <CurrencyRow currency={currency} />
          </>
        );
      })} */}
      {/* <div>1</div>
      <AutoSizer style={{ height: "100%", width: "100%" }}>
        {({ width, height }: any) => {
          console.log("width", width);
          console.log("height", height);
          return (
            <FixedSizeList
              ref={fixedListRef}
              width={width}
              height={height}
              itemData={currencies}
              itemCount={currencies.length}
              itemSize={560}
            >
              {Row}
            </FixedSizeList>
          );
        }}
      </AutoSizer> */}
      {/* <Flex overflowY="auto" flex="1"> */}
      {/* </Flex> */}
    </>
  );
};

export default CurrencyList;
