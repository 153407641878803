import React from "react";
import "./App.css";
import "./scss/app.scss";
import { Route, Routes } from "react-router-dom";
import { LandingPage, NotFoundPage, SwapPage } from "features";
import { MyLayout } from "components";
import ProductLayout from "features/layout/ProductLayout";
import LandingLayout from "features/layout/LandingLayout";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Web3ReactManager from "components/Web3ReactManager";
import useAuth from "hooks/useAuth";
import { useEffect } from "react";
import Blogs from "./features/blogs";

export interface AppProps {}

export const App: React.FunctionComponent<AppProps> = (props) => {
  // const { connector, autoLogin } = useAuth();
  // useEffect(() => {
  //   connector.isAuthorized().then(() => {
  //     autoLogin();
  //   });
  // }, []);

  return (
    <>
      <Web3ReactManager>
        <Routes>
          <Route path="/*" element={<ProductLayout />}>
            <Route path="swap" element={<SwapPage />} />
            <Route path="blog" element={<Blogs />} />
            <Route path="*" element={<NotFoundPage />} />
          </Route>

          <Route path="/*" element={<LandingLayout />}>
            <Route path="" element={<LandingPage />} />
          </Route>
        </Routes>
      </Web3ReactManager>

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
        toastStyle={{
          backgroundColor: "rgb(45, 39, 85)",
        }}
      />
    </>
  );
};
