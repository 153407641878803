import { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import useDebounce from "../../../hooks/useDebounce";
import { useAllTokens } from "../../../hooks/useAllTokens";
import { useToken } from "../../../hooks/Token";
import { useAllLists, useCombinedActiveList } from "../../../state/lists/hooks";
import {
  ManageListsProps,
  ManageListsContextType,
  CurrencyModalView,
} from "../../../models/manageList/ManageListsProps";
import { useFetchListCallback } from "../../../hooks/useFetchListCallback";
import uriToHttp from "utils/uriToHttp";
import { parseENSAddress } from "utils/parseENSAddress";
import { TokenList } from "@uniswap/token-lists";
import { UNSUPPORTED_LIST_URLS } from "constants/lists";

export const useManageListsContextSwap = ({
  setListUrl,
  setModalView,
  setImportList,
}: ManageListsProps): ManageListsContextType => {
  const [listUrlInput, setListUrlInput] = useState<string>("");
  const lists = useAllLists();

  const handleInput = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setListUrlInput(e.target.value);
  }, []);

  const fetchList = useFetchListCallback(); // call list token

  const validUrl: boolean = useMemo(() => {
    return (
      uriToHttp(listUrlInput).length > 0 ||
      Boolean(parseENSAddress(listUrlInput))
    );
  }, [listUrlInput]);

  // temporary fetched list for import flow
  const [tempList, setTempList] = useState<TokenList>();
  const [addError, setAddError] = useState<string | undefined>();

  useEffect(() => {
    async function fetchTempList() {
      fetchList(listUrlInput, false)
        .then((list) => setTempList(list))
        .catch(() => setAddError("Error importing list"));
    }
    // if valid url, fetch details for card
    if (validUrl) {
      fetchTempList();
    } else {
      setTempList(undefined);
      listUrlInput !== "" && setAddError("Enter valid list location");
    }

    // reset error
    if (listUrlInput === "") {
      setAddError(undefined);
    }
  }, [fetchList, listUrlInput, validUrl]);

  // check if list is already imported
  const isImported = Object.keys(lists).includes(listUrlInput);

  // set list values and have parent modal switch to import list view
  const handleImport = useCallback(() => {
    if (!tempList) return;
    setImportList(tempList);
    setModalView(CurrencyModalView.IMPORT_LIST);
    setListUrl(listUrlInput);
  }, [listUrlInput, setImportList, setListUrl, setModalView, tempList]);

  const renderableLists = useMemo(() => {
    return Object.keys(lists).filter((listUrl) => {
      // only show loaded lists, hide unsupported lists
      return (
        Boolean(lists[listUrl].current) &&
        !UNSUPPORTED_LIST_URLS.includes(listUrl)
      );
    });
  }, [lists]);

  return {
    listUrlInput,
    handleInput,
    addError,
    tempList,
    isImported,
    handleImport,
    renderableLists,
    disableListImport: false,
  };
};

export const useCurrencySearchContextSwap = () => {
  const [searchQuery, setSearchQuery] = useState<string>("");
  const debouncedQuery = useDebounce(searchQuery, 200);
  const searchToken = useToken(debouncedQuery);
  const allTokens = useAllTokens();

  const selectedTokenList = useCombinedActiveList();
  // console.log("debouncedQuery", debouncedQuery);
  // console.log("searchToken", searchToken);
  // console.log("searchQuery", searchQuery);
  const showFallbackTokens = true;

  return {
    searchQuery,
    setSearchQuery,
    debouncedQuery,
    searchToken,
    allTokens,
    selectedTokenList,
    showFallbackTokens,
  };
};
