import React from "react";
import { useCallback } from "react";
import { toast } from "react-toastify";
import TransactionPopup from "../../components/Popup/TransactionPopup";

export type PopupContent = {
  hash: string;
  success: boolean;
  summary?: string;
};

export function useAddPopup(): (
  content: PopupContent,
  autoClose?: number | false
) => void {
  return useCallback(
    (content: PopupContent, autoClose: number | false = 15000) => {
      toast.info(<TransactionPopup {...content} />, {
        position: "top-right",
        autoClose,
        icon: false,
        progressStyle: {
          background: "hsla(0,0%,100%,.7)",
        },
      });
    },
    []
  );
}
