import { CurrencyAmount } from "@ape.swap/sdk";
import { useWeb3React } from "@web3-react/core";
import { Currency, getNativeCurrency } from "entities/currency";
import { ERC20Token } from "entities/erc20token";
import { RawFraction } from "entities/fractions/fraction";
import { useMultiCallContract } from "hooks/useContract";
import JSBI from "jsbi";
import { useMemo } from "react";
import {
  useMultipleContractSingleData,
  useSingleContractMultipleData,
} from "state/multicall/multiCallHook";
import { isAddress } from "utils/addressHelpers";
import { ERC20_INTERFACE } from "utils/erc20";
import { parseBigintIsh } from "utils/numberHelpers";
import { RawCurrency } from "../entities/currency";
import { useNativeCurrencyBalance } from "./useNativeCurrencyBalance";

export function useNativeBalance(
  uncheckedAddresses?: (string | null | undefined)[]
): {
  [address: string]: { currency: RawCurrency; amount: RawFraction };
} {
  const { chainId, account } = useWeb3React();
  const multiCallContract = useMultiCallContract();
  const addresses = useMemo(
    () =>
      uncheckedAddresses && uncheckedAddresses.length > 0
        ? uncheckedAddresses
            .map(isAddress)
            .filter((a): a is string => a !== false)
        : [],
    [uncheckedAddresses]
  );

  const results = useSingleContractMultipleData(
    multiCallContract,
    "getEthBalance",
    account ? [[account]] : []
  );
  return useMemo(() => {
    return chainId
      ? addresses.reduce((memo: any, address, i) => {
          const value = results?.[i]?.result?.[0];
          const currency = getNativeCurrency(chainId);
          if (value && address)
            memo[address] = {
              currency,
              amount: new RawFraction(
                parseBigintIsh(JSBI.BigInt(value.toString())),
                JSBI.exponentiate(
                  JSBI.BigInt(10),
                  JSBI.BigInt(currency.decimals)
                )
              ),
            };
          return memo;
        }, {})
      : {};
  }, [results, addresses, chainId]);
}

export function useTokenBalances(
  address?: string | null,
  tokens?: any[]
): [
  {
    [tokenAddress: string]:
      | { currency: RawCurrency; amount: RawFraction }
      | undefined;
  },
  boolean
] {
  const validatedTokens = useMemo(
    () => tokens?.filter((t) => isAddress(t?.address) !== false) ?? [],
    [tokens]
  );

  const validatedTokenAddresses = useMemo(
    () => validatedTokens.map((vt) => vt.address),
    [validatedTokens]
  );

  const balances = useMultipleContractSingleData(
    validatedTokenAddresses,
    ERC20_INTERFACE,
    "balanceOf",
    useMemo(() => [address], [address])
  );

  const anyLoading: boolean = useMemo(
    () => balances.some((callState) => callState.loading),
    [balances]
  );

  return [
    useMemo(
      () =>
        address && validatedTokens && validatedTokens.length > 0
          ? validatedTokens.reduce((memo: any, token, i) => {
              const value = balances?.[i]?.result?.[0];
              const amount = value ? JSBI.BigInt(value.toString()) : undefined;
              if (amount && token)
                memo[token.address] = {
                  currency: token,
                  amount: new RawFraction(
                    parseBigintIsh(amount),
                    JSBI.exponentiate(
                      JSBI.BigInt(10),
                      JSBI.BigInt(token.decimals)
                    )
                  ),
                };
              return memo;
            }, {})
          : { error: "not data" },

      [balances, validatedTokens, address]
    ),
    anyLoading,
  ];
}

export function useCurrencyBalances(account?: string, currencies?: any[]) {
  const tokens = useMemo(
    () => currencies?.filter((currency) => currency?.address) ?? [],
    [currencies]
  );
  const tokenBalances = useTokenBalances(account, tokens)[0];
  const containsNativeCurrency = useMemo(
    () =>
      currencies?.some(
        (currency) => currency?.symbol === "BNB" || currency?.symbol === "ETH"
      ) ?? false,
    [currencies]
  );

  const nativeCurrencyBalance = useNativeBalance(
    containsNativeCurrency ? [account] : []
  );

  return useMemo(
    () =>
      currencies?.map((currency) => {
        if (!account || !currency) return undefined;
        if (currency.symbol === "BNB" || currency.symbol === "ETH")
          return nativeCurrencyBalance[account];
        else if (currency.address) return tokenBalances[currency.address];
        return undefined;
      }) ?? [],
    [account, currencies, nativeCurrencyBalance, tokenBalances]
  );
}

// export function useCurrencyBalancess(
//   account?: string,
//   currencies?: (Currency | undefined)[]
// ): (CurrencyAmount | undefined | any)[] {
//   const tokens = useMemo(
//     () => currencies?.filter((currency): currency is ERC20Token => currency instanceof ERC20Token) ?? [],
//     [currencies]
//   )

//   const tokenBalances = useTokenBalances(account, tokens)
//   const nativeCurrencyBalance = useNativeCurrencyBalance()

//   return useMemo(
//     () =>
//       currencies?.map(currency => {
//         if (!account || !currency) return undefined
//         if (currency instanceof Token) return tokenBalances[currency.address]
//         if (Currency.isNative(currency)) return nativeCurrencyBalance
//         return undefined
//       }) ?? [],
//     [account, currencies, nativeCurrencyBalance, tokenBalances]
//   )
// }
