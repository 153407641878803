import React from "react";
import { AlertCircle, CheckCircle } from "react-feather";

interface TransactionPopupProps {
  hash: string;
  success: boolean;
  summary?: string;
}

const TransactionPopup: React.FunctionComponent<TransactionPopupProps> = ({
  hash,
  success,
  summary,
}) => {
  return (
    <>
      <div style={{ paddingRight: 16 }} className="transaction--popup">
        <div>
          {success ? (
            <CheckCircle size={24} color={"green"} />
          ) : (
            <AlertCircle size={24} />
          )}
        </div>
        <div>
          <div>
            {summary ??
              "Hash: " + hash.slice(0, 8) + "..." + hash.slice(58, 65)}
          </div>
        </div>
      </div>
    </>
  );
};

export default TransactionPopup;
