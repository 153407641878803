import { createSlice } from '@reduxjs/toolkit';

const commonSlice = createSlice({
  name: 'common',
  initialState: { 
    redirectNotFound: false,
    redirectAccessForbidden: false,
    redirectLogin: false,
  },
  reducers: {
    redirectNotFound: (state) => {
      state.redirectNotFound = true;
    },
    redirectNotFoundDone: (state) => {
      state.redirectNotFound = true;
    },
    redirectAccessForbidden: (state) => {
      state.redirectAccessForbidden = true;
    },
    resetRedirectAccessForbidden: (state) => {
      state.redirectAccessForbidden = false;
    },
    redirectLogin: (state) => {
      state.redirectLogin = true;
    },
  },
});

export const {
 redirectNotFound, redirectNotFoundDone, redirectAccessForbidden, resetRedirectAccessForbidden, redirectLogin,
} = commonSlice.actions;

const commonReducer = commonSlice.reducer;
export default commonReducer;