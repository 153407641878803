import { arrayify } from "@ethersproject/bytes";
import { parseBytes32String } from "@ethersproject/strings";
import { useWeb3React } from "@web3-react/core";
import { Currency } from "entities/currency";
import { useMemo } from "react";
import { useSingleCallResult } from "state/multicall/multiCallHook";
import { isAddress } from "utils/addressHelpers";
import { useBytes32TokenContract, useTokenContract } from "./useContract";
import { useAllTokens } from "./useAllTokens";
import { useActiveWeb3React } from "./useActiveWeb3React";
// import {useNativeCurrency} from "./useNa"

const NEVER_RELOAD = { blocksPerFetch: Infinity };
const BYTES32_REGEX = /^0x[a-fA-F0-9]{64}$/;

function parseStringOrBytes32(
  str: string | undefined,
  bytes32: string | undefined,
  defaultValue: string
): string {
  return str && str.length > 0
    ? str
    : // need to check for proper bytes string and valid terminator
    bytes32 && BYTES32_REGEX.test(bytes32) && arrayify(bytes32)[31] === 0
      ? parseBytes32String(bytes32)
      : defaultValue;
}

export function useToken(tokenAddress: string | undefined): any | undefined {
  const { chainId } = useWeb3React();
  const address = isAddress(tokenAddress);
  const tokens = useAllTokens();

  const tokenContract = useTokenContract(address || undefined, false);
  const tokenContractBytes32 = useBytes32TokenContract(
    address || undefined,
    false
  );

  // get token from list tokens
  const token = address ? tokens[address] : undefined;

  const tokenName = useSingleCallResult(
    tokenContract,
    "name",
    undefined,
    NEVER_RELOAD
  );
  const tokenNameBytes32 = useSingleCallResult(
    tokenContractBytes32,
    "name",
    undefined,
    NEVER_RELOAD
  );
  const symbol = useSingleCallResult(
    tokenContract,
    "symbol",
    undefined,
    NEVER_RELOAD
  );
  const symbolBytes32 = useSingleCallResult(
    tokenContractBytes32,
    "symbol",
    undefined,
    NEVER_RELOAD
  );
  const decimals = useSingleCallResult(
    tokenContract,
    "decimals",
    undefined,
    NEVER_RELOAD
  );

  return useMemo(() => {
    if (token) return token;

    if (!chainId || !address) {
      return undefined
    };
    if (decimals.loading || symbol.loading || tokenName.loading)
      return undefined;
    if (decimals.result) {
      return {
        chainId,
        address,
        decimals: decimals.result[0],
        symbol: parseStringOrBytes32(
          symbol.result?.[0],
          symbolBytes32.result?.[0],
          "UNKNOWN"
        ),
        name: parseStringOrBytes32(
          tokenName.result?.[0],
          tokenNameBytes32.result?.[0],
          "UNKNOWN"
        ),
        image: "",
      };
    }
    return undefined;
  }, [
    address,
    chainId,
    decimals.loading,
    decimals.result,
    symbol.loading,
    symbol.result,
    token,
    symbolBytes32.result,
    tokenName.loading,
    tokenName.result,
    tokenNameBytes32.result,
  ]);
}

export function useCurrency(currencyId: string | undefined) {
  // default toke first reload
  const { chainId: activeChainId } = useActiveWeb3React();

  // const nativeCurrency = useNativeCurrency();

  const isBNB = currencyId?.toUpperCase() === "BNB";
  const isETH = currencyId?.toUpperCase() === "ETH";
  const token = useToken(isBNB || isETH ? undefined : currencyId);
  if (isBNB) return Currency.BNB;
  if (isETH) return Currency.ETH;
  return token;
}
