import React, { useCallback, useEffect, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";
import { useActiveWeb3React } from "../../hooks/useActiveWeb3React";
import { TransactionState } from "./reducer";
import { useBlockNumber } from "../../hooks/useBlockNumber";
import { useSelector } from "react-redux";
import { retry, RetryableError } from "../../utils/retry";
import { useAddPopup } from "../application/hook";
import { toast } from "react-toastify";
import TransactionPopup from "components/Popup/TransactionPopup";
import useNotification from "hooks/useNotification";
import { checkedTransaction } from "./actions";

interface UpdaterProps {}

export interface RetryOptions {
  n: number;
  minWait: number;
  maxWait: number;
}

interface TxInterface {
  addedTime: number;
  receipt?: Record<string, any>;
  lastCheckedBlockNumber?: number;
}

const DEFAULT_RETRY_OPTIONS: RetryOptions = {
  n: 10,
  minWait: 250,
  maxWait: 1000,
};

export function shouldCheck(lastBlockNumber: number, tx: TxInterface): boolean {
  if (tx.receipt) return false;
  if (!tx.lastCheckedBlockNumber) return true;
  const blocksSinceCheck = lastBlockNumber - tx.lastCheckedBlockNumber;
  if (blocksSinceCheck < 1) return false;
  const minutesPending = (new Date().getTime() - tx.addedTime) / 1000 / 60;
  if (minutesPending > 60) {
    // every 10 blocks if pending for longer than an hour
    return blocksSinceCheck > 9;
  } else if (minutesPending > 5) {
    // every 3 blocks if pending more than 5 minutes
    return blocksSinceCheck > 2;
  } else {
    // otherwise every block
    return true;
  }
}

const Updater: React.FunctionComponent<UpdaterProps> = (props) => {
  const { chainId, library } = useActiveWeb3React();
  const state = useSelector<RootState, RootState["transactions"]>(
    (state) => state.transactions
  );
  const lastBlockNumber = useBlockNumber();

  const transactions = useMemo(
    () => (chainId ? state[chainId] ?? {} : {}),
    [chainId, state]
  );

  const addPopup = useAddPopup();

  const dispatch = useAppDispatch();

  useEffect(() => {

    // library
    //   ?.getTransactionReceipt(
    //     "0x4cb28eb4be27efff5476e6cf827f9d24dd85c6ea7c55056bd98cd6e2e85122cc"
    //   )
    //   .then((res) => {
    //   });

    // toast.error(<TransactionPopup />, {
    //   position: "top-right",
    //   autoClose: 5000,
    //   hideProgressBar: false,
    //   closeOnClick: true,
    //   pauseOnHover: true,
    //   draggable: true,
    //   progress: undefined,
    //   theme: "dark",
    // });
  }, [chainId, library]);

  // need set time wait blockchain return
  const getTransactionReceipt = useCallback(
    (hash: string) => {
      if (!library || !chainId) throw new Error("No library or chainId");
      const retryOptions = DEFAULT_RETRY_OPTIONS;
      return retry(
        () =>
          library.getTransactionReceipt(hash).then((receipt) => {

            if (receipt === null) {
              throw new RetryableError();
            }

            return receipt;
          }),
        retryOptions
      );
    },
    [chainId, library]
  );

  useEffect(() => {
    if (!chainId || !library || !lastBlockNumber) return;

    const cancels = Object.values(transactions)
      .filter(({ hash }) => shouldCheck(lastBlockNumber, transactions[hash]))
      .map(({ hash, summary }) => {
        const { promise, cancel } = getTransactionReceipt(hash);
        promise
          .then((receipt) => {
            if (receipt) {
              addPopup({
                hash,
                success: receipt.status === 1,
                summary: transactions[hash]?.summary,
              });
            } else {
              dispatch(
                checkedTransaction({
                  chainId,
                  hash,
                  blockNumber: lastBlockNumber,
                })
              );
            }
          })
          .catch((error) => {
            console.log("ERROR WEB", { ...error });
          });

        return cancel;
      });
    return () => {
      cancels.forEach((cancel) => cancel());
    };
  }, [
    chainId,
    library,
    transactions,
    lastBlockNumber,
    dispatch,
    addPopup,
    getTransactionReceipt,
  ]);

  return null;
};

export default Updater;
