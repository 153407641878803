import { Col, Input, Row } from 'antd';
import React from 'react';
import { BiRefresh } from "react-icons/bi";
const { Search } = Input;

interface AssetSearchFormProps { }

export const AssetSearchForm: React.FunctionComponent<AssetSearchFormProps> = () => {

  return (
    <div>
      <Row className="d-flex align-items-center">
        <Col span={22}>
          <Search />
        </Col>
        <Col span={2}>
          <BiRefresh className="ml-2 asset__hover d-flex align-items-center" size={32} />
        </Col>
      </Row>
    </div>
  );
};
