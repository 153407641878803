import React from "react";
import BannerImage from "assets/images/banner/cryptik-banner-three-thumb.svg";
import { Image } from "antd";
interface HeroProps {}

const Hero: React.FunctionComponent<HeroProps> = (props) => {
  return (
    <>
      <section className="hero">
        <div className="hero--banner">
          <Image preview={false} src={BannerImage} />
        </div>
        <div className="hero--heading">
          <div className="hero--heading__title">
          Unlock the Wonders of Decentralized Exchange
          </div>
          <div className="hero--heading__sub">
          Through our platform, we aim to provide a seamless experience, empowering users with the necessary tools and information to navigate the ever-evolving world of decentralized finance. Join us on this journey of discovery and unlock the wonders of decentralized trading.
          </div>
          <div className="hero--heading__register">
            <input type="text" placeholder="Go to Swap" disabled/>
            <a href="/swap"><button className="btn"> Launch App</button></a>
          </div>
          <div className="hero--heading__alert">
          Kindly note that the Dexwonder token is presently unavailable. Stay tuned for upcoming updates and exciting announcements about the Dexwonder token.
          </div>
        </div>
      </section>
      
    </>
  );
};

export default Hero;
