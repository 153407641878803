import React from "react";
import { AlertTriangle } from "react-feather";

interface TransactionErrorContentProps {
    onDismiss: () => void
}

const TransactionErrorContent: React.FunctionComponent<
  TransactionErrorContentProps
> = ({onDismiss}) => {
  return (
    <>
      <div className="modal--swap--confirm--error">
        <AlertTriangle color="#c83737" style={{ strokeWidth: 1.5 }} size={64} />
        <div className="modal--swap--confirm--error__text">
          Transaction rejected
        </div>
        <button className="btn btn--dismiss" onClick={onDismiss}>Dismiss</button>
      </div>
    </>
  );
};

export default TransactionErrorContent;
