import { useCallback } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import { ListRowContextType } from "../../../models/manageList/ListRowContextType";
import {
  acceptListUpdate,
  disableList,
  enableList,
  removeList,
} from "../../../state/lists/actions";
import { useActiveListUrls, useAllLists } from "../../../state/lists/hooks";

export const useListRowContextSwap = (): ListRowContextType => {
  const dispatch = useAppDispatch();
  const listsByUrl = useAllLists();

  const activeListUrls = useActiveListUrls();

  const isActiveList = useCallback(
    (listUrl: string) => Boolean(activeListUrls?.includes(listUrl)),
    [activeListUrls]
  );

  const handleAcceptListUpdate = useCallback(
    (listUrl: string) => dispatch(acceptListUpdate(listUrl)),
    [dispatch]
  );
  const handleRemoveList = useCallback(
    (listUrl: string) => dispatch(removeList(listUrl)),
    [dispatch]
  );
  const handleEnableList = useCallback(
    (listUrl: string) => dispatch(enableList(listUrl)),
    [dispatch]
  );
  const handleDisableList = useCallback(
    (listUrl: string) => {
      dispatch(disableList(listUrl));
    },
    [dispatch]
  );

  return {
    disableListInfo: false,
    listsByUrl,
    isActiveList,
    handleAcceptListUpdate,
    handleRemoveList,
    handleEnableList,
    handleDisableList,
  };
};
