import { getAddress } from '@ethersproject/address';
import addresses from 'config/constants/contracts';

export function getMultiCallAddress(chainId?: number) {
  if (!chainId) return undefined;
  switch (chainId) {
    case 1:
      return addresses.mulltiCall[1];
    // case 3:
    //   return addresses.mulltiCall[3];
    case 97:
      return addresses.mulltiCall[97];
    case 56:
      return addresses.mulltiCall[56];
    default:
      return addresses.mulltiCall[137];
  }
}

export function isAddress(address: any): string | false {
  try {
    return getAddress(address);
  } catch {
    return false;
  }
}
