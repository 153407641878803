import React from "react";
import { TokenList } from "@uniswap/token-lists";
import { Image } from "antd";
import { CheckCircle, Settings } from "react-feather";

interface CardImportProps {
  tempList: TokenList;
  isImported: boolean | undefined;
  handleImport: any;
}

const CardImport: React.FunctionComponent<CardImportProps> = ({
  tempList,
  isImported,
  handleImport,
}) => {
  return (
    <div className="manage--tokens--import">
      <div className="manage--tokens--import__info">
        <Image src={tempList.logoURI} preview={false} width={35} height={35} />
        <div className="manage--tokens--import__info__nameEx">
          <div>{tempList.name}</div>
          <div>{tempList.tokens.length} tokens</div>
        </div>
      </div>
      <div className="manage--tokens--import__btn">
        {isImported ? (
          <div className="">
            <CheckCircle size={16} />
            <span>Loaded</span>
          </div>
        ) : (
          <button className="btn btn--import" onClick={handleImport}>
            Import
          </button>
        )}
      </div>
    </div>
  );
};

export default CardImport;
