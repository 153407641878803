import JSBI from 'jsbi';
import { Percent } from '@ape.swap/sdk';
import { SmartRouter, WETH, Token } from '@ape.swap/sdk';
import { ChainId } from 'libs/pancake-swap';

// exports for external consumption
export type BigintIsh = JSBI | bigint | string;

export enum TradeType {
  EXACT_INPUT,
  EXACT_OUTPUT,
}

export const FACTORY_ADDRESS = '0x0841BD0B734E4F5853f0dD8d7Ea041c241fb0Da6';

export const FACTORY_ADDRESS_MAP = {
  [ChainId.MAINNET]: FACTORY_ADDRESS,
  [ChainId.TESTNET]: '0x152349604d49c2af10adee94b918b051104a143e',
};

export const INIT_CODE_HASH = '0xf4ccce374816856d11f00e4069e7cada164065686fbef53c6167a63ec2fd8c5b';

export const INIT_CODE_HASH_MAP = {
  [ChainId.MAINNET]: INIT_CODE_HASH,
  [ChainId.TESTNET]: '0xf4ccce374816856d11f00e4069e7cada164065686fbef53c6167a63ec2fd8c5b',
};

export const MINIMUM_LIQUIDITY = JSBI.BigInt(1000);

// exports for internal consumption
export const ZERO = JSBI.BigInt(0);
export const ONE = JSBI.BigInt(1);
export const TWO = JSBI.BigInt(2);
export const THREE = JSBI.BigInt(3);
export const FIVE = JSBI.BigInt(5);
export const TEN = JSBI.BigInt(10);
export const _100 = JSBI.BigInt(100);
export const _998 = JSBI.BigInt(998);
export const _1000 = JSBI.BigInt(1000);

// export enum SolidityType {
//   uint8 = 'uint8',
//   uint256 = 'uint256',
// }

// export const SOLIDITY_TYPE_MAXIMA = {
//   [SolidityType.uint8]: JSBI.BigInt('0xff'),
//   [SolidityType.uint256]: JSBI.BigInt(
//     '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff'
//   ),
// };

export enum RouterTypes {
  APE = 'APE',
  SMART = 'SMART',
  BONUS = 'BONUS',
}

export const ZERO_PERCENT = new Percent('0');
export const ONE_HUNDRED_PERCENT = new Percent('1');

export const BIPS_BASE = JSBI.BigInt(10000);
export const BETTER_TRADE_LESS_HOPS_THRESHOLD = new Percent(JSBI.BigInt(50), BIPS_BASE);

// default allowed slippage, in bips
export const INITIAL_ALLOWED_SLIPPAGE = 50;
export const INITIAL_ZAP_SLIPPAGE = 100;
// 20 minutes, denominated in seconds
export const DEFAULT_DEADLINE_FROM_NOW = 60 * 20;

// one basis point
export const ONE_BIPS = new Percent(JSBI.BigInt(1), JSBI.BigInt(10000));

export const ALLOWED_PRICE_IMPACT_LOW: Percent = new Percent(JSBI.BigInt(100), BIPS_BASE); // 1%
export const ALLOWED_PRICE_IMPACT_MEDIUM: Percent = new Percent(JSBI.BigInt(300), BIPS_BASE); // 3%
export const ALLOWED_PRICE_IMPACT_HIGH: Percent = new Percent(JSBI.BigInt(500), BIPS_BASE); // 5%
// if the price slippage exceeds this number, force the user to type 'confirm' to execute
export const PRICE_IMPACT_WITHOUT_FEE_CONFIRM_MIN: Percent = new Percent(
  JSBI.BigInt(1000),
  BIPS_BASE
); // 10%
// for non expert mode disable swaps above this
export const BLOCKED_PRICE_IMPACT_NON_EXPERT: Percent = new Percent(JSBI.BigInt(1500), BIPS_BASE); // 15%

export const BONUS_CUTOFF_AMOUNT: Partial<Record<ChainId, number>> = {
  [ChainId.MAINNET]: 0.5,
  [ChainId.TESTNET]: 0,
};

// Wallchain Configs
// If a router is in the priority list for a certain chain it must be added to the wallchain params
export const WALLCHAIN_PARAMS: Partial<
  Record<ChainId, Partial<Record<SmartRouter, { apiUrl: string; apiKey: string }>>>
> = {
  [ChainId.MAINNET]: {
    [SmartRouter.APE]: {
      apiUrl: 'https://bsc.wallchains.com/upgrade_txn/',
      apiKey: '85c578a5-ecb0-445c-8a95-4c0eba2f33b6',
    },
    [SmartRouter.PANCAKE]: {
      apiUrl: 'https://bsc.wallchains.com/upgrade_txn/',
      apiKey: 'c5f0eb9a-180b-4787-a5c0-db68292f6926',
    },
    [SmartRouter.BISWAP]: {
      apiUrl: 'https://bsc.wallchains.com/upgrade_txn/',
      apiKey: '1cdb6a88-fc95-4831-906a-9ed0e16c9c52',
    },
  },
  [ChainId.TESTNET]: {
    [SmartRouter.APE]: {
      apiUrl: 'https://bsc.wallchains.com/upgrade_txn/',
      apiKey: '85c578a5-ecb0-445c-8a95-4c0eba2f33b6',
    },
  },
};

export const BLOCK_EXPLORER: Partial<Record<ChainId, string>> = {
  [ChainId.MAINNET]: 'https://bscscan.com',
  [ChainId.TESTNET]: 'https://testnet.bscscan.com/',
};

// Network RPC nodes
export const NETWORK_RPC: Partial<Record<ChainId, string[]>> = {
  [ChainId.MAINNET]: [
    'https://bsc-dataseed1.ninicoin.io',
    'https://bsc-dataseed.binance.org/',
    'https://bsc-dataseed1.defibit.io',
  ],
  [ChainId.TESTNET]: ['https://data-seed-prebsc-2-s3.binance.org:8545/'],
};

// Ape price impact cutoff
export const APE_PRICE_IMPACT = 15;

// This sets the priority of when a router is used
// After APE router should be in order of highest liquidity
export const PRIORITY_SMART_ROUTERS: Partial<Record<ChainId, SmartRouter[]>> = {
  [ChainId.MAINNET]: [SmartRouter.APE, SmartRouter.PANCAKE, SmartRouter.BISWAP],
  [ChainId.TESTNET]: [SmartRouter.APE],
};

export const WRAPPED_NATIVE_ONLY: ChainTokenList = {
  [ChainId.MAINNET]: [WETH[ChainId.MAINNET]],
  [ChainId.TESTNET]: [WETH[ChainId.TESTNET]],
}

export const BASES_TO_CHECK_TRADES_AGAINST: ChainTokenList = {
  ...WRAPPED_NATIVE_ONLY,
  [ChainId.MAINNET]: [
    ...WRAPPED_NATIVE_ONLY[ChainId.MAINNET],
  ],
  [ChainId.TESTNET]: [
    ...WRAPPED_NATIVE_ONLY[ChainId.TESTNET],
  ],
}

export const CUSTOM_BASES: { [chainId in ChainId]?: { [tokenAddress: string]: Token[] } } = {
  [ChainId.MAINNET]: {},
}

/**
 * Addittional bases for specific tokens
 * @example { [WBTC.address]: [renBTC], [renBTC.address]: [WBTC] }
 */
 export const ADDITIONAL_BASES: { [chainId in ChainId]?: { [tokenAddress: string]: Token[] } } = {
  [ChainId.MAINNET]: {},
}

// a list of tokens by chain
type ChainTokenList = {
  readonly [chainId in ChainId]: Token[]
}

export const SMART_LP_FEES: Partial<Record<ChainId, Partial<Record<SmartRouter, number>>>> = {
  [ChainId.MAINNET]: {
    [SmartRouter.APE]: 20,
    [SmartRouter.PANCAKE]: 25,
    [SmartRouter.BISWAP]: 10,
  },
  [ChainId.TESTNET]: {
    [SmartRouter.APE]: 20,
    [SmartRouter.PANCAKE]: 25,
  },
}