import { WrappedTokenInfo } from "./wrapped-token-info";
import { UNSUPPORTED_LIST_URLS } from "../../constants/lists";
import { useEffect, useMemo, useState } from "react";

import { ActionCreatorWithPayload, createAction } from "@reduxjs/toolkit";
import { TokenList } from "@uniswap/token-lists";
import { ChainId } from "constants/chainIds";
import { RootState } from "app/store";
import { useSelector } from "react-redux";
import sortByListPriority from "../../utils/listSort";
import { useActiveWeb3React } from "hooks/useActiveWeb3React";
import { Currency } from "entities/currency";
import { useAppDispatch } from "app/hooks";
import { useNativeCurrency } from "hooks/useNativeCurrency";
import { Field } from "state/swap/types";
import { isAddress } from "utils/addressHelpers";
import { useParsedQueryString } from "../../hooks/useParsedQueryString";
import {
  DEFAULT_OUTPUT_CURRENCY,
  PRE_SELECT_OUTPUT_CURRENCY_ID,
} from "../../constants/index";
import { replaceSwapState, SwapDelay } from "state/swap/actions";
import { RouterTypes } from "libs/ape-swap/constants";
import { SmartRouter } from "@ape.swap/sdk";
import { USDT } from "../../tokens/common";

export type TokenAddressMap = Readonly<{
  [chainId: number]: Readonly<{
    [tokenAddress: string]: { token: WrappedTokenInfo; list: TokenList };
  }>;
}>;

const listCache: WeakMap<TokenList, TokenAddressMap> | null =
  typeof WeakMap !== "undefined"
    ? new WeakMap<TokenList, TokenAddressMap>()
    : null;

function combineMaps(
  map1: TokenAddressMap,
  map2: TokenAddressMap
): TokenAddressMap {
  const chainList = [
    ChainId.MAINNET,
    ChainId.BNB,
    ChainId.tBNB,
    ChainId.GOERLI,
    ChainId.ROPSTEN,
  ];
  return Object.assign(
    {},
    ...chainList.map((chain) => ({
      [chain]: { ...map1[chain], ...map2[chain] },
    }))
  );
}

export function listToTokenMap(
  list: TokenList | null,
  useCache = true
): TokenAddressMap | any {
  if (!list) return {};
  if (useCache) {
    const result = listCache?.get(list);
    if (result) return result;
  }

  const map = list.tokens.reduce<TokenAddressMap>((tokenMap, tokenInfo) => {
    const token = new WrappedTokenInfo(tokenInfo, list);
    if (tokenMap[token.chainId]?.[token.address] !== undefined) {
      console.error(new Error(`Duplicate token! ${token.address}`));
      return tokenMap;
    }
    return {
      ...tokenMap,
      [token.chainId]: {
        ...tokenMap[token.chainId],
        [token.address]: {
          token,
          list,
        },
      },
    };
  }, {});
  if (useCache) listCache?.set(list, map);
  return map;
}

export function useInactiveListUrls(): string[] {
  const lists = useAllLists();
  const allActiveListUrls = useActiveListUrls();
  return Object.keys(lists).filter(
    (url) =>
      !allActiveListUrls?.includes(url) && !UNSUPPORTED_LIST_URLS.includes(url)
  );
}

const selectListByUrl = (state: RootState): RootState["lists"]["byUrl"] =>
  state.lists.byUrl;

export function useAllLists() {

  return useSelector(selectListByUrl);
}

function useCombinedTokenMapFromUrls(
  urls: string[] | undefined
): TokenAddressMap | any {
  const lists = useAllLists();
  return useMemo(() => {
    if (!urls) return {};
    return (
      urls
        .slice()
        // sort by priority so top priority goes last
        .sort(sortByListPriority)
        .reduce((allTokens, currentUrl) => {
          const current = lists[currentUrl]?.current;
          if (!current) return allTokens;
          try {
            return combineMaps(allTokens, listToTokenMap(current));
          } catch (error) {
            console.log("[ERROR]Could not show token list due to error", error);
            return allTokens;
          }
        }, {})
    );
  }, [lists, urls]);
}

export function useActiveListUrls(): string[] | undefined {
  return useSelector<RootState, RootState["lists"]["activeListUrls"]>(
    (state) => state.lists.activeListUrls
  )?.filter((url) => !UNSUPPORTED_LIST_URLS.includes(url));
}

export function useCombinedActiveList(): TokenAddressMap {
  const activeListUrls = useActiveListUrls();

  return useCombinedTokenMapFromUrls(activeListUrls);
}

export function useTokenInfoFromActiveListOnCurrentChain(
  currency?: Currency
): WrappedTokenInfo | undefined {
  const { chainId } = useActiveWeb3React();
  const activeListUrls = useActiveListUrls();
  const combinedList = useCombinedTokenMapFromUrls(activeListUrls);

  return useMemo(() => {
    if (!currency || !currency.address) return undefined;
    const list = combinedList[chainId || ChainId.MAINNET];
    if (!list) return undefined;
    const tokenFromList = list[currency.address];
    if (!tokenFromList) return undefined;
    return tokenFromList.token;
  }, [chainId, combinedList, currency]);
}

type SearchParams = { [k: string]: string };

type QueryParametersToSwapStateResponse = {
  independentField: Field;
  typedValue: string;
  [Field.INPUT]: {
    currencyId: string | undefined;
  };
  [Field.OUTPUT]: {
    currencyId: string | undefined;
  };
  recipient: string | null;
};

const ENS_NAME_REGEX =
  /^[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&/=]*)?$/;
const ADDRESS_REGEX = /^0x[a-fA-F0-9]{40}$/;

function parseCurrencyFromURLParameter(
  urlParam: any,
  nativeCurrencyId: string
): string {
  if (typeof urlParam === "string") {
    const valid = isAddress(urlParam);
    if (valid) return valid;
    if (urlParam.toUpperCase() === nativeCurrencyId) return nativeCurrencyId;
    if (valid === false) return nativeCurrencyId;
  }
  return nativeCurrencyId ?? "";
}

function validatedRecipient(recipient: any): string | null {
  if (typeof recipient !== "string") return null;
  const address = isAddress(recipient);
  if (address) return address;
  if (ENS_NAME_REGEX.test(recipient)) return recipient;
  if (ADDRESS_REGEX.test(recipient)) return recipient;
  return null;
}

function parseTokenAmountURLParameter(urlParam: any): string {
  return typeof urlParam === "string" && !isNaN(parseFloat(urlParam))
    ? urlParam
    : "";
}

function parseIndependentFieldURLParameter(urlParam: any): Field {
  return typeof urlParam === "string" && urlParam.toLowerCase() === "output"
    ? Field.OUTPUT
    : Field.INPUT;
}

export function queryParametersToSwapState(
  parsedQs: SearchParams,
  nativeCurrencyId: string,
  defaultOutputCurrency?: string,
  nativeSymbol?: string
): QueryParametersToSwapStateResponse {
  let inputCurrency = parseCurrencyFromURLParameter(
    parsedQs.inputCurrency,
    nativeCurrencyId
  );

  let outputCurrency =
    typeof parsedQs.outputCurrency === "string"
      ? isAddress(parsedQs.outputCurrency) || nativeSymbol
      : defaultOutputCurrency ?? DEFAULT_OUTPUT_CURRENCY;

  if (inputCurrency === outputCurrency) {
    if (typeof parsedQs.outputCurrency === "string") {
      inputCurrency = "";
    } else {
      outputCurrency = "";
    }
  }

  const recipient = validatedRecipient(parsedQs.recipient);

  return {
    [Field.INPUT]: {
      currencyId: inputCurrency,
    },
    [Field.OUTPUT]: {
      currencyId: outputCurrency,
    },
    typedValue: parseTokenAmountURLParameter(parsedQs.exactAmount),
    independentField: parseIndependentFieldURLParameter(parsedQs.exactField),
    recipient,
  };
}

// updates the swap state to use the defaults for a given network
export function useDefaultsFromURLSearch():
  | {
    inputCurrencyId: string | undefined;
    outputCurrencyId: string | undefined;
  }
  | undefined {
  const { chainId = 56 } = useActiveWeb3React();
  const parsedQs = useParsedQueryString();
  const dispatch = useAppDispatch();
  const nativeCurrency = useNativeCurrency(chainId);

  const [result, setResult] = useState<
    | {
      inputCurrencyId: string | undefined;
      outputCurrencyId: string | undefined;
    }
    | undefined
  >();
  useEffect(() => {
    if (!chainId) {
      return
    };
    const parsed = queryParametersToSwapState(
      parsedQs,
      "BNB",
      chainId === 56
        ? USDT[chainId]?.address
        : "0x337610d27c682E347C9cD60BD4b3b107C9d34dDd",
      nativeCurrency[0].symbol
    ); // default 2 token input
    const outputCurrencyId = !!parsed[Field.OUTPUT].currencyId?.length
      ? parsed[Field.OUTPUT].currencyId
      : PRE_SELECT_OUTPUT_CURRENCY_ID[chainId];

    dispatch(
      replaceSwapState({
        typedValue: parsed.typedValue,
        field: parsed.independentField,
        inputCurrencyId: parsed[Field.INPUT].currencyId,
        outputCurrencyId,
        recipient: parsed.recipient,
        swapDelay: SwapDelay.INIT,
        bestRoute: {
          routerType: RouterTypes.APE,
          smartRouter: SmartRouter.APE,
        },
      })
    );
  }, [dispatch, chainId]);
  return result;
}

export const fetchTokenList: Readonly<{
  pending: ActionCreatorWithPayload<{ url: string; requestId: string }>;
  fulfilled: ActionCreatorWithPayload<{
    url: string;
    tokenList: TokenList;
    requestId: string;
  }>;
  rejected: ActionCreatorWithPayload<{
    url: string;
    errorMessage: string;
    requestId: string;
  }>;
}> = {
  pending: createAction("lists/fetchTokenList/pending"),
  fulfilled: createAction("lists/fetchTokenList/fulfilled"),
  rejected: createAction("lists/fetchTokenList/rejected"),
};
