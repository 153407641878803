import * as React from "react";

import { Input } from "antd";

interface ISearchFieldProps {
  value?: string | undefined;
  name: string;
  onChange: any;
  placeholder: string;
}

const SearchField: React.FunctionComponent<ISearchFieldProps> = ({
  onChange,
  placeholder,
  name,
  value,
}) => {
  return (
    <>
      <div className="search-input-container">
        <div className="search-input-container__wrapper">
          <input
            value={value}
            type="text"
            placeholder={placeholder}
            className="search-input-container__input"
            onChange={onChange}
            name={name}
          />
        </div>
        <div style={{ maxHeight: "19px" }}>
          <svg
            width="18"
            height="19"
            viewBox="0 0 18 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M13.6375 12.4807C15.965 9.49155 15.7547 5.16699 13.0069 2.41913C10.0314 -0.556376 5.20713 -0.556376 2.23163 2.41913C-0.743876 5.39463 -0.743876 10.2189 2.23163 13.1944C4.97949 15.9422 9.30405 16.1525 12.2932 13.825C12.3061 13.8396 12.3195 13.8539 12.3334 13.8678L16.3741 17.9085C16.7461 18.2805 17.3491 18.2805 17.721 17.9085C18.093 17.5366 18.093 16.9336 17.721 16.5616L13.6803 12.5209C13.6664 12.507 13.6521 12.4936 13.6375 12.4807ZM11.66 3.76603C13.8916 5.99766 13.8916 9.61584 11.66 11.8475C9.42834 14.0791 5.81016 14.0791 3.57853 11.8475C1.34691 9.61584 1.34691 5.99766 3.57853 3.76603C5.81016 1.53441 9.42834 1.53441 11.66 3.76603Z"
              fill="#939598"
            />
          </svg>
        </div>
      </div>
    </>
  );
};

export default SearchField;
