import React, { CSSProperties } from "react";

interface LoadingPlaceholderProps {
  extraStyles: CSSProperties;
  container?: CSSProperties;
}

const LoadingPlaceholder: React.FunctionComponent<LoadingPlaceholderProps> = (
  props
) => {
  return (
    <>
      <div
        className="loader"
        style={{
          position: props.container ? "absolute" : "relative",
          ...props.extraStyles,
        }}
      >
        <div className="loader--swipe"></div>
      </div>
    </>
  );
};

export default LoadingPlaceholder;
