import { ERC20Token } from "entities/erc20token";
import CurrencyLogo from "features/manageLists/components/CurrencyLogo";
import { useActiveWeb3React } from "hooks/useActiveWeb3React";
import { getExplorerLink } from "../../../utils/getExploreLink";
import React from "react";

interface TokenInfoProps {
  token: ERC20Token;
}

const TokenInfo: React.FunctionComponent<TokenInfoProps> = ({ token }) => {
  const { chainId = 0 } = useActiveWeb3React();

  return (
    <>
      <CurrencyLogo currency={token} />
      <div className="manage--tokens--import--token__info__right">
        <div className="manage--tokens--import--token__info__right__network">
          <span>{token.name}</span>
          <span>{token.symbol}</span>
        </div>
        <div className="manage--tokens--import--token__info__right__link">
          <a
            target="_blank"
            href={getExplorerLink(chainId, token.address, "token")}
          >
            {`${token.address.substring(0, 6)}...${token.address.substring(
              38
            )}`}{" "}
            <span>View on Explorer</span>
          </a>
        </div>
      </div>
    </>
  );
};

export default TokenInfo;
