import { useWeb3React } from "@web3-react/core";

import { useAppDispatch } from "app/hooks";
import ChervonDown from "assets/icons/ChervonDown";
import SwapIcon from "assets/icons/SwapIcon";
import BigNumber from "bignumber.js";
import CurrencyInputPanel from "components/CurrencyInputPanel/CurrencyInputPanel";
import { Currency } from "entities/currency";
import {
  useNetworkSwitcherToggle,
  useUnsupportedChainIdError,
  useWalletModalToggle,
} from "hooks";
import { useCurrency } from "hooks/useCurrency";
import { useSwapRoute } from "hooks/useSwapRoute";
import { useApeSwapCallback } from "libs/ape-swap/hooks/useApeSwapCallback";
import { useSwapCallback as useBiSwapCallback } from "libs/bi-swap/hooks/useSwapCallback";
import { Trade } from "libs/pancake-swap";
import {
  ApprovalState,
  useApproveCallbackFromTrade,
} from "libs/pancake-swap/hooks/useApproveCallback";
import { useSwapCallback } from "libs/pancake-swap/hooks/useSwapCallback";
import React, {
  Dispatch,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDerivedSwapInfo, useSwapState } from "state/swap/swapHook";
import { switchCurrencies, typeInput } from "state/swap/swapSlice";
import { Field } from "state/swap/types";
import { useUserSlippageTolerance } from "state/user/userHook";
import ConfirmSwapModal from "../../../components/ConfirmSwapModal/pages/ConfirmSwapModal";
import { useSwapHandler } from "../../../hooks/useSwapHandler";
import { useDefaultsFromURLSearch } from "../../../state/lists/hooks";
import AdvanceMore from "./AdvanceMore";
import CurrencyInput from "./currencyInput/CurrencyInput";
import SwapAdvance from "./SwapAdvance";

interface SwapTabProps {
  setIsEnter: Dispatch<boolean>;
  platformOverride: string;
  setPlatformOverride: Dispatch<string>;
}

export type SwapData = {
  inputSwapData?: Currency | null;
  outputSwapData?: Currency | null;
  showConfirm: boolean;
  tradeToConfirm: Trade | undefined;
  attemptingTxn: boolean;
  swapErrorMessage: string | undefined;
  txHash: string | undefined;
};

const SwapTab: React.FunctionComponent<SwapTabProps> = ({
  setIsEnter,
  platformOverride,
  setPlatformOverride,
}) => {
  // const { account } = useWeb3React();
  const { active, activate, account, error } = useWeb3React();
  const unsupportedChainIdError = useUnsupportedChainIdError();
  const [allowedSlippage] = useUserSlippageTolerance();
  const [showInvertedPrice, setShowInvertedPrice] = useState(false);
  const toggleNetworkSwitcher = useNetworkSwitcherToggle();

  const isLoaded = useDefaultsFromURLSearch();

  const {
    independentField,
    typedValue,
    recipient,
    [Field.INPUT]: { currencyId: inputCurrencyId },
    [Field.OUTPUT]: { currencyId: outputCurrencyId },
  } = useSwapState();

  const [platformTrade, setPlatformTrade] = useState<any>();

  const inputCurrency = useCurrency(inputCurrencyId); // get token info that has logoURI

  const outputCurrency = useCurrency(outputCurrencyId);

  const {
    currencyBalances,
    trade,
    mdexTrade,
    biswapTrade,
    apeswapTrade,
    apeswapBestRoute,
    currencies,
  } = useDerivedSwapInfo(
    independentField,
    typedValue,
    inputCurrency,
    outputCurrency,
    recipient
  );

  const { computeApeswapTradePriceBreakdownObj } = useSwapRoute({
    currencyBalances,
    pancakeTrade: trade,
    mdexTrade,
    biswapTrade,
    apeswapTrade,
  });

  const [approvalState, approveCallback] = useApproveCallbackFromTrade(
    trade.trade,
    allowedSlippage
  );

  const parsedAmounts = useMemo(() => {
    let _trade;
    if (platformOverride === "PancakeSwap") _trade = trade;
    if (platformOverride === "MdexSwap") _trade = mdexTrade;
    if (platformOverride === "Biswap") _trade = biswapTrade;
    if (platformOverride === "ApeSwap") _trade = apeswapTrade;

    return (
      _trade && {
        [Field.INPUT]:
          independentField === Field.INPUT
            ? _trade.parsedAmount
            : _trade?.trade?.inputAmount,
        [Field.OUTPUT]:
          independentField === Field.OUTPUT
            ? _trade.parsedAmount
            : _trade?.trade?.outputAmount,
      }
    );
  }, [
    independentField,
    platformOverride,
    trade,
    mdexTrade,
    apeswapTrade,
    biswapTrade,
  ]);

  const insufficeBalance = useMemo(() => {
    const balanceStr = currencyBalances[Field.INPUT]?.amount.toSignificant(
      18
    ) as string;
    const typeAmountStr =
      parsedAmounts &&
      (parsedAmounts[Field.INPUT]?.toSignificant(18) as string);
    if (!balanceStr) return true;
    if (!typeAmountStr) return false;

    const balance = new BigNumber(balanceStr);
    const typeAmount = new BigNumber(typeAmountStr);

    return balance.lt(typeAmount);
  }, [currencyBalances, parsedAmounts]);

  const dependentField: Field =
    independentField === Field.INPUT ? Field.OUTPUT : Field.INPUT;

  const formattedAmounts = useMemo(
    () => ({
      [independentField]: typedValue ?? "",
      [dependentField]:
        (parsedAmounts && parsedAmounts[dependentField]?.toSignificant(6)) ??
        "",
    }),
    [independentField, dependentField, parsedAmounts, typedValue]
  );

  const formattedPrice = useMemo(() => {
    if (!trade || !trade.trade || !trade.trade.executionPrice) return "Pricing";
    const executionPrice = trade.trade.executionPrice;
    const label = showInvertedPrice
      ? `${executionPrice.quoteCurrency.symbol} per ${executionPrice.baseCurrency.symbol}`
      : `${executionPrice.baseCurrency.symbol} per ${executionPrice.quoteCurrency.symbol}`;
    const price = showInvertedPrice
      ? executionPrice.toSignificant(6)
      : executionPrice.invert().toSignificant(6);
    return `${price} ${label}`;
    // return "-";
  }, [showInvertedPrice, trade]);

  const [amount0, setAmount0] = useState<string>("");
  const [amount1, setAmount1] = useState<string>("");

  const toggleWalletSwitcher = useWalletModalToggle();
  const dispatch = useAppDispatch();

  const reverse = () => {
    dispatch(switchCurrencies());
  };

  const switchPrice = useCallback(() => {
    setShowInvertedPrice(!showInvertedPrice);
  }, [showInvertedPrice]);

  const onMax = (field: Field) => {
    if (field === Field.INPUT) {
      const value = currencyBalances
        ? currencyBalances[Field.INPUT]?.amount.toFixed(4)
        : "0";
      dispatch(
        typeInput({
          field: Field.INPUT,
          typedValue: value ?? "0",
        })
      );
    }
  };

  const timer = useRef<NodeJS.Timeout>();
  const isChanging = useRef<boolean>(false);
  const onHandleChange = (e: any, field: Field) => {
    isChanging.current = true;
    if (timer.current) clearTimeout(timer.current);
    timer.current = setTimeout(() => {
      isChanging.current = false;
      dispatch(
        typeInput({
          field: field,
          typedValue: e.target.value,
        })
      );
    }, 1500);
  };

  useEffect(() => {
    if (isChanging.current) return;
    setAmount0(formattedAmounts[Field.INPUT]);
    setAmount1(formattedAmounts[Field.OUTPUT]);
  }, [formattedAmounts]);

  const [isShowSwapRoute, setIsShowSwapRoute] = useState<boolean>(false);
  const [isShowAdvance, setIsShowAdvance] = useState<boolean>(false);

  // modal and loading
  const [
    {
      showConfirm,
      tradeToConfirm,
      swapErrorMessage,
      attemptingTxn,
      txHash,
      inputSwapData,
      outputSwapData,
    },
    setSwapState,
  ] = useState<SwapData>({
    inputSwapData: null,
    outputSwapData: null,
    showConfirm: false,
    tradeToConfirm: undefined,
    attemptingTxn: false,
    swapErrorMessage: undefined,
    txHash: undefined,
  });

  const onSwapHandler = useSwapHandler({
    independentField,
    typedValue,
    inputCurrency,
    outputCurrency,
    recipient,
  });
  const [isConfirm, setIsConfirm] = useState<boolean>(false);

  const onSwapAction = () => {
    setIsConfirm(true);
    if (onSwapHandler) {
      if (platformOverride === "PancakeSwap") {
        setPlatformTrade(trade);
      }

      if (platformOverride === "MdexSwap") {
        setPlatformTrade(mdexTrade);
      }

      if (platformOverride === "Biswap") {
        setPlatformTrade(biswapTrade);
      }

      if (platformOverride === "ApeSwap") {
        setPlatformTrade(apeswapTrade);
        // onSwapHandler.Apeswap && onSwapHandler.Apeswap();
      }

      setSwapState((state) => ({
        ...state,
        inputSwapData: currencies[Field.INPUT],
        outputSwapData: currencies[Field.OUTPUT],
      }));
    }
  };

  const onConfirm = () => {
    if (onSwapHandler) {
      setSwapState((state) => ({ ...state, attemptingTxn: true }));
      if (platformOverride === "PancakeSwap") {
        onSwapHandler.Pancake &&
          onSwapHandler
            .Pancake()
            .then((result) => {
              setSwapState((state) => ({
                ...state,
                attemptingTxn: false,
                txHash: result,
              }));
            })
            .catch((error) => {
              setSwapState((state) => ({
                ...state,
                attemptingTxn: false,
                txHash: undefined,
                swapErrorMessage: error.message,
              }));
            });
      }

      if (platformOverride === "MdexSwap") {
        onSwapHandler.Mdex &&
          onSwapHandler
            .Mdex()
            .then((result) => {
              setSwapState((state) => ({
                ...state,
                attemptingTxn: false,
                txHash: result,
              }));
            })
            .catch((error) => {
              setSwapState((state) => ({
                ...state,
                attemptingTxn: false,
                txHash: undefined,
                swapErrorMessage: error.message,
              }));
            });
      }

      if (platformOverride === "Biswap") {
        onSwapHandler.Biswap &&
          onSwapHandler
            .Biswap()
            .then((result) => {
              setSwapState((state) => ({
                ...state,
                attemptingTxn: false,
                txHash: result,
              }));
            })
            .catch((error) => {
              setSwapState((state) => ({
                ...state,
                attemptingTxn: false,
                txHash: undefined,
                swapErrorMessage: error.message,
              }));
            });
      }

      if (platformOverride === "ApeSwap") {
        onSwapHandler.Apeswap &&
          onSwapHandler
            .Apeswap()
            .then((result: any) => {
              setSwapState((state) => ({
                ...state,
                attemptingTxn: false,
                txHash: result,
              }));
            })
            .catch((error: any) => {
              setSwapState((state) => ({
                ...state,
                attemptingTxn: false,
                txHash: undefined,
                swapErrorMessage: error.message,
              }));
            });
      }
    }
  };

  const handleOnDismiss = () => {
    setSwapState({
      showConfirm: false,
      tradeToConfirm,
      attemptingTxn,
      swapErrorMessage,
      txHash,
    });
    setIsConfirm(false);
  };

  return (
    <>
      <CurrencyInputPanel>
        <CurrencyInput
          quickSelect
          className="mb-10"
          balance={
            currencyBalances
              ? currencyBalances[Field.INPUT]?.amount.toFixed(4)
              : "0"
          }
          onChange={(e) => {
            setAmount0(e.target.value);
            onHandleChange(e, Field.INPUT);
            // dispatch(
            //   typeInput({
            //     field: Field.INPUT,
            //     typedValue: e.target.value,
            //   })
            // );
            setIsEnter(true);
          }}
          value={amount0}
          field={Field.INPUT}
          btnSwitch
          reverse={reverse}
          onMax={onMax}
          currency={currencies[Field.INPUT]}
        />
      </CurrencyInputPanel>

      <CurrencyInputPanel>
        <CurrencyInput
          className="mb-10"
          balance={
            currencyBalances
              ? currencyBalances[Field.OUTPUT]?.amount.toFixed(4)
              : "0"
          }
          onChange={(e) => {
            setAmount1(e.target.value);
            onHandleChange(e, Field.OUTPUT);
            // dispatch(
            //   typeInput({
            //     field: Field.OUTPUT,
            //     typedValue: e.target.value,
            //   })
            // );
          }}
          value={amount1}
          field={Field.OUTPUT}
          onMax={onMax}
          currency={currencies[Field.OUTPUT]}
        />
      </CurrencyInputPanel>

      {/* swap route */}
      <div className=" swapPer mb-10">
        <div className="d-flex-row items-between swap--lending">
          <div className="swap--per">
            <div className="text-sub ">{formattedPrice}</div>
            <button className="btn" onClick={switchPrice}>
              <SwapIcon />
            </button>
          </div>

          {/* <div
            onClick={() => {
              setIsShowSwapRoute((prev) => !prev);
            }}
            className={`chervon--icon ${isShowSwapRoute && "rotate"}`}
          >
            <ChervonDown onClick={() => {}} />
          </div> */}
        </div>

        {/* {isShowSwapRoute && (
      <>
        <div className="mt-10 text-right swap--route">
          <SwapRoute />
        </div>
        <div className="text-right text-network">
          <span className="text-linked">View more</span>
        </div>
      </>
    )} */}
        <div
          className={`swap--route text-right mt-10 ${
            isShowSwapRoute && "show-animated"
          }`}
        >
          {
            <>
              {/* <SwapRoute exchangeSelected={exchangeSelected} /> */}
              {/* <div className="text-right text-network">
                <span
                  className="text-linked"
                  onClick={() => {
                    setIsModalVisible(true);
                  }}
                >
                  View more
                </span>
              </div> */}
            </>
          }
        </div>
      </div>
      {/* advance more */}
      <div className="swapPer mb-10">
        <AdvanceMore></AdvanceMore>
      </div>
      {/* advance */}
      <div className="card__tab mb-10">
        <div className="d-flex-row items-between">
          <div className="text-sub">Advance</div>
          <div
            className={`accordion ${isShowAdvance && "accordion--rotate"}`}
            onClick={() => {
              setIsShowAdvance((prev) => !prev);
            }}
          >
            <ChervonDown onClick={() => {}} />
          </div>
        </div>

        <div className={`swap--advance ${isShowAdvance && "show-animated"}`}>
          {<SwapAdvance platformOverride={platformOverride} />}
        </div>
      </div>

      {/* button */}
      <div className="swap--btn">
        {!account ? (
          unsupportedChainIdError ? (
            <button className="btn btn-primary" onClick={toggleNetworkSwitcher}>
              Switch to BSC network
            </button>
          ) : (
            <button className="btn btn-primary" onClick={toggleWalletSwitcher}>
              Connect Wallet
            </button>
          )
        ) : (
          ""
        )}
        {account &&
          ApprovalState.APPROVED === approvalState &&
          insufficeBalance === false && (
            <button className="btn btn-primary" onClick={onSwapAction}>
              Swap
            </button>
          )}
        {account &&
          ApprovalState.NOT_APPROVED === approvalState &&
          insufficeBalance === false && (
            <button className="btn btn-primary" onClick={() => {}}>
              <strong>Approve Token To Swap</strong>
            </button>
          )}
        {account && insufficeBalance && (
          <button className="btn btn-primary" onClick={() => {}}>
            <strong>Insufficient Balance</strong>
          </button>
        )}
      </div>
      <ConfirmSwapModal
        inputAmount={amount0}
        outputAmount={amount1}
        inputSwapData={inputSwapData}
        outputSwapData={outputSwapData}
        isOpen={isConfirm}
        setIsOpen={setIsConfirm}
        trade={platformTrade}
        allowedSlippage={allowedSlippage}
        formattedPrice={formattedPrice}
        switchPrice={switchPrice}
        attemptingTxn={attemptingTxn}
        txHash={txHash}
        onConfirm={onConfirm}
        swapErrorMessage={swapErrorMessage}
        onDismiss={handleOnDismiss}
        setSwapState={setSwapState}
      />
    </>
  );
};

export default SwapTab;
