import { createContext } from "react";
import { TokenList } from "@uniswap/token-lists";
import { ListsState } from "../../../state/lists/reducer";

export interface ManageListsContextType {
  addError?: string;
  tempList?: TokenList;
  isImported?: boolean;
  handleInput?: (e: any) => void;
  listUrlInput?: string;
  handleImport?: () => void;
  renderableLists: string[];
  disableListImport?: boolean;
}

export interface ListRowContextType {
  listsByUrl: ListsState["byUrl"];
  isActiveList: (url: string) => boolean;
  disableListInfo: boolean;
  handleRemoveList: (url: string) => void;
  handleEnableList: (url: string) => void;
  handleDisableList: (url: string) => void;
  handleAcceptListUpdate: (url: string) => void;
}

export const ListRowContext = createContext<ListRowContextType>({
  listsByUrl: {},
  disableListInfo: false,
  isActiveList: () => false,
  handleEnableList: () => null,
  handleRemoveList: () => null,
  handleDisableList: () => null,
  handleAcceptListUpdate: () => null,
});

export const ManageListsContext = createContext<ManageListsContextType>({
  addError: "false",
  tempList: undefined,
  isImported: false,
  handleInput: () => null,
  listUrlInput: "",
  handleImport: () => null,
  renderableLists: [],
  disableListImport: false,
});
