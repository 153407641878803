import React, { Dispatch, useMemo, useState } from "react";
import Tab from "./Tab";
import _ from "lodash";

interface TabsProps {
  currentTab: number;
  setCurrentTab: Dispatch<number>;
}

const Tabs: React.FunctionComponent<TabsProps> = ({
  currentTab,
  setCurrentTab,
}) => {
  const tabs = useMemo(
    () => [
      {
        label: "Lists",
        index: 0,
      },
      {
        label: "Tokens",
        index: 1,
      },
    ],
    []
  );
  const onClickTab = (indexTab: number) => {
    setCurrentTab(indexTab);
  };
  return (
    <>
      <div className="manage--tokens--tabs">
        {tabs.map((tab: any) => {
          return (
            <Tab
              key={_.uniqueId()}
              label={tab.label}
              currentTab={currentTab}
              indexTab={tab.index}
              onClickTab={onClickTab}
            />
          );
        })}
      </div>
    </>
  );
};

export default Tabs;
