import { AbstractConnector } from "@web3-react/abstract-connector";
import { InjectedConnector } from "@web3-react/injected-connector";
import metamask from "../../src/assets/images/metamask.png";
import coinBaseWallet from "../../src/assets/images/coinbaseWalletIcon.svg";
import { ChainId } from "./chainIds";

export const injected = new InjectedConnector({
  supportedChainIds: [ChainId.tBNB, ChainId.BNB],
});

export interface WalletInfo {
  connector?: AbstractConnector;
  name: string;
  iconName: string;
  description: string;
}

const SUPPORTED_WALLETS: { [key: string]: WalletInfo } = {
  METAMASK: {
    connector: injected,
    name: "MetaMask",
    iconName: metamask,
    description: "Easy-to-use browser extension.",
  },
  WALLET_LINK: {
    name: "Coinbase Wallet",
    iconName: coinBaseWallet,
    description: "Use Coinbase Wallet app on mobile device",
  },
};

export const PRE_SELECT_OUTPUT_CURRENCY_ID: { [chainId in ChainId]: string } = {
  [ChainId.MAINNET]: "",
  [ChainId.BNB]: "",
  [ChainId.GOERLI]: "",
  [ChainId.ROPSTEN]: "",
  [ChainId.tBNB]: "",
};

export const TESTNETS = [ChainId.GOERLI, ChainId.tBNB, ChainId.ROPSTEN];

export default SUPPORTED_WALLETS;

export const CMC_PRO_API_KEY = "fc408713-e32a-4e83-8f18-c0620aa90b4d";
export const CMC_URL =
  "https://pro-api.coinmarketcap.com/v2/cryptocurrency/quotes/latest";

// CAKE
export const DEFAULT_OUTPUT_CURRENCY =
  "0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82";

export const All__Platform__Trades = ["PancakeSwap", "MdexSwap", "Biswap", "ApeSwap"];