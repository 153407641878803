import { ChainId } from "../constants/chainIds";

export const EXPLORER_LINK_TYPE: Record<string, string> = {
  transaction: "transaction",
  token: "token",
  address: "address",
  block: "block",
};

export const getExplorerLink = (
  chainId: ChainId,
  hash: string,
  type: keyof typeof EXPLORER_LINK_TYPE
): string => {
  const prefix = getExplorerPrefix(chainId);

  switch (type) {
    case EXPLORER_LINK_TYPE.transaction: {
      return `${prefix}/tx/${hash}`;
    }
    case EXPLORER_LINK_TYPE.token: {
      return `${prefix}/token/${hash}`;
    }
    case EXPLORER_LINK_TYPE.block: {
      return `${prefix}/block/${hash}`;
    }
    case EXPLORER_LINK_TYPE.address:
    default: {
      return `${prefix}/address/${hash}`;
    }
  }
};

const getExplorerPrefix = (chainId: ChainId) => {
  switch (chainId) {
    case ChainId.tBNB:
      return "https://testnet.bscscan.com/";
    case ChainId.MAINNET:
      return "https://etherscan.io/";
    case ChainId.GOERLI:
      return "https://goerli.etherscan.io/";
    case ChainId.BNB:
      return "https://bscscan.com";
    default:
      return `https://
      }etherscan.io`;
  }
};
