import React from "react";
import ChervonDown from "../../../assets/icons/ChervonDown";

interface ImportTokenHeaderProps {
  showManageView: () => void;
}

const ImportTokenHeader: React.FunctionComponent<ImportTokenHeaderProps> = ({
  showManageView,
}) => {
  return (
    <>
      <div className="manage--tokens--header">
        <ChervonDown onClick={showManageView} />
        <div className="manage--tokens--header__title">
          Import unknown token
        </div>
      </div>
    </>
  );
};

export default ImportTokenHeader;
