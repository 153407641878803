import { Contract } from "@ethersproject/contracts";
import { Web3Provider } from "@ethersproject/providers";
import { useWeb3React } from "@web3-react/core";
import pancakeMultiCallABI from "config/abis/pancakeMultiCall.json";
import uniswapMultiCallABI from "config/abis/uniswapMultiCall.json";
import MULTICALL2_ABI from "config/abis/multicall2.json";
import erc20ABI from "config/abis/erc20.json";
import erc20Bytes32ABI from "config/abis/erc20_bytes32.json";
import { useMemo } from "react";
import { getMultiCallAddress } from "utils/addressHelpers";
import { ChainId } from "libs/pancake-swap/constants";
import ensPublicResolver from "libs/ape-swap/abis/ens-public-resolver.json";
import ens from "libs/ape-swap/abis/ens-registrar.json";
import multicallV3 from "libs/ape-swap/abis/multicallv3.json";
import { useMulticallV3Address } from "libs/ape-swap/hooks/useAddress";
import { useActiveWeb3React } from "./useActiveWeb3React";
import { getAddress } from "@ethersproject/address";
import { AddressZero } from "@ethersproject/constants";

function getProviderOrSigner(library: Web3Provider, account?: string | null) {
  return account ? library.getSigner(account).connectUnchecked() : library;
}

function getMultiCallAbi(chainId?: number) {
  if (!chainId) return undefined;
  if (chainId === 97 || chainId === 56) return MULTICALL2_ABI;
  else return MULTICALL2_ABI;
}
// returns the checksummed address if the address is valid, otherwise returns false
export function isAddress(value: any): string | false {
  try {
    return getAddress(value);
  } catch {
    return false;
  }
}

export function getContract(
  address: string,
  ABI: any,
  library: Web3Provider,
  account?: string
): Contract {
  if (!isAddress(address) || address === AddressZero) {
    throw Error(`Invalid 'address' parameter '${address}'.`);
  }

  return new Contract(
    address,
    ABI,
    getProviderOrSigner(library, account) as any
  );
}

export function useContract(
  address: any,
  abi: any | undefined,
  withSignerIfPossible = true
) {
  // const { library, account } = useWeb3React();
  const { library, account } = useActiveWeb3React();
  return useMemo(() => {
    if (!address || !abi || !library) return null;
    try {
      // return new Contract(
      //   address,
      //   abi,
      //   withSignerIfPossible ? getProviderOrSigner(library, account) : undefined
      // );
      return getContract(
        address,
        abi,
        library,
        withSignerIfPossible && account ? account : undefined
      );
    } catch (error) {
      console.error("Failed to get contract", error);
      return null;
    }
  }, [address, abi, library, account, withSignerIfPossible]);
}

export function useMultiCallContract() {
  const { chainId } = useActiveWeb3React();
  return useContract(
    getMultiCallAddress(chainId),
    MULTICALL2_ABI,
    false
  );
}

export function useTokenContract(
  tokenAddress?: string,
  withSignerIfPossible?: boolean
) {
  return useContract(tokenAddress, erc20ABI, withSignerIfPossible);
}

export function useBytes32TokenContract(
  tokenAddress?: string,
  withSignerIfPossible?: boolean
) {
  return useContract(tokenAddress, erc20Bytes32ABI, withSignerIfPossible);
}

export function useENSResolverContract(
  address: string | undefined,
  withSignerIfPossible?: boolean
): Contract | null {
  return useContract(address, ensPublicResolver, withSignerIfPossible);
}

export function useENSRegistrarContract(
  withSignerIfPossible?: boolean
): Contract | null {
  const { chainId } = useWeb3React();
  let address: string | undefined;
  if (chainId) {
    // eslint-disable-next-line default-case
    switch (chainId) {
      case ChainId.MAINNET:
        address = "0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e";
        break;
    }
  }
  return useContract(address, ens, withSignerIfPossible);
}

export function useInterfaceMulticall() {
  return useContract(useMulticallV3Address(), multicallV3, false);
}
