import React, { useMemo } from "react";
import { useActiveWeb3React } from "hooks/useActiveWeb3React";
import { ChainId } from "constants/chainIds";
import { useTokenInfoFromActiveListOnCurrentChain } from "state/lists/hooks";
import useHttpLocations from "../../../hooks/useHttpLocations";
import { WrappedTokenInfo } from "state/lists/wrapped-token-info";
import { Currency } from "entities/currency";
import {
  getTokenLogoURL,
  NATIVE_CURRENCY_LOGO,
} from "../../../utils/getTokenLogoURL";
import StyledCurrencyLogo from "./StyledCurrencyLogo";

interface CurrencyLogoProps {
  currency: Currency;
  chainIdOverride?: ChainId;
}

const CurrencyLogo: React.FunctionComponent<CurrencyLogoProps> = ({
  currency,
  chainIdOverride,
}) => {
  const { chainId } = useActiveWeb3React();
  const selectedChainId = chainIdOverride || chainId;

  const swapWrappedTokenInfo =
    useTokenInfoFromActiveListOnCurrentChain(currency);

  const uriLocations = useHttpLocations(
    currency instanceof WrappedTokenInfo ? currency.logoURI : undefined
  );
  const nativeCurrencyLogo =
    NATIVE_CURRENCY_LOGO[(selectedChainId as ChainId) || ChainId.BNB];

  //   const nativeCurrncyLogo = NATIVE_CURRENCY_LOGO[(selectedChainId as ChainId) || ChainId.MAINNET]
  const sources: string[] = useMemo(() => {
    if (
      (currency && currency.symbol === "BNB") ||
      (currency.symbol === "ETH" && !!nativeCurrencyLogo)
    ) {
      return [nativeCurrencyLogo];
    }

    if (!currency || !currency.address) {
      return uriLocations;
    }
    return [
      getTokenLogoURL(currency.address, selectedChainId),
      ...uriLocations,
    ];
  }, [currency, nativeCurrencyLogo, uriLocations]);
  return (
    <>
      <StyledCurrencyLogo sources={sources} currency={currency} />
    </>
  );
};

export default CurrencyLogo;
