import { Row, Col } from "antd";
import Counter from "components/UIElements/Counter";
import React from "react";
import { FaAward, FaStar, FaHeart, FaDownload } from "react-icons/fa";

interface AwardsProps {}

const Awards: React.FunctionComponent<AwardsProps> = (props) => {
  return (
    <>
    </>
  );
};

export default Awards;
