import React, { useCallback, useEffect, useState } from 'react';
import { useAppDispatch } from '../../app/hooks';
import { useActiveWeb3React } from 'hooks/useActiveWeb3React';
import useIsWindowVisible from '../../hooks/useIsWindowVisible';
import useDebounce from '../../hooks/useDebounce';
import { setConnectorInfo, updateBlockNumber } from './application';

interface UpdaterProps {
}

const Updater: React.FunctionComponent<UpdaterProps> = (props) => {
  const { library, chainId, account } = useActiveWeb3React()
  const dispatch = useAppDispatch()
  
  const windowVisible = useIsWindowVisible()

  const [state, setState] = useState<{
    chainId: number | undefined
    blockNumber: number | null
  }>({
    chainId,
    blockNumber: null,
  })
  
  const blockNumberCallback = useCallback(
    (blockNumber: number) => {
      setState((state: any) => {
        if (chainId === state.chainId) {
          if (typeof state.blockNumber !== 'number') return { chainId, blockNumber }
          return { chainId, blockNumber: Math.max(blockNumber, state.blockNumber) }
        }
        return state
      })
    },
    [chainId]
  )
 // attach/detach listeners
 useEffect(() => {
  if (!library || !chainId || !windowVisible) return undefined

  setState({ chainId, blockNumber: null })

  library
    .getBlockNumber()
    .then(blockNumberCallback)
    .catch(error => console.error(`Failed to get block number for chainId: ${chainId}`, error))

  library.on('block', blockNumberCallback)

  return () => {
    library.removeListener('block', blockNumberCallback)
  }
}, [dispatch, chainId, library, blockNumberCallback, windowVisible])

const debouncedState = useDebounce(state, 100)

useEffect(() => {
 
  if (!debouncedState.chainId || !debouncedState.blockNumber) return
  dispatch(updateBlockNumber({ chainId: debouncedState.chainId, blockNumber: debouncedState.blockNumber }))
}, [windowVisible, dispatch, debouncedState.blockNumber, debouncedState.chainId])

useEffect(() => {
  if (chainId && account) {
    dispatch(setConnectorInfo({ chainId, account }))
  }
}, [account, chainId, dispatch])

return null
};

export default Updater;
