import BinanceChainLogo from "../assets/images/binance-chain-logo.svg";
import EthereumLogo from "../assets/images/ethereum-logo.svg";

import { ChainId } from "./chainIds";
import { NetworkDetails } from "models/network";
import { Currency } from "@ape.swap/sdk";

export type NetworkOptionsPreset = {
  chainId: ChainId;
  name: string;
  logoSrc: string;
  tag?: NetworkSwitcherTags;
  symbol?: string;
};

export enum NetworkSwitcherTags {
  TESTNETS = "testnets",
  COMING_SOON = "coming soon",
}

export const networkOptionsPreset: NetworkOptionsPreset[] = [
  // no tag - mainnets
  {
    chainId: ChainId.MAINNET,
    name: "Ethereum",
    logoSrc: EthereumLogo,
    symbol: "ETH",
  },
  {
    chainId: ChainId.BNB,
    name: "BNB Smart Chain",
    logoSrc: BinanceChainLogo,
    symbol: "BNB",
  },
  // TESTNETS
  {
    chainId: ChainId.tBNB,
    name: "BNB Testnet",
    logoSrc: BinanceChainLogo,
    tag: NetworkSwitcherTags.TESTNETS,
    symbol: "BNB",
  },
  {
    chainId: ChainId.GOERLI,
    name: "Görli",
    logoSrc: EthereumLogo,
    tag: NetworkSwitcherTags.TESTNETS,
    symbol: "GoerliETH",
  },
];

export const NETWORK_DETAIL: { [chainId: number]: NetworkDetails } = {
  [ChainId.MAINNET]: {
    chainId: `0x${ChainId.MAINNET.toString(16)}`,
    chainName: "Ethereum mainnet",
    nativeCurrency: {
      name: Currency.ETHER.name || "Ether",
      symbol: Currency.ETHER.symbol || "ETH",
      decimals: Currency.ETHER.decimals || 18,
    },
    rpcUrls: ["https://mainnet.infura.io/v3"],
    blockExplorerUrls: ["https://etherscan.io"],
  },
  [ChainId.GOERLI]: {
    chainId: `0x${ChainId.GOERLI.toString(16)}`,
    chainName: "Görli",
    nativeCurrency: {
      name: Currency.ETHER.name || "Ether",
      symbol: Currency.ETHER.symbol || "ETH",
      decimals: Currency.ETHER.decimals || 18,
    },
    rpcUrls: ["https://goerli.infura.io/v3"],
    blockExplorerUrls: ["https://goerli.etherscan.io"],
  },
  [ChainId.tBNB]: {
    chainId: `0x${ChainId.tBNB.toString(16)}`,
    chainName: "BSC Testnet",
    nativeCurrency: {
      name: Currency.BNB.name || "Binance Coin",
      symbol: Currency.BNB.symbol || "BNB",
      decimals: Currency.BNB.decimals || 18,
    },
    rpcUrls: ["https://data-seed-prebsc-1-s1.binance.org:8545/"],
    blockExplorerUrls: ["https://testnet.bscscan.com/"],
  },
  [ChainId.BNB]: {
    chainId: `0x${ChainId.BNB.toString(16)}`,
    chainName: "BSC Mainnet",
    nativeCurrency: {
      name: Currency.BNB.name || "Binance Coin",
      symbol: Currency.BNB.symbol || "BNB",
      decimals: Currency.BNB.decimals || 18,
    },
    rpcUrls: ["https://bsc-dataseed.binance.org"],
    blockExplorerUrls: ["https://bscscan.com"],
  },
};
