import { CurrencyAmount } from "@ape.swap/sdk";
import { Image } from "antd";
import CurrencyLogo from "features/manageLists/components/CurrencyLogo";
import { Token } from "models/manageToken";
import React, { CSSProperties } from "react";
import { Token as MyToken } from "../../models/manageToken/index";

interface TokenPickerItemProps {
  token: Token | any;
  price?: number;
  onClick?: (token: Token) => void;
  style: CSSProperties;
  balance?: any;
  onSelectToken: (token: MyToken) => void;
}

const TokenPickerItem: React.FunctionComponent<TokenPickerItemProps> = ({
  token,
  price,
  onClick,
  style,
  balance,
  onSelectToken,
}) => {
  return (
    <>
      <div
        className="currencies--item"
        style={style}
        onClick={() => {
          onSelectToken(token);
        }}
      >
        <div className="currency">
          {/* avatar */}
          <div className="currency--img">
            <CurrencyLogo currency={token} />
            {/* <Image
              src={
                token.logoSrc || token.tokenInfo.logoURI || token.image || ""
              }
              width={32}
              height={32}
              style={{ borderRadius: "50%" }}
              preview={false}
            /> */}
          </div>
          {/* info */}
          <div className="currency--info">
            <div className="text-currency">{token.symbol}</div>
            <div className="text-yoyo">{token.name}</div>
          </div>
        </div>

        <div className="balance">
          <div className="text-currency">{price}</div>
          {/* <div className="text-yoyo">
            {balance ? balance.amount.toFixed(4) : "$0"}
          </div> */}
        </div>
      </div>
    </>
  );
};

export default TokenPickerItem;
