import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { customCreateAsyncThunk } from 'slice/customAsynSlice';

export const listProviderUser  = customCreateAsyncThunk.createAsyncThunkWithLoading(
  'private/listProvideruser', 'GET', '/api/public/swap-provider/active',
);

interface InitialState {
    error?:any,
    dataProvider?:object,
    dataProviderUser?:object,
    isChangedStatus?:boolean,
}

const initialState:InitialState = {
  dataProvider: [],
  error: null,
  isChangedStatus: false,
  dataProviderUser: [],
};

const providerSlice = createSlice({
  name: 'provider',
  initialState,
  reducers: {
    resetProviderReducer: (state:any) => {
      state.isChangedStatus= false;
    },
  },
  extraReducers: {
    [listProviderUser.fulfilled]: (state:any, action:any) => {
      state.dataProviderUser = action.payload;
    },
    [listProviderUser.rejected]: (state:any, action:any) => {
      state.error = action.payload;
    },
  },
});

export const { resetProviderReducer } = providerSlice.actions;

const providerReducer = providerSlice.reducer;
export default providerReducer;
export const selectProvider = (state:any) => state.provider;
