import ButtonCheckCircle from "assets/icons/ButtonCheckCircle";
import SearchField from "components/Input/SearchField";
import React, { useContext, useMemo, useState } from "react";
import Tabs from "../components/Tabs";
import CardList from "../../manageLists/components/CardList";
import ManageLists from "../../manageLists/pages/ManageLists";
import ManageTokens from "../../manageLists/pages/ManageTokens";
import { CurrencySearchModalContext } from "components/CurrenySearchModal/context/CurrencySearchModalContext";
interface ManageTokenProps {}

const ManageToken: React.FunctionComponent<ManageTokenProps> = (props) => {
  const [currentTab, setCurrentTab] = useState<number>(0);
  const { setModalView, setImportToken } = useContext(
    CurrencySearchModalContext
  );

  return (
    <>
      <div className="manage--tokens">
        <Tabs currentTab={currentTab} setCurrentTab={setCurrentTab} />
        {currentTab === 0 && <ManageLists />}
        {currentTab === 1 && (
          <ManageTokens
            setModalView={setModalView}
            setImportToken={setImportToken}
          />
        )}
      </div>
    </>
  );
};

export default ManageToken;
