// export const DEFAULT_TOKEN_LIST =
//   "ipfs://QmRqvWQahoyAUdeXEroKsWtLMLPpJ2Nxad7SeGTN4H6zRt";
export const DEFAULT_TOKEN_LIST =
  "https://raw.githubusercontent.com/SparkMindsJSC/token-lists/main/lists/rubikswap-default.json";
// export const DEFAULT_TOKEN_LIST =
//   "ipfs://QmfBMB8tmiuEBTjVAcnNG4C6Ttxo2ryLefLnHcGNo93cPV";

const BA_LIST =
  "https://raw.githubusercontent.com/The-Blockchain-Association/sec-notice-list/master/ba-sec-list.json";

const PANCAKESWAP_LIST =
  "https://tokens.pancakeswap.finance/pancakeswap-extended.json";

const COINGECKO_LIST = "https://tokens.pancakeswap.finance/coingecko.json";
// const COINGECKO_LIST = "https://tokens.coingecko.com/uniswap/all.json";

export const UNSUPPORTED_LIST_URLS: string[] = [BA_LIST];

export const DEFAULT_LIST_OF_LISTS: string[] = [
  DEFAULT_TOKEN_LIST,
  PANCAKESWAP_LIST,
  COINGECKO_LIST,
  // ...UNSUPPORTED_LIST_URLS,
];
export const DEFAULT_ACTIVE_LIST_URLS: string[] = [
  DEFAULT_TOKEN_LIST,
  PANCAKESWAP_LIST,
  COINGECKO_LIST,
];

export enum SYMBOLS {
  BTC = "bitcoin",
  ETH = "ethereum",
  USDT = "tether",
  BNB = "binancecoin",
}
