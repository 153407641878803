import { Currency } from "entities/currency";
import React from "react";
import Logo from "../../../components/Common/Logo";

interface StyledCurrencyLogoProps {
  sources: string[];
  currency?: Currency;
}

const StyledCurrencyLogo: React.FunctionComponent<StyledCurrencyLogoProps> = ({
  sources,
  currency,
}) => {
  return (
    <>
      <Logo sources={sources} defaultText={currency?.symbol || "?"} size="40" />
    </>
  );
};

export default StyledCurrencyLogo;
