import { DownOutlined } from '@ant-design/icons';
import { Button, Col, Image, Input, Row, Typography } from 'antd';
import { getAllNativeCurrenciesAndTokens, useManageTokenModal } from 'hooks';
import React, { ChangeEvent, useEffect, useMemo } from 'react';
import { useAppDispatch } from 'app/hooks';
import { selectCurrency } from 'state/swap/swapSlice';
import { Field } from 'state/swap/types';
import { useSwapState } from 'state/swap/swapHook';
import { useWeb3React } from '@web3-react/core';

interface CurrencyInputProps {
  title: string;
  balance?: any;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  value: string;
  field: Field;
  onMax?: any;
}

export const CurrencyInput: React.FunctionComponent<CurrencyInputProps> = ({
  title,
  balance = 0,
  onChange,
  value,
  field,
  onMax,
}) => {
  const { chainId } = useWeb3React();
  const dispatch = useAppDispatch();
  const manageTokenModal = useManageTokenModal();
  const tokens = getAllNativeCurrenciesAndTokens(chainId);

  const {
    [Field.INPUT]: { currencyId: inputCurrencyId },
    [Field.OUTPUT]: { currencyId: outputCurrencyId },
  } = useSwapState();

  const currenyCurrency = useMemo(() => {
    let address = field === Field.INPUT ? inputCurrencyId : outputCurrencyId;
    if (address === 'BNB') address = '';
    return tokens ? tokens.filter((t) => t.address === address)[0] : undefined;
  }, [tokens, inputCurrencyId, outputCurrencyId, field]);

  useEffect(() => {
    const currencyId = !manageTokenModal.currentToken
      ? ''
      : manageTokenModal.currentToken.address
      ? manageTokenModal.currentToken.address
      : manageTokenModal.currentToken.symbol;

    dispatch(
      selectCurrency({
        field,
        currencyId,
      })
    );
  }, [manageTokenModal.currentToken, dispatch, field]);

  const onClickMax = () => {
    if (!onMax) return;
    onMax(field);
  };

  return (
    <Row align="middle" className="bordered py-9">
      <Col span={15}>
        <Typography.Text style={{ paddingLeft: '11px' }}>{title}</Typography.Text>
        <Input bordered={false} placeholder="0.0" onChange={onChange} value={value} />
        <Typography.Text style={{ paddingLeft: '11px' }}>Balance: {balance}</Typography.Text>
      </Col>
      <Col span={9}>
        <div className="d-flex just-content-end px-10">
          {field === Field.INPUT && (
            <Button className="text-primary" type="text" onClick={onClickMax}>
              Max
            </Button>
          )}

          <Button
            className="px-5"
            style={{ minWidth: '100px' }}
            onClick={() => manageTokenModal.showModal()}
          >
            <div
              className="w-100 d-flex just-content-end align-items-center"
              style={{ height: '25px' }}
            >
              {currenyCurrency ? (
                <div className="d-flex cg-10 align-items-center just-content-center">
                  <Image
                    width={25}
                    height={25}
                    style={{ borderRadius: '50%' }}
                    src={currenyCurrency ? currenyCurrency.image : ''}
                    preview={false}
                  />
                  <Typography.Text strong>
                    {currenyCurrency ? currenyCurrency.symbol : ''}
                  </Typography.Text>
                  <DownOutlined />
                </div>
              ) : (
                <DownOutlined />
              )}
            </div>
          </Button>
        </div>
      </Col>
      {manageTokenModal.modal}
    </Row>
  );
};
