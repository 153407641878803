import React, { FC } from "react";
import { useCurrencySearchModalSwap } from "../../hooks/useCurrencySearchModalSwap";
import CurrencySearchModalProvider from "../CurrenySearchModal/pages/CurrencySearchModalProvider";
interface CurrencyInputPanelProps {
  children: React.ReactNode;
}

const CurrencyInputPanel: React.FC<CurrencyInputPanelProps> = ({
  children,
}) => {
  const searchModalContexts = useCurrencySearchModalSwap();

  return (
    <>
      <CurrencySearchModalProvider {...searchModalContexts}>
        {children}
      </CurrencySearchModalProvider>
    </>
  );
};

export default CurrencyInputPanel;
