import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ApplicationModal } from "models/application";
import { ChainId } from "../../constants/chainIds";

export interface ApplicationState {
  readonly openModal: ApplicationModal | null;
  readonly blockNumber: { readonly [chainId: number]: number }
  readonly chainId: ChainId | undefined,
  readonly account: string | null | undefined
}

const initialState: ApplicationState = {
  openModal: null,
  blockNumber: {},
  chainId: undefined,
  account: null,
};

export const applicationSlice = createSlice({
  name: "application",
  initialState,
  reducers: {
    setOpenModal: (state, action) => {
      state.openModal = action.payload;
    },
    updateBlockNumber:(state,action)=>{
      const { chainId, blockNumber } = action.payload
      if (typeof state.blockNumber[chainId] !== 'number') {
        state.blockNumber[chainId] = blockNumber
      } else {
        state.blockNumber[chainId] = Math.max(blockNumber, state.blockNumber[chainId])
      }
    },
    setConnectorInfo: (state, action) => {
      const { account, chainId } = action.payload
      state.account = account
      state.chainId = chainId
    }
  },
});

export const { setOpenModal, updateBlockNumber, setConnectorInfo } = applicationSlice.actions;

export default applicationSlice.reducer;
