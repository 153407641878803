const TOKENS: {
  [chainId: number]: {
    chainId: number;
    address: string;
    decimals: number;
    symbol: string;
    name: string;
    image: string;
  }[];
} = {
  0: [
    {
      name: "Tether USD",
      symbol: "USDT",
      address: "0x55d398326f99059fF775485246999027B3197955",
      chainId: 56,
      decimals: 18,
      image:
        "https://pancakeswap.finance/images/tokens/0x55d398326f99059fF775485246999027B3197955.png",
    },
  ], // default not connected wallet
  3: [
    {
      chainId: 3,
      symbol: "USDT",
      name: "Tether USD",
      decimals: 6,
      address: "0x516de3a7A567d81737e3a46ec4FF9cFD1fcb0136",
      image: "https://s2.coinmarketcap.com/static/img/coins/64x64/825.png",
    },
  ],
  56: [
    {
      name: "WBNB Token",
      symbol: "WBNB",
      address: "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
      chainId: 56,
      decimals: 18,
      image:
        "https://pancakeswap.finance/images/tokens/0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c.png",
    },
    {
      name: "BUSD Token",
      symbol: "BUSD",
      address: "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56",
      chainId: 56,
      decimals: 18,
      image:
        "https://pancakeswap.finance/images/tokens/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56.png",
    },
    {
      name: "Ethereum Token",
      symbol: "ETH",
      address: "0x2170Ed0880ac9A755fd29B2688956BD959F933F8",
      chainId: 56,
      decimals: 18,
      image:
        "https://pancakeswap.finance/images/tokens/0x2170Ed0880ac9A755fd29B2688956BD959F933F8.png",
    },
    {
      name: "BTCB Token",
      symbol: "BTCB",
      address: "0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c",
      chainId: 56,
      decimals: 18,
      image:
        "https://pancakeswap.finance/images/tokens/0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c.png",
    },
    {
      name: "Tether USD",
      symbol: "USDT",
      address: "0x55d398326f99059fF775485246999027B3197955",
      chainId: 56,
      decimals: 18,
      image:
        "https://pancakeswap.finance/images/tokens/0x55d398326f99059fF775485246999027B3197955.png",
    },
    {
      name: "PancakeSwap Token",
      symbol: "CAKE",
      address: "0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82",
      chainId: 56,
      decimals: 18,
      image:
        "https://pancakeswap.finance/images/tokens/0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82.png",
    },
    {
      name: "Venus",
      symbol: "XVS",
      address: "0xcF6BB5389c92Bdda8a3747Ddb454cB7a64626C63",
      chainId: 56,
      decimals: 18,
      image:
        "https://pancakeswap.finance/images/tokens/0xcF6BB5389c92Bdda8a3747Ddb454cB7a64626C63.png",
    },
    {
      name: "VAI Stablecoin",
      symbol: "VAI",
      address: "0x4BD17003473389A42DAF6a0a729f6Fdb328BbBd7",
      chainId: 56,
      decimals: 18,
      image:
        "https://pancakeswap.finance/images/tokens/0x4BD17003473389A42DAF6a0a729f6Fdb328BbBd7.png",
    },
    {
      name: "Pancake Bunny",
      symbol: "BUNNY",
      address: "0xC9849E6fdB743d08fAeE3E34dd2D1bc69EA11a51",
      chainId: 56,
      decimals: 18,
      image:
        "https://pancakeswap.finance/images/tokens/0xC9849E6fdB743d08fAeE3E34dd2D1bc69EA11a51.png",
    },
    {
      name: "Alpaca",
      symbol: "ALPACA",
      address: "0x8F0528cE5eF7B51152A59745bEfDD91D97091d2F",
      chainId: 56,
      decimals: 18,
      image:
        "https://pancakeswap.finance/images/tokens/0x8F0528cE5eF7B51152A59745bEfDD91D97091d2F.png",
    },
  ],
  97: [
    {
      chainId: 97,
      symbol: "USDT",
      name: "Tether USD",
      decimals: 18,
      address: "0x337610d27c682E347C9cD60BD4b3b107C9d34dDd",
      image: "https://s2.coinmarketcap.com/static/img/coins/64x64/825.png",
    },
    {
      chainId: 97,
      symbol: "CAKE",
      name: "PancakeSwap Token",
      decimals: 18,
      address: "0xa35062141Fa33BCA92Ce69FeD37D0E8908868AAe",
      image: "https://s2.coinmarketcap.com/static/img/coins/64x64/7186.png",
    },
    {
      chainId: 97,
      symbol: "BUSD",
      name: "Binance USD",
      decimals: 18,
      address: "0xeD24FC36d5Ee211Ea25A80239Fb8C4Cfd80f12Ee",
      image: "https://s2.coinmarketcap.com/static/img/coins/64x64/4687.png",
    },
    {
      chainId: 97,
      symbol: "BAKE",
      name: "Bakeryswap Token",
      decimals: 18,
      address: "0xE02dF9e3e622DeBdD69fb838bB799E3F168902c5",
      image: "https://s2.coinmarketcap.com/static/img/coins/64x64/7064.png",
    },
    {
      chainId: 97,
      symbol: "SYRUP",
      name: "SyrupBar Token",
      decimals: 18,
      address: "0xfE1e507CeB712BDe086f3579d2c03248b2dB77f9",
      image: "https://s2.coinmarketcap.com/static/img/coins/64x64/4687.png",
    },
  ],
};

export default TOKENS;
