import { RawCurrency } from "entities/currency";
import { RawFraction } from "entities/fractions/fraction";
import { CurrencyAmount, TokenAmount, Trade } from "libs/pancake-swap";
import {
  CurrencyAmount as MdexCurrencyAmount,
  TokenAmount as MdexTokenAmount,
  Trade as MdexTrade,
} from "libs/mdex-swap";
import {
  CurrencyAmount as BiswapCurrencyAmount,
  TokenAmount as BiswapTokenAmount,
  Trade as BiswapTrade,
} from "libs/bi-swap";
import {
  CurrencyAmount as ApeswapCurrencyAmount,
  Trade as ApeswapTrade,
  Currency as ApeswapCurrency,
  SmartRouter,
} from "@ape.swap/sdk";
import React, { useEffect, useState } from "react";
import { Field } from "../state/swap/types";
import { Field as ActionField } from "state/swap/actions";
import { RouterTypes } from "libs/ape-swap/constants";
import { computeTradePriceBreakdown } from "libs/pancake-swap/utils/prices";
import { computeMdexTradePriceBreakdown } from "libs/mdex-swap/utils/prices";
import { computeBiswapTradePriceBreakdown } from "libs/bi-swap/utils/prices";
import { computeApeswapTradePriceBreakdown } from "libs/ape-swap/utils/prices";
import { useWeb3React } from "@web3-react/core";
import { useDispatch } from "react-redux";
import { setSwapRoutes } from "features/swap/swapSlice";
import { RouteSwap } from "models";

interface UseSwapRouteProps {
  currencyBalances: {
    [Field.INPUT]:
    | {
      currency: RawCurrency;
      amount: RawFraction;
    }
    | undefined;
    [Field.OUTPUT]:
    | {
      currency: RawCurrency;
      amount: RawFraction;
    }
    | undefined;
  };
  pancakeTrade: {
    trade: Trade | undefined;
    parsedAmount: CurrencyAmount | TokenAmount | undefined;
  };
  mdexTrade: {
    trade: MdexTrade | undefined;
    parsedAmount: MdexCurrencyAmount | MdexTokenAmount | undefined;
  };
  biswapTrade: {
    trade: BiswapTrade | undefined;
    parsedAmount: BiswapCurrencyAmount | BiswapTokenAmount | undefined;
  };
  apeswapTrade: {
    currencies: { [field in ActionField]?: ApeswapCurrency };
    currencyBalances: { [field in ActionField]?: ApeswapCurrencyAmount };
    parsedAmount: ApeswapCurrencyAmount | undefined;
    trade: ApeswapTrade | undefined;
    inputError?: string;
    v1Trade: ApeswapTrade | undefined;
  };
  platformTrade?: string
}

export const useSwapRoute = ({
  pancakeTrade,
  mdexTrade,
  biswapTrade,
  apeswapTrade,
  platformTrade
}: UseSwapRouteProps) => {
  const { account, chainId } = useWeb3React();
  const dispatch = useDispatch();
  const apeswapBestRoute = {
    routerType: RouterTypes.APE,
    smartRouter: SmartRouter.PANCAKE,
  };

  const computePancakeTradePriceBreakdownObj = computeTradePriceBreakdown(
    pancakeTrade.trade
  );
  const computeMdexTradePriceBreakdownObj = computeMdexTradePriceBreakdown(
    mdexTrade.trade
  );
  const computeBiswapTradePriceBreakdownObj = computeBiswapTradePriceBreakdown(
    biswapTrade.trade
  );
  const computeApeswapTradePriceBreakdownObj =
    computeApeswapTradePriceBreakdown(
      chainId || 97,
      apeswapBestRoute.smartRouter,
      apeswapTrade.trade
    );

  useEffect(() => {
    if (
      (!pancakeTrade || !pancakeTrade.trade) &&
      (!mdexTrade || !mdexTrade.trade) &&
      (!biswapTrade || !biswapTrade.trade) &&
      (!apeswapTrade || !apeswapTrade.trade)
    ) {
      dispatch(setSwapRoutes([]));
      return;
    }

    const swapRoutes: RouteSwap[] = [];
    if (pancakeTrade && pancakeTrade.trade) {
      const leftPool = `${pancakeTrade.trade.route.pairs[0].reserve0.toSignificant(
        6
      )} ${pancakeTrade.trade.route.pairs[0].token0.symbol}`;
      const rightPool = `${pancakeTrade.trade.route.pairs[0].reserve1.toSignificant(
        6
      )} ${pancakeTrade.trade.route.pairs[0].token1.symbol}`;

      const providerFee = computePancakeTradePriceBreakdownObj.realizedLPFee
        ? `${computePancakeTradePriceBreakdownObj.realizedLPFee.toSignificant(
          4
        )} ${pancakeTrade.trade.inputAmount.currency.symbol}`
        : "-";

      const priceImpact =
        computePancakeTradePriceBreakdownObj.priceImpactWithoutFee
          ? computePancakeTradePriceBreakdownObj.priceImpactWithoutFee.toFixed(
            2
          )
          : "-";
      swapRoutes.push({
        routeId: "1",
        routeName: "PancakeSwap",
        providerFee,
        estimateReceived: pancakeTrade.trade.outputAmount.toSignificant(6),
        priceImpact: priceImpact,
        imageUrl:
          "https://s2.coinmarketcap.com/static/img/exchanges/64x64/1344.png",
        outputCurrency: pancakeTrade.trade.outputAmount.currency.symbol,
        route: pancakeTrade.trade.route.path.map((p) => p.symbol).join(" -> "),
        liquidityPool: `${leftPool} / ${rightPool}`,
      });
    }
    if (mdexTrade && mdexTrade.trade) {
      const leftPool = `${mdexTrade.trade.route.pairs[0].reserve0.toSignificant(
        6
      )} ${mdexTrade.trade.route.pairs[0].token0.symbol}`;
      const rightPool = `${mdexTrade.trade.route.pairs[0].reserve1.toSignificant(
        6
      )} ${mdexTrade.trade.route.pairs[0].token1.symbol}`;

      const providerFee = computeMdexTradePriceBreakdownObj.realizedLPFee
        ? `${computeMdexTradePriceBreakdownObj.realizedLPFee.toSignificant(
          4
        )} ${mdexTrade.trade.inputAmount.currency.symbol}`
        : "-";

      const priceImpact =
        computeMdexTradePriceBreakdownObj.priceImpactWithoutFee
          ? computeMdexTradePriceBreakdownObj.priceImpactWithoutFee.toSignificant(6)
          : "-";
      swapRoutes.push({
        routeId: "2",
        routeName: "MdexSwap",
        providerFee,
        estimateReceived: mdexTrade.trade.outputAmount.toSignificant(6),
        priceImpact,
        imageUrl:
          "https://s2.coinmarketcap.com/static/img/exchanges/64x64/1329.png",
        outputCurrency: mdexTrade.trade.outputAmount.currency.symbol,
        route: mdexTrade.trade.route.path.map((p) => p.symbol).join(" -> "),
        liquidityPool: `${leftPool} / ${rightPool}`,
      });
    }
    if (biswapTrade && biswapTrade.trade) {
      const leftPool = `${biswapTrade.trade.route.pairs[0].reserve0.toSignificant(
        6
      )} ${biswapTrade.trade.route.pairs[0].token0.symbol}`;
      const rightPool = `${biswapTrade.trade.route.pairs[0].reserve1.toSignificant(
        6
      )} ${biswapTrade.trade.route.pairs[0].token1.symbol}`;

      const providerFee = computeBiswapTradePriceBreakdownObj.realizedLPFee
        ? `${computeBiswapTradePriceBreakdownObj.realizedLPFee.toSignificant(
          4
        )} ${biswapTrade.trade.inputAmount.currency.symbol}`
        : "-";

      const priceImpact =
        computeBiswapTradePriceBreakdownObj.priceImpactWithoutFee
          ? computeBiswapTradePriceBreakdownObj.priceImpactWithoutFee.toFixed(2)
          : "-";
      swapRoutes.push({
        routeId: "3",
        routeName: "Biswap",
        providerFee,
        estimateReceived: biswapTrade.trade.outputAmount.toSignificant(6),
        priceImpact,
        imageUrl:
          "https://s2.coinmarketcap.com/static/img/exchanges/64x64/1445.png",
        outputCurrency: biswapTrade.trade.outputAmount.currency.symbol,
        route: biswapTrade.trade.route.path.map((p) => p.symbol).join(" -> "),
        liquidityPool: `${leftPool} / ${rightPool}`,
      });
    }
    if (apeswapTrade && apeswapTrade.trade) {
      const leftPool = `${apeswapTrade.trade.route.pairs[0].reserve0.toSignificant(
        6
      )} ${apeswapTrade.trade.route.pairs[0].token0.symbol}`;
      const rightPool = `${apeswapTrade.trade.route.pairs[0].reserve1.toSignificant(
        6
      )} ${apeswapTrade.trade.route.pairs[0].token1.symbol}`;

      const providerFee = computeApeswapTradePriceBreakdownObj.realizedLPFee
        ? `${computeApeswapTradePriceBreakdownObj.realizedLPFee.toSignificant(
          4
        )} ${apeswapTrade.trade.inputAmount.currency.symbol}`
        : "-";

      const priceImpact =
        computeApeswapTradePriceBreakdownObj.priceImpactWithoutFee
          ? computeApeswapTradePriceBreakdownObj.priceImpactWithoutFee.toFixed(
            2
          )
          : "-";
      swapRoutes.push({
        routeId: "4",
        routeName: "ApeSwap",
        providerFee,
        estimateReceived: apeswapTrade.trade.outputAmount.toSignificant(6),
        priceImpact: priceImpact,
        imageUrl:
          "https://s2.coinmarketcap.com/static/img/exchanges/64x64/1281.png",
        outputCurrency: apeswapTrade.trade.outputAmount.currency.symbol,
        route: apeswapTrade.trade.route.path
          .map((p) => p.symbol)
          .join(" -> "),
        liquidityPool: `${leftPool} / ${rightPool}`,
      });
    }
    dispatch(setSwapRoutes(swapRoutes));
  }, [
    pancakeTrade,
    mdexTrade,
    biswapTrade,
    apeswapTrade,
    dispatch,
    computePancakeTradePriceBreakdownObj.realizedLPFee,
    computePancakeTradePriceBreakdownObj.priceImpactWithoutFee,
    computeMdexTradePriceBreakdownObj.priceImpactWithoutFee,
    computeMdexTradePriceBreakdownObj.realizedLPFee,
    computeBiswapTradePriceBreakdownObj.priceImpactWithoutFee,
    computeBiswapTradePriceBreakdownObj.realizedLPFee,
    computeApeswapTradePriceBreakdownObj.priceImpactWithoutFee,
    computeApeswapTradePriceBreakdownObj.realizedLPFee,
  ]);

  return {
    computePancakeTradePriceBreakdownObj,
    computeMdexTradePriceBreakdownObj,
    computeBiswapTradePriceBreakdownObj,
    computeApeswapTradePriceBreakdownObj,
  };
};
