import { useWeb3React } from '@web3-react/core';
import { ChainId } from 'libs/pancake-swap';
import { useMemo } from 'react';
import { Currency, Token, WETH } from '../entities';
import { mainnetTokens, testnetTokens } from '../tokens';

// a list of tokens by chain
type ChainTokenList = {
  readonly [chainId in ChainId]: Token[];
};

function wrappedCurrency(
  currency: Currency | undefined,
  chainId: ChainId | undefined
): Token | undefined {
  return chainId && currency?.symbol === 'BNB'
    ? WETH[chainId]
    : currency instanceof Token
      ? currency
      : undefined;
}

// used to construct intermediary pairs for trading
const BASES_TO_CHECK_TRADES_AGAINST: ChainTokenList = {
  [ChainId.MAINNET]: [
    mainnetTokens.wbnb,
    mainnetTokens.cake,
    mainnetTokens.busd,
    mainnetTokens.usdt,
    mainnetTokens.btcb,
    mainnetTokens.ust,
    mainnetTokens.eth,
    mainnetTokens.usdc,
  ],
  [ChainId.TESTNET]: [testnetTokens.wbnb, testnetTokens.cake, testnetTokens.busd],
};

const ADDITIONAL_BASES: { [chainId in ChainId]?: { [tokenAddress: string]: Token[] } } = {
  [ChainId.MAINNET]: {},
};

const CUSTOM_BASES: { [chainId in ChainId]?: { [tokenAddress: string]: Token[] } } = {
  [ChainId.MAINNET]: {},
};

export function useAllCurrencyCombinations(
  currencyA?: Currency,
  currencyB?: Currency,
): [Token, Token][] {
  const { chainId = 56 } = useWeb3React();
  const [tokenA, tokenB] = chainId
    ? [wrappedCurrency(currencyA, chainId), wrappedCurrency(currencyB, chainId)]
    : [undefined, undefined];

  const bases: Token[] = useMemo(() => {
    if (!chainId) return [];

    const pancakeChainId = chainId === 56 ? ChainId.MAINNET : ChainId.TESTNET;
    const common = BASES_TO_CHECK_TRADES_AGAINST[pancakeChainId] ?? [];
    const additionalA = tokenA ? ADDITIONAL_BASES[pancakeChainId]?.[tokenA.address] ?? [] : [];
    const additionalB = tokenB ? ADDITIONAL_BASES[pancakeChainId]?.[tokenB.address] ?? [] : [];
    return [...common, ...additionalA, ...additionalB];
  }, [chainId, tokenA, tokenB]);

  const basePairs: [Token, Token][] = useMemo(
    () =>
      bases
        .flatMap((base): [Token, Token][] => bases.map((otherBase) => [base, otherBase]))
        // though redundant with the first filter below, that expression runs more often, so this is probably worthwhile
        .filter(([t0, t1]) => !t0.equals(t1)),
    [bases]
  );

  return useMemo(
    () =>
      tokenA && tokenB
        ? [
          // the direct pair
          [tokenA, tokenB] as [Token, Token],
          // token A against all bases
          ...bases.map((base): [Token, Token] => [tokenA, base]),
          // token B against all bases
          ...bases.map((base): [Token, Token] => [tokenB, base]),
          // each base against all bases
          ...basePairs,
        ]
          // filter out invalid pairs comprised of the same asset (e.g. WETH<>WETH)
          .filter(([t0, t1]) => !t0.equals(t1))
          // filter out duplicate pairs
          .filter(([t0, t1], i, otherPairs) => {
            // find the first index in the array at which there are the same 2 tokens as the current
            const firstIndexInOtherPairs = otherPairs.findIndex(([t0Other, t1Other]) => {
              return (
                (t0.equals(t0Other) && t1.equals(t1Other)) ||
                (t0.equals(t1Other) && t1.equals(t0Other))
              );
            });
            // only accept the first occurrence of the same 2 tokens
            return firstIndexInOtherPairs === i;
          })
          // optionally filter out some pairs for tokens with custom bases defined
          .filter(([tokenA, tokenB]) => {
            if (!chainId) return true;
            const mdexChainId = chainId === 56 ? ChainId.MAINNET : ChainId.TESTNET;
            const customBases = CUSTOM_BASES[mdexChainId];

            const customBasesA: Token[] | undefined = customBases?.[tokenA.address];
            const customBasesB: Token[] | undefined = customBases?.[tokenB.address];

            if (!customBasesA && !customBasesB) return true;

            if (customBasesA && !customBasesA.find((base) => tokenB.equals(base))) return false;
            if (customBasesB && !customBasesB.find((base) => tokenA.equals(base))) return false;

            return true;
          })
        : [],
    [tokenA, tokenB, bases, basePairs, chainId]
  );
}
