import { Col, Image, Row, Select } from "antd";
import React from "react";
import BlogsWrapper from "./pages/BlogsWrapper";
import BlogCard from "./components/BlogCard";

interface BlogsProps {}

const Blogs: React.FunctionComponent<BlogsProps> = (props) => {
  return (
    <>
      <BlogsWrapper>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="blog--head">
          <Col xs={24} sm={24} md={16} lg={16} xl={16} xxl={16}>
            <Row>
              <Col span={24} className="blog--head__title">
                News
              </Col>
              <Col span={24} className="blog--head__sub">
                Insights into the biggest events shaping the crypto industry.
              </Col>
            </Row>
          </Col>
          <Col
            xs={24}
            sm={24}
            md={8}
            lg={8}
            xl={8}
            xxl={8}
            className="text-right"
          >
            <Select
              showSearch
              onChange={() => {}}
              className="select--base"
              placeholder="Search coin"
              dropdownAlign={{ offset: [0, 1] }}
            >
              <Select.Option value={1}>All coins</Select.Option>
              <Select.Option value={2}>Bitcoin</Select.Option>
              <Select.Option value={3}>Ethereum</Select.Option>
              <Select.Option value={3}>Tether</Select.Option>
              <Select.Option value={3}>USD coin</Select.Option>
            </Select>
          </Col>
        </Row>

        <div className="blog--content">
          {[1, 2, 3, 4].map(() => {
            return <BlogCard />;
          })}
        </div>
      </BlogsWrapper>
    </>
  );
};

export default Blogs;
