import { Col, Image, Row } from "antd";
import React from "react";
import dexwonderFull from "assets/images/dexwonderFull.png";
import {
  FaFacebook,
  FaMedium,
  FaTelegram,
  FaBusinessTime,
  FaReadme,
  FaTwitter,
  FaMailBulk
} from "react-icons/fa";
import { Link } from "react-router-dom";

const contact_data = [
  {
    icon: <FaTelegram />,
    url: "https://t.me/dexwonder",
    text: ""
  },
  {
    icon: <FaMailBulk />,
    url: "mailto:support@dexwonder.com",
    text: ""
  }
];

const social_data = [
  {
    icon: <FaTwitter />,
    url: "https://twitter.com/dexwonder",
    text: ""
  },
  {
    icon: <FaMedium />,
    url: "https://dexwonder.medium.com/",
    text: ""
  },
  {
    icon: <FaFacebook />,
    url: "https://www.facebook.com/dexwonderinsights",
    text: ""
  },
  {
    icon: <FaTelegram />,
    url: "https://t.me/dexwonder",
    text: ""
  }
];

interface FooterProps {}

const Footer: React.FunctionComponent<FooterProps> = (props) => {
  return (
    <>
      <section className="footer">
        <Row className="footer--top" gutter={[48, 12]}>
          <Col span={7} className="col-md-4">
            <Image preview={false} src={dexwonderFull} alt="DexWonder" width={200}/>
          </Col>
        </Row>
        <Row className="footer--sub">
          <div>Unlock the Wonders of Decentralized Exchange</div>
        </Row>
        <Row className="footer--contact-title">Business Contacts</Row>
        <Row className="footer--contact">
        <a href="mailto:tony@dexwonder.com" target="_blank">tony@dexwonder.com</a>
        </Row>
        <Row className="footer--contact-title">Help</Row>
        <Row className="footer--contact">
          <span>
              <a href="mailto:support@dexwonder.com" target="_blank">support@dexwonder.com</a>
          </span>
        </Row>
        <Row className="footer--icons">
          <Col> 
          <span className="footer--contact">Community |</span>
            {social_data.map((item, index) => (
              <a href={item.url} target="_blank">{item.icon} {item.text}</a>
            ))}
          </Col>

          <Col className="d-flex-row items-between" span={24}>
            <label>&#169; 2023 DexWonder. All rights reserved</label>
            <label>v0.11.0</label>
          </Col>
        </Row>
      </section>
    </>
  );
};

export default Footer;
