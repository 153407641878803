import { Divider, Layout } from "antd";
import { Content } from "antd/lib/layout/layout";
import { MyHeader } from "components";
import MyAssets from "components/MyAssets";
import React from "react";
import { Outlet } from "react-router-dom";

interface LandingLayoutProps {}

const LandingLayout: React.FunctionComponent<LandingLayoutProps> = (props) => {
  return (
    <>
      <Layout>
        <MyHeader />
        <Content
          style={{
            padding: "0px 50px",
            background:
            "linear-gradient(295.27deg, #000000 50%, #394867 84.52%)",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center center",
          }}
          className="landing--wrapper"
        >
          <Outlet />
        </Content>
      </Layout>
    </>
  );
};

export default LandingLayout;
