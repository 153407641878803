import { TokenList } from "@uniswap/token-lists";

export enum CurrencyModalView {
  SEARCH,
  MANAGE,
  IMPORT_TOKEN,
  IMPORT_LIST,
}

export interface ManageListsProps {
  setListUrl: (url: string) => void;
  setModalView: (view: CurrencyModalView) => void;
  setImportList: (list: TokenList) => void;
}

export interface ManageListsContextType {
  addError?: string;
  tempList?: TokenList;
  isImported?: boolean;
  handleInput?: (e: any) => void;
  listUrlInput?: string;
  handleImport?: () => void;
  renderableLists: string[];
  disableListImport?: boolean;
}
