import { useCallback, useContext, useMemo } from "react";
import { ListRowContext } from "../context/ManageListContext";
import { useActiveWeb3React } from "../../../hooks/useActiveWeb3React";
interface ListRowProps {
  listUrl: string;
}

export const useListRow = ({ listUrl }: ListRowProps) => {
  const {
    listsByUrl,
    isActiveList,
    disableListInfo,
    handleRemoveList: handleRemoveListRaw,
    handleEnableList: handleEnableListRaw,
    handleDisableList: handleDisableListRaw,
    handleAcceptListUpdate: handleAcceptListUpdateRaw,
  } = useContext(ListRowContext);

  const { chainId } = useActiveWeb3React();
  const { current: list, pendingUpdate: pending } = listsByUrl[listUrl];

  const tokensAmountInCurrentChain = useMemo(() => {
    if (!list || list.tokens.length === 0 || !chainId) return 0;
    return list.tokens.filter((token) => token.chainId === chainId).length;
  }, [chainId, list]);

  const isActive = isActiveList(listUrl);

  const handleAcceptListUpdate = useCallback(() => {
    if (!pending) return;
    handleAcceptListUpdateRaw(listUrl);
  }, [handleAcceptListUpdateRaw, listUrl, pending]);

  const handleRemoveList = useCallback(() => {
    if (
      window.prompt(
        `Please confirm you would like to remove this list by typing REMOVE`
      ) === `REMOVE`
    ) {
      handleRemoveListRaw(listUrl);
    }
  }, [handleRemoveListRaw, listUrl]);

  const handleEnableList = useCallback(() => {
    handleEnableListRaw(listUrl);
  }, [handleEnableListRaw, listUrl]);

  const handleDisableList = useCallback(() => {
    handleDisableListRaw(listUrl);
  }, [handleDisableListRaw, listUrl]);


  return {
    list,
    tokensAmountInCurrentChain,
    handleAcceptListUpdate,
    handleRemoveList,
    handleEnableList,
    handleDisableList,
    isActive,
    listsByUrl,
    disableListInfo,
  };
};
