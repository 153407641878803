import { Col, Image, Row } from "antd";
import aboutImage from "assets/images/about/about.svg";

import React from "react";
import { FaApple, FaGooglePlay } from "react-icons/fa";

interface AboutProps {}

const About: React.FunctionComponent<AboutProps> = (props) => {
  return (
    <>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="about">
        <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
          <Image src={aboutImage} preview={false} />
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
          <Row gutter={[0, 42]}>
            <Col span={24}>
              <div className="about__title">
                <p>Single Destination for Multiple Product</p>
              </div>
            </Col>

            <Col span={24}>
              <div className="about__sub">
                <p>
                Dexwonder aims to establish itself as a leading decentralized trading platform, continuously evolving and adapting to the dynamic crypto landscape.
                </p>
              </div>
            </Col>
            <Col span={24}>
              <div className="about__list">
                <span>Convenient access to multiple DEX markets
                  <div> Current integrated Dexes:
                  <a href="https://pancakeswap.finance/swap" target="_blank"> <img src="https://s2.coinmarketcap.com/static/img/exchanges/128x128/1344.png" width="32" height="32"></img> </a>
                  <a href="https://dex.apeswap.finance/swap" target="_blank"><img src="https://s2.coinmarketcap.com/static/img/exchanges/128x128/1281.png" width="32" height="32"></img> </a>
                  <a href="https://bsc.mdex.com/#/swap" target="_blank"><img src="https://s2.coinmarketcap.com/static/img/exchanges/128x128/1329.png" width="32" height="32"></img> </a>
                  <a href="https://biswap.org/swap" target="_blank"><img src="https://s2.coinmarketcap.com/static/img/exchanges/128x128/1445.png" width="32" height="32"></img> </a>
                  </div>
                </span>
                <span>Simple path to access multiple network markets</span>
                <span>Easy gateway to a range of P2P markets</span>
                <span>NFT Marketplaces</span>
                <span>DEX - Orderbook exchange</span>
                <span>Real-time market tracking and reporting</span>
                <span>Manage your assets with the best data tools</span>
                <span>PvP games for all users</span>
                <span>A large community for people all over the world</span>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default About;
