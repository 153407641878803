import { Avatar, Table } from "antd";
import { useAppSelector } from "app/hooks";
import { RouteSwap } from "models";
import React, { Dispatch, useEffect, useState } from "react";
import { selectSwapRoutes } from "../swapSlice";
import { CheckCircle } from "react-feather";

interface TableExchangeProps {
  platformOverride: string;
  setPlatformOverride: Dispatch<string>
}
const TableExchange: React.FunctionComponent<TableExchangeProps> = ({
  platformOverride,
  setPlatformOverride
}) => {
  const swapRoutes = useAppSelector(selectSwapRoutes);
  const [filterSwapRoute, setFilterSwapRoute] = useState<Array<RouteSwap> | []>(
    []
  );

  const columns = [
    {
      title: "",
      dataIndex: "",
      key: "",
      align: "left" as "left",
    },

    {
      title: "DEX",
      dataIndex: "imageUrl",
      key: "imageUrl",
      align: "left" as "left",
      render: (imageUrl: string, record: RouteSwap) => {
        return (
          <div className="pl-4 d-flex items-center gap-15">
            <Avatar src={imageUrl} />
            <div>{record.routeName}</div>
          </div>
        );
      },
    },
    {
      title: "Provider Fee",
      dataIndex: "providerFee",
      key: "providerFee",
      align: "left" as "left",
      render: (providerFee: string) => {
        return <>{providerFee}</>;
      },
      sorter: (a: any, b: any) => {
        const _feeA = Number( a.providerFee.split(" ")[0]);
        const _feeB = Number(b.providerFee.split(" ")[0]);
        return _feeA - _feeB;
      },
    },
    {
      title: "Price Impact",
      dataIndex: "priceImpact",
      key: "priceImpact",
      align: "left" as "left",
      render: (priceImpact: string) => {
        return <>{priceImpact} %</>;
      },
      sorter: (a: any, b: any) => {
        const _priceImpactA = Number( a.priceImpact);
        const _priceImpactB = Number(b.priceImpact);
        return _priceImpactA - _priceImpactB;
      },
    },
    {
      title: "Est Received",
      dataIndex: "estimateReceived",
      key: "estimateReceived",
      align: "left" as "left",
      render: (estimateReceived: string, _: RouteSwap) => {
        return (
          <div className="d-flex  align-items-center gap-5">
            {estimateReceived} {_.outputCurrency}
          </div>
        );
      },
      sorter: (a: any, b: any) => {
        const _estimateReceivedA = Number( a.estimateReceived);
        const _estimateReceivedB = Number(b.estimateReceived);
        return _estimateReceivedA - _estimateReceivedB;
      },
    },
    {
      title: "",
      dataIndex: "estimateReceived",
      key: "estimateReceived",
      align: "left" as "left",
      render: (_: any, record: RouteSwap) => {
        return (
          <>
            {platformOverride === (record.routeName) ? (
              <CheckCircle size={24} color="green" />
            ) : (
              ""
            )}
          </>
        );
      },
    },
  ];

  useEffect(() => {
    if (swapRoutes.length !== 0) {
      setFilterSwapRoute(swapRoutes);
    }
  }, [swapRoutes]);

  let locale = {
    emptyText: '...',
  };

  return (
    <>
      <Table
        locale={locale}
        dataSource={filterSwapRoute}
        columns={columns}
        pagination={false}
        bordered={false}
        size="middle"
        className="common--table swap--table swap--routes--table"
        onRow={(record: RouteSwap, index: any): any => {
          return {
            onClick: () => {
              if (platformOverride !== (record.routeName)) {
                setPlatformOverride((record.routeName));
              }
            },
          };
        }}
        rowClassName={(record: RouteSwap, index: any): any => {
          if (platformOverride === (record.routeName)) {
            return "table-row-highlight";
          }
        }}
      />
    </>
  );
};

export default TableExchange;
