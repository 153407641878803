import { StaticJsonRpcProvider } from '@ethersproject/providers';

const BSC_RPC_URL = 'https://bsc-dataseed1.defibit.io/';
const BSC__TESTNET_RPC_URL = 'https://data-seed-prebsc-1-s1.binance.org:8545';
const ETH_RPC_URL = 'https://ropsten.infura.io/v3/8ca4f4a1c10b4ed9abb0d77eb4ef7227';

export const getSimpleRpcProvider = (chainId: number | undefined) => {
  switch (chainId) {
    case 56:
      return new StaticJsonRpcProvider(BSC_RPC_URL);
    case 97:
      return new StaticJsonRpcProvider(BSC__TESTNET_RPC_URL);
    case 1:
      return new StaticJsonRpcProvider(ETH_RPC_URL);
    default:
      return new StaticJsonRpcProvider(BSC_RPC_URL);
  }
};
