import { useAppSelector } from "app/hooks";
import React, { useEffect, useState } from "react";
import { selectSwapRoutes } from "../swapSlice";
import { RouteSwap } from "models";

interface SwapAdvanceProps {
  platformOverride: string
}

const SwapAdvance: React.FunctionComponent<SwapAdvanceProps> = ({
  platformOverride
}) => {
  const swapRoutes = useAppSelector(selectSwapRoutes);
  
  const [platform, setPlatform] = useState<RouteSwap>()
  
  useEffect(()=>{
    if(platformOverride) {
      const res = swapRoutes.find((swap:any)=>{return swap.routeName === platformOverride})
      if(res) {
        setPlatform(res)
      }
    }
  }, [platformOverride, swapRoutes])
  
  return (
    <>
      <div className="mt-10">
        {/* route name */}
        <div className="d-flex-row items-between text-network">
          <div>Swap name</div>
          <div>
            {/* {swapRoutes && swapRoutes.length > 0
              ? swapRoutes[exchangeSelected - 1].routeName +
                ` (${swapRoutes[0].route})`
              : "- - -"} */}
              {platform ? platform.routeName : "- - -"}
          </div>
        </div>
        {/* pool */}
        <div className="d-flex-row items-between text-network mt-10">
          <div>Liquidity Pools</div>
          <div>
            {/* {swapRoutes && swapRoutes.length > 0
              ? swapRoutes[exchangeSelected - 1].liquidityPool
              : "- - / - -"} */}
              {platform ? platform.liquidityPool : "- - -"}
              
          </div>
        </div>
        {/* fee */}
        <div className="d-flex-row items-between text-network mt-10">
          <div>Liquidity Provider Fee</div>
          <div>
            {/* {swapRoutes && swapRoutes.length > 0
              ? swapRoutes[exchangeSelected - 1 ].providerFee
              : "- - -"} */}
              {platform ? platform.providerFee : "- - -"}
          </div>
        </div>
      </div>
    </>
  );
};

export default SwapAdvance;
