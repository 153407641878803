import React, { Dispatch } from "react";
import { ArrowUpCircle } from "react-feather";
interface TransactionSubmitContentProps {
  setIsOpen: Dispatch<boolean>;
  setSwapState: any;
}

const TransactionSubmitContent: React.FunctionComponent<
  TransactionSubmitContentProps
> = ({ setIsOpen, setSwapState }) => {
  return (
    <>
      <div className="modal--swap--confirm--result">
        <ArrowUpCircle color="white" width={100} height={100} />
        <div className="modal--swap--confirm--result__text">
          Transaction Submitted
        </div>
        <button
          className="btn btn-primary"
          onClick={() => {
            setIsOpen(false);
            setSwapState((state: any) => ({
              ...state,
              attemptingTxn: false,
              txHash: undefined,
            }));
          }}
        >
          Close
        </button>
      </div>
    </>
  );
};

export default TransactionSubmitContent;
